import React,{useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { createBrowserHistory } from 'history';
import EventEmitter from "../../EventEmitter";

import App from "../../App";
import Modal from "../Inc/Modal";
import HeadLine from "../Inc/HeadLine/HeadLine";
import Tabs from "../Inc/Tabs/Tabs";
import ListExercises from "./Inc/ListExercises";
import ListFavourites from "./Inc/ListFavourites";
import ListComplex from "./Inc/ListComplex";

//new
var globalSelectMode = {
	callback: (()=>{}),
	history: {}
};
//

var ExerciseSelected = [];
var ExerciseObjectSelected = {};
var ExerciseSelectCount = 0;
export var ExerciseSelectMode = false;

const Book = () => {
	
	useEffect(() => {
		HeadLine.setTitle('Список упражнений');
		HeadLine.setProps({
			back: true
		});
	},[]);
	
	const [selectMode, setSelectMode] = useState(ExerciseSelectMode);
	Book.__setSelectMode = setSelectMode;

	const [selectCount, setSelectCount] = useState(ExerciseSelectCount);

	ExerciseSelectMode = selectMode;
	ExerciseSelectCount = selectCount;
	

	Book.toggleSelect = (id, element, setSelect = (()=>{})) => {
		var ret = false;
		if (ret = Book.checkSelected(id)) {
			ExerciseSelected.splice(ExerciseSelected.indexOf(id), 1);
		}else{
			ExerciseSelected.push(id);
			ExerciseObjectSelected[id] = {
				element: element,
				setSelect: setSelect
			};
			setSelectMode(true);
		}
		setSelectCount(ExerciseSelected.length);
		return !ret
	};

	const submitSelect = () => {
		setSelectMode(false);
		setSelectCount(0);
		//EventEmitter.dispatch('book-select', ExerciseSelected);
		
		setTimeout(() => {
			globalSelectMode.callback(ExerciseSelected, ExerciseObjectSelected);
			for(let key in ExerciseObjectSelected) {
				ExerciseObjectSelected[key].setSelect(false);
			}
			ExerciseSelected = [];
			ExerciseObjectSelected = {};
		});

	}

	const ChoiseList = () => {
		const selectOjects = Object.keys(ExerciseObjectSelected);
		return(<>{selectOjects.length > 0 ? selectOjects.map((key) => (
			ExerciseObjectSelected[key].element
		)) : <div className="stub">Список пуст</div>}</>);
	};

	const setActionSelect = () => {
		if (ExerciseSelectMode) {
			HeadLine.setAction({
				show: true,
				check: submitSelect
			});
		}
	};

	Tabs.subscribe('choise',(status) => {
		if (!status) {
			for(let selectedId in ExerciseObjectSelected) {
				if (ExerciseSelected.indexOf(Number(selectedId)) < 0)
					delete ExerciseObjectSelected[selectedId];
			}
		}
		setActionSelect();
	});

	Tabs.subscribe('exercises',(status) => {
		if (status)
			HeadLine.setAction({
				show: true,
				search: () => {}
			});
		setActionSelect();
	});

	Tabs.subscribe('complexes',(status) => {
		if (status) {
			HeadLine.setAction({
				show: true,
				search: () => {},
				add: () => {
					Tabs.open('exercises');
					Book.setSelectMode(true, (items) => {
						Tabs.open('complexes');
						ListComplex.setItems(items);
						Modal.show('new-complex');
					});
				}
			});
		}
			
		setActionSelect();
	});

	Tabs.subscribe('favourite',(status) => {
		if (status)
			HeadLine.setAction({
				show: true,
				search: () => {}
			});
		setActionSelect();
	});
	
	return(<>
	<div className="main-content">
	
		<div className="mobile">
			<div className="tab-control" rel="training">
				<div className={"tab-item choise" + (selectMode ? '' : ' hide')}><Tabs.NavTab to="choise"> Выбрано <i className="counter">{selectCount}</i></Tabs.NavTab></div>
				<div className="tab-item"><Tabs.NavTab to="exercises" className="active"> Упражнения</Tabs.NavTab></div>
				<div className="tab-item"><Tabs.NavTab to="complexes"> Комплексы</Tabs.NavTab></div>
				<div className="tab-item icon"><Tabs.NavTab to="favourite"><i className="ic ic-star-full"></i></Tabs.NavTab></div>
			</div>
		</div>
		
		<Tabs>
			
			<Tabs.Item id="choise">
				<div className="list empty plates">
					<ChoiseList/>
				</div>
			</Tabs.Item>
			
			<Tabs.Item id="exercises" active={true}>
				<div className="list">
					<ListExercises/>
				</div>
			</Tabs.Item>
			
			<Tabs.Item id="complexes">
				<ListComplex/>
			</Tabs.Item>
			
			<Tabs.Item id="favourite">
				<div className="list plates">
					<ListFavourites/>
				</div>
			</Tabs.Item>
			
		</Tabs>
	
	</div>
	<div className="side-content">
		<div className="desktop">
			<div className="tab-control vert" rel="training">
				<div className={"tab-item choise" + (selectMode ? '' : ' hide')}><Tabs.NavTab to="choise"><i className="ic ic-list"></i> Выбрано <i className="counter">{selectCount}</i></Tabs.NavTab></div>
				<div className="tab-item"><Tabs.NavTab to="exercises" className="active"><i className="ic ic-training"></i> Упражнения</Tabs.NavTab></div>
				<div className="tab-item"><Tabs.NavTab to="complexes"><i className="ic ic-sport"></i> Комплексы</Tabs.NavTab></div>
				<div className="tab-item"><Tabs.NavTab to="favourite"><i className="ic ic-star-full"></i> Избранное</Tabs.NavTab></div>
			</div>
			<a className={"button big choise" + (selectMode ? '' : ' hide')} onClick={submitSelect}><i className="ic ic-check"></i> Подтвердить</a>
		</div>
	</div>
	</>);
};

Book.getSelect = (callback) => {
	EventEmitter.subscribe('book-select', callback);
};
Book.setSelect = () => {
	App.navigate('/training/book', () => {
		Book.__setSelectMode(true);
	});
};

Book.setSelected = (items) => {
	if (items)
		ExerciseSelected = items;
};
Book.setSelectedObjects = (items) => {
	if (items)
		ExerciseObjectSelected = items;
};
Book.setSelectMode = (status, callback = (()=>{}), route = '/training/book#exercises') => {

	// Modal.show('book-select-mode');
	// return;
	
	globalSelectMode.history = createBrowserHistory();
	const location = globalSelectMode.history.location;
	globalSelectMode.callback = (...args) => {
		const newLocation = createBrowserHistory().location;
		if (location.pathname === newLocation.pathname) {
			callback(...args);
		}else{
			App.navigate(location.pathname + location.hash, () => {
				setTimeout(() => {
					callback(...args);
				});
			});
		}
	};
	if (location.pathname === '/training/book') {
		Book.__setSelectMode(status);
	}else{
		App.navigate(route, () => {
			Book.__setSelectMode(status);
		});
	}
};
Book.checkSelected = (id) => {
	return ExerciseSelected.indexOf(id) >= 0 ? true : false;
};

const Wrapper = () => {
	const [selectItems, setSelectItems] = useState([]);
	const [selectItemElements, setSelectItemElements] = useState([]);

	const ChoiseList = () => {
		return(<>{selectItemElements.length > 0 ? selectItemElements.map((item) => item) : <div className="stub">Список пуст</div>}</>);
	};

	const toggleSelect = (id,element) => {
		const newItems = selectItems;
		const newItemElements = selectItemElements;
		const indexItem = selectItems.indexOf(id);
		if (indexItem < 0) {
			newItems.push(id);
			newItemElements.push(element);
		}else{
			newItems.splice(indexItem, 1);
			newItemElements.splice(indexItem, 1);
		}
		setSelectItems([...newItems]);
		setSelectItemElements([...newItemElements]);
	};

	const close = () => {
		Modal.close('book-select-mode');
	};

	const submit = () => {
		EventEmitter.dispatch('book-select-mode-submit',selectItems);
		close();
	};
	
	return(<Modal id="book-select-mode">
		<div className="tab-control" rel="training">
			<div className={"tab-item choise" + (true ? '' : ' hide')}><Tabs.NavTab to="select-choise"> Выбрано <i className="counter">{selectItems.length}</i></Tabs.NavTab></div>
			<div className="tab-item"><Tabs.NavTab to="select-exercises" className="active"> Упражнения</Tabs.NavTab></div>
		</div>
		<Tabs history={false}>
			<Tabs.Item id="select-choise">
				<div className="list empty plates">
					<ChoiseList/>
				</div>
			</Tabs.Item>
			
			<Tabs.Item id="select-exercises" active={true}>
				<div className="list">
					<ListExercises selectMode={true} onSelect={toggleSelect}/>
				</div>
			</Tabs.Item>
		</Tabs>
		<div className="wrapper">
			<div className="buttons flex grows">
				<a className="button cancel" onClick={close}>Отмена</a>
				<a className="button ok accent" onClick={submit}>ОК</a>
			</div>
		</div>
	</Modal>);
};

Book.Wrapper = () => (<Wrapper/>);
export default Book;