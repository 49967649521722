import React from "react";
import TopUser from "../../Inc/TopUser";
import Menu from "../Menu/Menu";
import styles from './style.css';

export default class HeadLine extends React.PureComponent {
	static obj;
	constructor(props) {
		super(props);

		this.default = {
			action: {
				show: false,
				mobile: false,
				spinner: false
			}
		};

		this.state = {
			title: '...',
			sub: '',
			back: false,
			action: this.default.action
		};
		this.title = document.title;
		this.mobileMenuShow = this.mobileMenuShow.bind(this);
		this.goBack = this.goBack.bind(this);

		HeadLine.obj = this;
	}

	componentDidUpdate(props) {
		if(props.location.key !== this.props.location.key)
			Menu.hide();

		document.title = this.state.title + (this.state.sub ? ('/' + this.state.sub) : '') + ' ' + this.title;
	}	

	static setTitle(title, sub = '') {
		HeadLine.obj.setState(state => ({...state,
			title: title,
			sub: sub,
			back: false,
			action: HeadLine.obj.default.action
		}));
	}

	static setAction(action) {
		HeadLine.obj.setState(state => ({...state,
			action: {...state.action, ...action}
		}));
	}

	static setProps(props) {
		HeadLine.obj.setState(state => ({...state, ...props}));
	}

	mobileMenuShow() {
		Menu.show();
	}

	goBack() {
		if (this.props.navigate)
			this.props.navigate(-1);
	}

	render() {
		return(
			<div id="app-bar">
				<div className="row float">
					<div className="side-content"><TopUser user={this.props.user}/></div>
					<div className="main-content">
						<div className="home">
							{
								this.state.back ? <i className="ic ic-arr-left" onClick={this.goBack}></i> :
								<i className="ic ic-menu" onClick={this.mobileMenuShow}></i>
							}
						</div>
						<div className={'title' + (this.state.sub ? ' wsub' : '')}>
							<span>{this.state.title}</span>
							<span className="sub">{this.state.sub}</span>
						</div>
						{
							this.state.action.show ?
								<div className={'actions' + (this.state.action.mobile ? ' mobile' : '')}>
									{
										this.state.action.spinner ? <a><i className={styles.ic + ' ' + styles['ic-spinner']}></i></a> : null
									}
									{Object.keys(this.state.action).map((action, i) => 
									typeof this.state.action[action] === 'function' ? 
										<React.Fragment key={i}>
											{ action === 'edit' ? <a onClick={this.state.action[action]}><i className="ic ic-edit"></i></a> : null }
											{ action === 'dots' ? <a onClick={this.state.action[action]}><i className="ic ic-dots"></i></a> : null }
											{ action === 'search' ? <a onClick={this.state.action[action]}><i className="ic ic-search"></i></a> : null }
											{ action === 'add' ? <a onClick={this.state.action[action]}><i className="ic ic-o-add"></i></a> : null }
											{ action === 'check' ? <a onClick={this.state.action[action]}><i className="ic ic-check"></i></a> : null }
											{ action === 'task' ? <a onClick={this.state.action[action]}><i className="ic ic-task"></i></a> : null }
											{ action === 'book' ? <a onClick={this.state.action[action]}><i className="ic ic-book"></i></a> : null }
											{ action === 'share' ? <a onClick={this.state.action[action]}><i className="ic ic-share"></i></a> : null }
										</React.Fragment>
									: null
									)}
								</div> : null
						}
					</div>
					<div className="side-content">&nbsp;</div>
				</div>
			</div>
		);
	}
}