import HeadLine from "../Inc/HeadLine/HeadLine";

const Anketa = () => {
	HeadLine.setTitle('Опросник тренера', 'Настройки');
	HeadLine.setProps({
		back: true
	});
	HeadLine.setAction({
		show: true,
		check: () => {}
	});
	return(<>
	<div className="main-content">
	
		<div className="settings-items plate notifier">
		Текст об опроснике и конфинденциальности
		</div>
		
		<div className="settings-items plate">
			<div className="setting-cap">Общие данные</div>

			<div className="setting-field">
				<p><b>Вредные превычки</b></p>
				<label className="checkbox" name="vpriv"><input type="checkbox"/><span>Курение</span><i></i></label>
				<label className="checkbox" name="vpriv"><input type="checkbox"/><span>Алкоголь</span><i></i></label>
				<label className="checkbox" name="vpriv"><input type="checkbox"/><span>Другое</span><i></i></label>
				<textarea placeholder="Опишите что именно"></textarea>
			</div>
			<div className="setting-field">
				<p><b>Пристрастие к вредной еде</b></p>
				<label className="checkbox" name="vpriv"><input type="checkbox"/><span>Фастфуд</span><i></i></label>
				<label className="checkbox" name="vpriv"><input type="checkbox"/><span>Мучное</span><i></i></label>
				<label className="checkbox" name="vpriv"><input type="checkbox"/><span>Сладости</span><i></i></label>
				<label className="checkbox" name="vpriv"><input type="checkbox"/><span>Другое</span><i></i></label>
				<textarea placeholder="Опишите что именно"></textarea>
			</div>
			<div className="setting-field">
				<p><b>Соматип</b></p>
				<label className="radio" name="vpriv"><input type="checkbox"/><span>Эктоморф</span><i></i></label>
				<label className="radio" name="vpriv"><input type="checkbox"/><span>Мезоморф</span><i></i></label>
				<label className="radio" name="vpriv"><input type="checkbox"/><span>Эндоморф</span><i></i></label>
				<label className="radio" name="vpriv"><input type="checkbox"/><span>Затрудняюсь ответить</span><i></i></label>
				<div className="spoiler">
				<div className="spoiler-head">Как определить свой соматип?</div>
				<div className="spoiler-content" style={{color: '#777', fontSize: '0.7rem'}}>
	<b>Следует обхватить запястье левой руки большим и указательным пальцами правой руки:</b><br/><br/>
	- пальцы пошли внахлест – Эктоморф<br/>
	- пальцы соприкоснулись – Мезоморф<br/>
	- пальцы не соприкоснулись – Эндоморф
				</div>
				</div>
			</div>

		</div>
		
		<div className="settings-items plate">
			<div className="setting-cap">Фитнес</div>
			<div className="setting-field">
				<p><b>Работали с персональным тренером?</b></p>
				<label className="radio" name="vpriv"><input type="checkbox"/><span>Да</span><i></i></label>
				<label className="radio" name="vpriv"><input type="checkbox"/><span>Нет</span><i></i></label>
			</div>
			<div className="setting-field">
				<p><b>Ваш опыт занятий фитнесом</b></p>
				<label className="radio" name="vpriv"><input type="checkbox"/><span>менее 1 года</span><i></i></label>
				<label className="radio" name="vpriv"><input type="checkbox"/><span>1-3 года</span><i></i></label>
				<label className="radio" name="vpriv"><input type="checkbox"/><span>Более 3-х лет</span><i></i></label>
			</div>
			<div className="setting-field">
				<p><b>Как часто Вы занимаетесь спортом?</b></p>
				<label className="radio" name="vpriv"><input type="checkbox"/><span>1 раз в неделю</span><i></i></label>
				<label className="radio" name="vpriv"><input type="checkbox"/><span>несколько раз в неделю</span><i></i></label>
				<label className="radio" name="vpriv"><input type="checkbox"/><span>ежедневно</span><i></i></label>
			</div>
			<div className="setting-field">
				<p><b>Где вы чаще всего тренируетесь?</b></p>
				<label className="radio" name="vpriv"><input type="checkbox"/><span>Спортзал</span><i></i></label>
				<label className="radio" name="vpriv"><input type="checkbox"/><span>Дома</span><i></i></label>
				<label className="radio" name="vpriv"><input type="checkbox"/><span>Улица</span><i></i></label>
			</div>
			
			<div className="setting-field">
				<p><b>Ваша цель занятий?</b></p>
				<select className="block w100">
					<option>Поддержание формы</option>
					<option>Набор массы</option>
					<option>Увеличение силы</option>
					<option>Жиросжигание</option>
					<option>Выносливость</option>
					<option>Подготовительная</option>
				</select>
			</div>
		</div>
		
		<div className="settings-items plate">
			<div className="setting-cap">Медицинские показатели</div>
			<div className="setting-field">
				<textarea placeholder="Аллергия"></textarea>
			</div>
			<div className="setting-field">
				<textarea placeholder="Травмы"></textarea>
			</div>
			<div className="setting-field">
				<textarea placeholder="Заболевания"></textarea>
			</div>
		</div>
		

		
		<div className="settings-items plate">
			<div className="setting-cap">Предпочтения</div>
			<div className="setting-field">
				<textarea placeholder="Любимые упражнения"></textarea>
			</div>
			<div className="setting-field">
				<textarea placeholder="Любимая еда"></textarea>
			</div>
		</div>

		<button className="last-button">Сохранить</button>
		
	</div>
	<div className="side-content">

	</div>
	</>);
};
export default Anketa;