import React from "react";

class Avatar extends React.PureComponent {
	static route = '';
	static objs = [];
	constructor(props) {
		super(props);
		this.t = '';
		this.index = 0;

	}

	componentDidMount() {
		for(let obj of Avatar.objs) {
			if (obj === this)
				return;
		}
		this.index = Avatar.objs.push(this);
	}

	url(params) {
		var ret = Avatar.route;
		for(let key in params) {
			ret = ret.replace('--'+key+'--', params[key]);
		}
		return ret + this.t;
	}

	static update() {
		console.log(Avatar.objs);
		for(let obj of Avatar.objs) {
			obj.t = '?t=' +(new Date().getTime());
			obj.forceUpdate();
		}
	}

	render() {
		return(<>
		<img src={this.url({uid: this.props.uid, size: this.props.size ?? 900})}/>
		</>);
	}
}

export default class File {
	static Avatar = Avatar;

	static setRoutes({avatar}) {
		Avatar.route = avatar;
	}
}