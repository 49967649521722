import React from "react";

export default class MenuBlock extends React.PureComponent {
	static obj;
	constructor(props) {
		super(props);
		this.state = {
			element: null
		}
		
		MenuBlock.obj = this;
	}

	componentDidUpdate() {
		this.state.element = null;
	}

	static setElement(element) {
		MenuBlock.obj.setState({
			element: element
		});
	}

	render() {
		if (!this.state.element)
			return(<></>);

		return(<>
			<div class="side-content">{this.state.element}</div>
		</>);
	}
}