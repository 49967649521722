import HeadLine from "../Inc/HeadLine/HeadLine";
import Modal from "../Inc/Modal";

const Privacy = () => {
	HeadLine.setTitle('Приватность', 'Настройки');
	HeadLine.setProps({
		back: true
	});
	HeadLine.setAction({
		show: true,
		check: () => {}
	});
	return(<>
	<div className="main-content">

		<div className="settings-items plate">
			<div className="setting-cap">Приватность в поиске</div>
			<div className="setting-field">
				<label className="switch">
					<input type="checkbox"/>
					<span>Скрыть в поиске</span>
					<i></i>
				</label>
			</div>
			<div className="setting-field">
				<label className="switch">
					<input type="checkbox"/>
					<span>Только ник</span>
					<i></i>
				</label>
			</div>
		</div>

		<div className="settings-items plate">
			<div className="setting-cap">Приватность страницы</div>
			<div className="setting-field">
				<label className="switch">
					<input type="checkbox"/>
					<span>Скрыть друзей</span>
					<i></i>
				</label>
			</div>
			<div className="setting-field">
				<a className="modal" onClick={() => Modal.show('privacy-groups')}>Приватность стены <i style={{float: 'right'}}>Видно всем</i></a>
			</div>
		</div>

	</div>
	
	<div className="side-content">

	</div>

	<Modal id="privacy-groups">
		<div className="wrapper">
			<div className="long-list">
				<div className="item"><label className="radio"><input type="radio" name="pp"/><i></i><span>Только для себя</span></label></div>
				<div className="item"><label className="radio"><input type="radio" name="pp"/><i></i><span>Для друзе</span></label></div>
				<div className="item"><label className="radio"><input type="radio" name="pp"/><i></i><span>Всем</span></label></div>
			</div>
			<div className="actions">
				<a className="button">ОК</a>
				<a className="button accent" onClick={() => Modal.close('privacy-groups')}>Отмена</a>
			</div>
		</div>
	</Modal>
	</>);
};
export default Privacy;