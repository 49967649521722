import { useState, useRef, useEffect } from "react";

const TextChange = ({value, placeholder, className, style, autoSelect, onChange, textarea}) => {
	onChange = onChange ?? (()=>{});
	const [editMode, setEditMode] = useState(false);
	const [text, setText] = useState(value);
	const [heightBlock, setHeightBlock] = useState();
	const inputRef = useRef();

	const changeText = (e) => {
		setText(e.target.value);
		setHeightBlock(inputRef.current.scrollHeight);
	};
	
	const submit = () => {
		if (text !== value) {
			onChange(text);
		}
		setEditMode(false);
	};

	const keySubmit = (e) => {
		if (e.keyCode === 13)
			submit();
	};

	useEffect(() => {
		if (editMode && autoSelect) {
			inputRef.current.select();
		}
	},[editMode]);
	
	if (!editMode && text !== value)
		setText(value);

	if (editMode) {		
		if (textarea) {
			return(<textarea style={{...style, width: '100%', height: heightBlock}} className={className} onBlur={submit} value={text ?? ''} onChange={changeText} placeholder={placeholder} ref={inputRef} autoFocus={true}/>);
		}else{
			return(<input className={className} style={style} onBlur={submit} value={text ?? ''} onChange={changeText} onKeyDown={keySubmit} placeholder={placeholder} ref={inputRef} autoFocus={true}/>);
		}
	}
	
	return(<div className={className} style={style} onClick={() => setEditMode(!editMode)}>{text ? text : placeholder}</div>);
};
export default TextChange;