import React from "react";
import Modal from "../Modal";
import styles from './style.css';

export default class DatePicker extends React.Component {
	
	view = null;
	
	day = null;
	month = null;
	year = null;
	
	hours = null;
	minutes = null;
	
	select = new Date();
	buffer = 1;
	mode = 'date';
	minute_step = 5;
	
	constructor(props){
		super(props);

		this.state = {
			selected: null
		};

		this.containerRef = React.createRef();

		this.mode = props.mode ?? 'time';
		this.onChange = props.onChange ?? (()=>{});
		this.onSubmit = props.onSubmit ?? (()=>{});
		this.value = props.value ?? '';

		this.state.selected = new Date(this.value);

		this.__close = this.__close.bind(this);
		this.__show = this.__show.bind(this);
		this.submit = this.submit.bind(this);
		this.TimeString = this.TimeString.bind(this);
	}

	componentDidUpdate() {
		if (this.props.value !== this.value) {
			this.setState({
				selected: new Date(this.props.value)
			});
			this.value = this.props.value;
		}
	}

	init() {
		this.view = this.containerRef.current;
		if (!this.view) return;
		
		if (this.mode == 'date') {
			this.day = document.createElement('div');
			this.day.setAttribute('class', styles['items']);
			this.day.setAttribute('rel', 'day');

			this.month = document.createElement('div');
			this.month.setAttribute('class', styles['items']);
			this.month.setAttribute('rel', 'month');

			this.year = document.createElement('div');
			this.year.setAttribute('class', styles['items']);
			this.year.setAttribute('rel', 'year');

			this.view.appendChild(this.day);
			this.view.appendChild(this.month);
			this.view.appendChild(this.year);
			
			this.create_years();
			this.create_months();
			this.create_days();
		} else {
			this.hour = document.createElement('div');
			this.hour.setAttribute('class', styles['items']);
			this.hour.setAttribute('rel', 'hour');

			this.minute = document.createElement('div');
			this.minute.setAttribute('class', styles['items']);
			this.minute.setAttribute('rel', 'minute');

			this.view.appendChild(this.hour);
			var dots = document.createElement('div');
			dots.setAttribute('class', styles['dots']);
			dots.textContent = ':';

			this.view.appendChild(dots);
			this.view.appendChild(this.minute);
			this.create_hours();
			this.create_minutes();
		}
		
		this.setListeners();
	}
	
	setListeners() {
		
		if (this.mode == 'date') {
			this.onScrollStop(this.day, () => {
				//vibrate(50);
				this.setActive(this.day);
			});
			
			this.onScrollStop(this.month, () => {
				//vibrate(50);
				this.setActive(this.month);
			});
			
			this.onScrollStop(this.year, () => {
				//vibrate(50);
				this.setActive(this.year);
			});
		} else {
			this.onScrollStop(this.hour, () => {
				//vibrate(50);
				this.setActive(this.hour);
			});
			
			this.onScrollStop(this.minute, () => {
				//vibrate(50);
				this.setActive(this.minute);
			});
		}
	
	}

	onScrollStop(view, callback) {

		if (!view || typeof view.addEventListener !== 'function') return;
		if (!callback || typeof callback !== 'function') return;
		var is_scroll = null;
		view.addEventListener('scroll', (event) => {
			clearTimeout(is_scroll);
			is_scroll = setTimeout(() => {
				callback();
			}, 250);
		}, false);
	
	}
	
	setActive(view){
		const type = view.getAttribute('rel');
		const a = view.getElementsByClassName(styles['active']);
		if (a && a[0]) a[0].classList.remove(styles['active']);
		var h = view.scrollHeight / view.children.length;
		var num = ((view.scrollTop + this.buffer * h)  / h) >> 0;
		view.children[num].classList.add(styles['active']);

		const value = Number(view.children[num].innerText);
		if (type === 'hour')
			this.state.selected.setHours(value);

		if (type === 'minute')
			this.state.selected.setMinutes(value);
		
		this.onChange(this.state.selected);
	}
	
	create_hours(){
		this.hour.innerHTML = '';
		
		for (var i = 0 - this.buffer; i < 24 + this.buffer; i++) {
			var el = document.createElement('div');
			this.state.selected.getHours() === i ? el.classList.add(styles['active']) : el.classList.remove(styles['active']);
			el.textContent = (i >= 0 && i < 10) ? '0' : '';
			el.textContent += (i >= 0 && i < 24) ? i : '';
			this.hour.appendChild(el);
		}
		this.hour.getElementsByClassName(styles['active'])[0].scrollIntoView({behavior: 'auto', block: 'center'});
	}
	
	create_minutes() {
		this.minute.innerHTML = '';
		var step = this.minute_step;
		var steps = (60 / step) >> 0;
		
		for (var i = 0 - this.buffer; i < steps + this.buffer; i++) {
			var el = document.createElement('div');
			var l = i * step;
			(Math.round(this.state.selected.getMinutes() * 0.2) * 5) === l ? el.classList.add(styles['active']) : el.classList.remove(styles['active']);
				
			el.textContent = (l >= 0 && l < 10) ? '0' : '';
			el.textContent += (l >= 0 && i < steps) ? l : '';
			this.minute.appendChild(el);
		}
		this.minute.getElementsByClassName(styles['active'])[0].scrollIntoView({behavior: 'auto', block: 'center'});
	}
	
	create_days(days){
		
		days = 31;
		
		this.day.innerHTML = '';
		
		for (var i = 1 - this.buffer; i <= days + this.buffer; i++) {
			var el = document.createElement('div');
			el.textContent = (i > 0 && i <= days) ? i : '';
			this.day.appendChild(el);
		}
	}
	
	create_months(){
		
		this.month.innerHTML = '';
		
		for (var i = 0 - this.buffer; i < 12 + this.buffer; i++) {
			var el = document.createElement('div');
			el.textContent = (i >= 0 && i < 12) ? this.getMonthStr(i) : '';
			this.month.appendChild(el);
		}
	}
	
	create_years(date){
		
		this.year.innerHTML = '';
		var start = this.select.getFullYear();
		
		for (var i = start - 10; i < start + 10; i++) {
			var el = document.createElement('div');
			el.textContent = i;
			this.year.appendChild(el);
		}
	}
	
	getMonthStr(monthIndex) {
		return [
			'Январь',
			'Февраль',
			'Март',
			'Апрель',
			'Май',
			'Июнь',
			'Июль',
			'Август',
			'Сентябрь',
			'Октябрь',
			'Ноябрь',
			'Декабрь'
		][monthIndex];
	}

	__show() {
		Modal.show('app-date-picker');
		setTimeout(() => {
			this.init();
		});
	}

	__close() {
		Modal.close('app-date-picker');
	}

	submit() {
		this.onSubmit(this.state.selected);
		this.forceUpdate();
		this.__close();
	}

	TimeString() {
		return String(this.state.selected.getHours()).padStart(2, "0") + ':' + String(this.state.selected.getMinutes()).padStart(2, "0");
	}
	
	render() {
		
		return(<>
			<a className="date link modal" rel="timer" onClick={this.__show}>
				<i className="ic ic-clock"></i>
				<span> <this.TimeString/> </span>
			</a>
			<Modal id="app-date-picker">
				<div className="wrapper">
					<div className={styles["dt-picker"]} ref={this.containerRef}></div>
					<div className="tip desktop" style={{margin: '10px 0 0', textAlign: 'center'}}>
						<i className="ic ic-mouse"></i>
						<span>Используйте колесико мыши</span>
					</div>
					<div className="buttons flex grows">
						<a className="button cancel" onClick={this.__close}>Отмена</a>
						<a className="button save accent" onClick={this.submit}>ОК</a>
					</div>
				</div>
			</Modal>
		</>);
	}
}