import React,{useState, useEffect, useRef} from "react";
import { useNavigate } from "react-router";
import API from "../../../Api/API";
import SubMenu from "../../Inc/SubMenu/SubMenu";
import { getExerciseImg } from "./Helpers";
import Book,{ ExerciseSelectMode } from "../Book";
import Modal from "../../Inc/Modal";
import {default as ExerciseExe} from "../Exercise";

var states = [];

const Exercise = ({id, title, icon, favourite, short, select, selectMode, onSelect, disabled, draggable, onDelete}) => {
	onSelect = onSelect ?? (()=>{});
	const navigate = useNavigate();
	const container = useRef(null);
	const [favouriteActive, setFavouriteActive] = useState(false);
	const [selected, setSelected] = useState(select);
	Exercise.setSelected = setSelected;

	const initStates = () => {
		if (states[id]) {
			if (states[id].favourite)
				setFavouriteActive(states[id].favourite);
		}else{
			states[id] = {
				favourite: false
			};
		}
	};

	useEffect(() => {
		initStates();
		if (favourite)
			setFavouriteActive(favourite ? true : false);
		if (Book.checkSelected(id))
			setSelected(true);
	},[]);

	const favoutiteToggle = (id) => {
		API.post({
			path: 'favourites/toggle',
			data: {
				item: id,
				type: 1
			},
			success: ({status}) => {
				setFavouriteActive(status);
				states[id].favourite = status;
			},
			error: (e) => {
				console.error(e);
			}
		});
	};

	const selectToggle = (e) => {
		if (!ExerciseSelectMode)
			return;
		if (Book.toggleSelect(id, <Exercise key={id} id={id} title={title} icon={icon} favourite={favourite}/>, setSelected)) {
			setSelected(true);
		}else{
			setSelected(false);
		}
		// if (!selectMode)
		// 	return;
		
		onSelect(id, <Exercise key={id} id={id} title={title} icon={icon} favourite={favourite} select={!selected} selectMode={selectMode} onSelect={onSelect}/>);
		setSelected(!selected);
		
		e.preventDefault();
		e.stopPropagation();
	};

	const open = () => {
		if (selectMode) {
			Modal.show('modal-exe-' + id);
			return;
		}
		navigate('/training/exercise/' + id);
	};
	
	return(<>
		<a className={"exercise-item" + (selected ? ' selected' : '') + (favouriteActive ? ' favourite' : '')} ref={container} onClick={open}>
			<div className="pic-box" onClick={selectToggle}><img src={getExerciseImg(icon)}/></div>
			<div className="name">{title}</div>
			<div className="lvl"><i className="ic ic-exercise-lvl-'+item.level+'"></i></div>
			{
				draggable ?
				<div className="draggable"><i className="ic ic-reorder"></i></div>
				: null
			}
			
			{
				disabled ? null :
				<SubMenu.Link to={'sub-exe-' + id} className="menu s-menu" onClick={(e) => SubMenu.show('sub-exe-' + id, e)}><i className="ic ic-dots"></i></SubMenu.Link>
			}
		</a>
		<SubMenu id={'sub-exe-' + id}>
			<a onClick={() => favoutiteToggle(id)}><i className={"ic ic-star" + (favouriteActive ? ' ic-star-full' : '')}></i> {favouriteActive ? 'Убрать из избранного' : 'В избранное'}</a>
			<a><i className="ic ic-share"></i> Поделиться</a>
			{onDelete ? <a onClick={() => onDelete(id)}><i className="ic ic-delete"></i> Удалить</a> : null}
		</SubMenu>
		<Modal id={'modal-exe-' + id}>
			<ExerciseExe id={id}/>
		</Modal>
	</>);
};

export default Exercise;