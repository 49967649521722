const PostItem = () => (
	<div className="post-item plate" rel="<? echo $item['id']; ?>">
		<div className="post-header">
			<div className="user-pic pic-box"><img src="/assets/users/0.jpg"/></div>
			<div className="post-info">
				<div className="user-name">0</div>
				<div className="post-date">0</div>
			</div>
			<div className="post-menu s-menu" rel="posts"><i className="ic ic-dots"></i></div>
		</div>
		<div className="post-title">0</div>
		<div className="post-text">
			<p>0</p>
			<p><a href="">Читать далее</a></p>
		</div>
	
		<div className="post-media snap-slider wheel <? echo $slides ? 'scrollbar' : '';  ?>">
			<iframe src="https://www.youtube.com/embed/'.$item['video'].'" allowFullScreen></iframe>
			<div className="slide video yt-video" rel="'.$item['video'].'"><span><i className="ic ic-play"></i></span><img src="https://img.youtube.com/vi/'.$item['video'].'/0.jpg"/></div>
			<img src="assets/posts/'.$sl.'.jpg"/>
		</div>
		
		<div className="post-controls">
			<a className="likes">0</a>
			<a className="cmnts">0</a>
			<a className="views">0</a>
			<span className="extra"><i className="ic ic-barbell"></i> <i className="ic ic-dish"></i></span>
			<a className="fav active"></a>
		</div>
	</div>
);

export default PostItem;