import React,{ useCallback, useRef, useEffect, useState } from "react";
import Modal from "../../Inc/Modal";
import DragItems from "../Inc/DragItems";
import Exercise from "../Inc/Exercise";
import ProgItemSets from "./ProgItemSets";
import DivPlaceholder from "../../Inc/DivPlaceholder";
import Book from "../Book";
import App from "../../../App";
import API from "../../../Api/API";
import Training from "../Training";

var progsItems = {};
var progNewItems = {};
var progItemsSets = {};
var progChangeList = {};

var timerTimeout = 0;
const ItemRestore = ({timeout, onRestore, onDelete}) => {
	onRestore = onRestore ?? (()=>{});
	const [timer, setTimer] = useState(timeout);
	
	var tick = timeout;
	const startTimer = () => {
		setTimer(tick);
		tick = tick - 1;
		timerTimeout = setTimeout(startTimer, 1000);
		if (tick < 0) {
			clearTimeout(timerTimeout);
			onDelete();
		}	
	};
	useEffect(() => {
		if (timeout) {
			startTimer();
		}
		return () => {
			clearTimeout(timerTimeout);
		}
	},[]);
	return(<div className="restore">
		<div>{timer}</div>
		<a onClick={onRestore}>Восстановить</a>
	</div>);
};

const ProgItems = ({id, selectDay, onChange, onLoad}) => {
	onLoad = onLoad ?? (()=>{});
	onChange = onChange ?? (()=>{});
	const dayId = selectDay;
	const apiObj = useRef({});
	const [load, setLoad] = useState(false);
	const [items, __setItems] = useState(progsItems[id] ?? []);
	const [existItems, setExistItems] = useState({});
	const [deleteItems, setDeleteItems] = useState([]);
	const [newItems, __setNewItems] = useState(progNewItems[id] ?? {});
	const [itemsSort, __setItemsSort] = useState([]);
	const [itemSets, __setItemSets] = useState(progItemsSets[dayId] ?? []);

	useEffect(() => {
		if (!progsItems[id])
			progsItems[id] = [];
		if (!progNewItems[id])
			progNewItems[id] = {};
		if (!progItemsSets[dayId])
			progItemsSets[dayId] = [];
	},[]);

	useEffect(() => {
		if (existItems[dayId])
			onLoad(existItems[dayId]);
	},[existItems]);

	const setOnChange = () => {
		progChangeList[id] = true;
		onChange();
	};

	const setItemsSort = (items, startIndex, endIndex) => {
		const [removed] = existItems[dayId].splice(startIndex, 1);
		existItems[dayId].splice(endIndex, 0, removed);
		setExistItems(existItems);
		__setItemsSort(items);
		setOnChange();
	};
	
	const setItems = (items) => {
		progsItems[id] = items;
		__setItems(items);
	};
	ProgItems.setItems = setItems;

	const setNewItems = (items) => {
		progNewItems[id] = items;
		__setNewItems(items);
		setOnChange();
	};
	ProgItems.setNewItems = setNewItems;
	
	const setItemSets = (sets) => {
		if (progItemsSets[dayId] && progItemsSets[dayId].length > 0) {
			if (sets.length > progItemsSets[dayId].length) {
				setOnChange();
				__setItemSets(progItemsSets[dayId]);
				return;
			}
			
			for(let key in progItemsSets[dayId]) {
				const set = progItemsSets[dayId][key];
				if (set.id === 0) {
					setOnChange();
					__setItemSets(progItemsSets[dayId]);
					return;
				}	
			}
		}
		setOnChange();
		__setItemSets(sets);
		progItemsSets[dayId] = sets;
	};
	
	const itemExists = (day, id) => {
		if (!items[day])
			return false;
		return items[day].indexOf(Number(id)) < 0 ? false : true;
	}

	const itemRestore = (day, id, pos) => {
		id = Number(id);
		if (!items[day])
			return;
		items[day].splice(pos, 0, id);
		setItems({...items});
		deleteItems.splice(deleteItems.indexOf(id), 1)
		progChangeList[id] = true;
	};

	ProgItems.deleteDay = (day) => {
		var deleteExistItems = existItems ?? {};
		delete deleteExistItems[day];
		setExistItems({...existItems, deleteExistItems});
		delete progsItems[id][day];
		delete progNewItems[id][day];
		
		Modal.closeAll();
	};

	ProgItems.addExe = () => {
		Book.setSelected(progsItems[id][dayId]);
		Book.setSelectedObjects(progNewItems[id][dayId]);
		Book.setSelectMode(true, (ids, objs) => {
			//App.back(() => {
				if (!newItems[dayId])
					newItems[dayId] = {};
				newItems[dayId] = {...newItems[dayId], ...objs};
				ProgItems.setNewItems({...newItems});

				if (!items[dayId])
					items[dayId] = [];
				for(let newId of ids) {
					if (items[dayId].indexOf(newId) < 0) {
						items[dayId].push(newId);
					}
				}
				
				setItems({...items});
			//});
		});
	};
	
	const deleteItem = (itemId, day, exeid) => {
		
		if (!items[day])
			return;
		
		var deleteList = deleteItems;
		items[day].splice(items[day].indexOf(itemId),1);
		setItems({...items});
		deleteList.push(exeid);
		setDeleteItems(deleteList);
		progChangeList[id] = true;
	};

	const setDataItems = (dataList) => {
		let listItems = progsItems[id] ?? {};
		let listItemObjs = {};
		for(let el of dataList) {
			if (!listItems[el.prog_day])
				listItems[el.prog_day] = [];
			if (listItems[el.prog_day].indexOf(el.id) < 0)
				listItems[el.prog_day].push(el.id);
			if (!listItemObjs[el.prog_day])
				listItemObjs[el.prog_day] = [];

			listItemObjs[el.prog_day].push({
				id: el.prog_item_id,
				typeSets: el.type,
				item_id: el.id,
				day_id: el.prog_day,
				pic: el.pic,
				title: el.title,
				
				element: <Exercise key={el.id} day={el.prog_day} id={el.id} icon={el.pic} title={el.title} draggable={true} onDelete={(id) => deleteItem(id, el.prog_day, el.prog_item_id)}/>
			})
		}
		setExistItems({...listItemObjs});
		setItems({...listItems});
	};
	
	const getNewItemList = (newDay) => {
		var days = {};
		for(let day in newItems) {
			days[newDay ?? day] = Object.keys(newItems[day]).map((id) => (Number(id)));
		}
		return days;
	};

	const getList = (list) => {
		if (list) {
			setDataItems([...list]);
			return;
		}
		setLoad(true);
		apiObj.current = API.get({
			path: 'training/program/items/list/' + id + '/' + dayId,
			data: {},
			cache: 'prog-items-' + id,
			success: ({list, list_sets}) => {
				setDataItems([...list]);
				setItemSets([...list_sets]);
			},
			fail: (e) => {
				console.error(e);
			},
			complete: () => {
				setLoad(false);
			}
		});
	};

	const saveSort = () => {
		if (itemsSort.length <= 0)
			return;
		if (apiObj.current)
			apiObj.current.clearCache();
		API.post({
			path: 'training/program/items/sort',
			data: {
				day: dayId,
				list: itemsSort
			},
			success: () => {},
			fail: (e) => {
				console.error(e);
			}
		});
	};
	const saveList = (saveDayId) => {
		
		if (!progChangeList[id])
			return;
		
		const list_new = getNewItemList(saveDayId);
		if (!(Object.keys(list_new).length > 0 || deleteItems.length > 0 || itemSets.length > 0))
			return;

		saveDayId = saveDayId ?? dayId;
		API.post({
			path: 'training/program/items/update_list',
			data: {
				pid: id,
				did: saveDayId,
				list_new: list_new,
				list_delete: deleteItems,
				list_sets: itemSets
			},
			success: ({list}) => {
				setNewItems({});
				getList(list);
				if (apiObj.current)
					apiObj.current.clearCache();
			},
			fail: (e) => {
				console.error(e);
			},
			complete: () => {
				progChangeList[id] = false;
				setLoad(false);
			}
		});
	};

	ProgItems.clearCache = () => {
		apiObj.current.clearCache();
	}

	ProgItems.save = (dayId) => {
		apiObj.current.clearCache();
		saveList(dayId);
		saveSort();
	};
	
	useEffect(() => {
		if (dayId >= 0)
			getList();
	},[dayId]);

	if (load)
		return(<>
		<DivPlaceholder className="list plate mgbs training-exercise" wait={true}/>
		<DivPlaceholder className="list plate mgbs training-exercise" wait={true}/>
		<DivPlaceholder className="list plate mgbs training-exercise" wait={true}/>
		</>);
	
	const listExistItems = existItems[dayId] ?? [];
	
	return(<>
		{Object.keys(newItems).map((day) => Number(day) === dayId ? Object.keys(newItems[day]).map((item, i) => 
			<div key={i} className="list plate mgbs training-exercise">
				{newItems[day][item].element}
			</div>
		) : null)}
		<DragItems onChange={setItemsSort}>
			{
				listExistItems.map((item, i) => 
				<DragItems.Item key={i} index={i} id={item.id} className="list plate mgbs training-exercise">
					{itemExists(dayId, item.item_id) ? null : <ItemRestore timeout={id ? null : 11} onRestore={() => itemRestore(dayId, item.item_id, i)} onDelete={setOnChange}/>}
					<Exercise key={item.item_id} day={item.day_id} id={item.item_id} icon={item.pic} title={item.title} draggable={true} onDelete={(id) => deleteItem(id, item.day_id, item.id)}/>
					<ProgItemSets id={item.id} title={item.element.props.title} type={item.typeSets} icon={item.element.props.icon} dataItemSets={itemSets} onChange={setItemSets}/>
				</DragItems.Item>
				)
			}
		</DragItems>
	</>);
};

export default ProgItems;