import React,{ useRef, useEffect, useState } from "react";
import Modal from "../../Inc/Modal";
import ListPrograms from "../Inc/ListPrograms";
import Tabs from "../../Inc/Tabs/Tabs";
import App from "../../../App";
import Calendar from "../../Inc/Calendar";
import DivPlaceholder from "../../Inc/DivPlaceholder";
import API from "../../../Api/API";
import { dateDay } from "../../Inc/Helpers";
import Button from "../../Inc/Button/Button";

const FormIntersect = ({onSubmit, waitSubmit}) => {
	const [intersectData, setIntersectData] = useState([]);
	const submit = () => {
		if (typeof onSubmit === 'function') {
			onSubmit(true);
		}
	};

	FormIntersect.show = (data) => {
		setIntersectData(data);
		Modal.show('program-select-intersect');
	};
	
	FormIntersect.close = () => {
		setIntersectData([]);
		Modal.close('program-select-intersect');
	};
	
	return(
		<Modal id="program-select-intersect">
			<div className="wrapper">
				Пересечение программ:
				<ul>
					{intersectData.map((item, key) => <li key={key}>{item.title}</li>)}
				</ul>
				<div className="buttons flex grows">
					<Button title="Отмена" disabled={waitSubmit} onPress={FormIntersect.close}/>
					<Button title="ОК" type="accent" spinner={waitSubmit} disabled={waitSubmit} onPress={submit}/>
				</div>
			</div>
		</Modal>
	);
};

const ProgSelect = ({selectDayDate, onChange}) => {
	onChange = onChange ?? (()=>{});
	const selectDay = selectDayDate;
	selectDayDate = selectDayDate.toLocaleString({timeZone: "UTC"});
	const apiObj = useRef({});
	const [newSelected, __setNewSelected] = useState({
		id: 0,
		title: 'Свободная программа'
	});
	const [wait, setWait] = useState(false);
	const [waitSubmit, setWaitSubmit] = useState(false);
	const [id, setId] = useState(0);
	const [title, setTitle] = useState('Свободная программа');
	const [date, __setDate] = useState({
		start: new Date(),
		stop: new Date()
	});

	const setDate = (varDate) => {
		if (typeof varDate === 'object' && varDate.start && varDate.stop) {
			__setDate(varDate);
		}else{
			__setDate({
				start: varDate,
				stop: varDate
			});
		}
	};

	const openProgram = () => {
		if (id <= 0)
			return;
		App.navigate('/training/prog/' + id);
	};

	const show = () => {
		Modal.show('program-select');
	};

	const close = () => {
		Modal.close('program-select');
	};

	const setNewSelect = (id, {title}) => {
		__setNewSelected({
			id: id,
			title: title
		});
	};

	const submit = (force) => {
		if (newSelected.id <= 0)
			return;
		setWaitSubmit(true);
		API.post({
			path: 'training/diary/prog_select',
			data: {
				id: newSelected.id,
				day_start: selectDayDate,
				force: (force === true ? '1' : '0')
			},
			success: ({intersect}) => {
				if (intersect.length > 0) {
					FormIntersect.show(intersect);
					return;
				}
				onChange(newSelected.id);
				apiObj.current.clearCache();
				setId(newSelected.id);
				setTitle(newSelected.title);
				close();
				FormIntersect.close();
			},
			fail: (e) => {
				console.error(e);
			},
			complete: () => {
				setWaitSubmit(false);
			}
		});
		
	};

	useEffect(() => {
		setWait(true);
		apiObj.current = API.post({
			path: 'training/diary/prog_selected',
			data: {
				day_date: selectDayDate
			},
			cache: 'diary-prog-select',
			success: ({item}) => {
				if (item) {
					setId(item.pid);
					setTitle(item.title);
					setDate({
						start: new Date(item.date_start),
						stop: new Date(item.date_stop)
					});
				}else{
					setId(0);
					setTitle('Свободная программа');
					setDate(selectDay);
				}
			},
			fail: (e) => {
				console.error(e);
			},
			complete: () => {
				setWait(false);
			}
		});
	},[selectDayDate]);

	return(<>
		<div className="training-day-prog flex divider">
			<div className=""><i className="ic ic-task"></i></div>
			<DivPlaceholder wait={wait} className="grow">
				<a className="prog-name" onClick={openProgram}>{title}</a>
				<div className="prog-date">Активна с {dateDay(date.start)} по {dateDay(date.stop)}</div>
			</DivPlaceholder>
			<div className=""><a onClick={show} className="button primary small">Изменить</a></div>
		</div>
		<Modal id="program-select">
			<div className="tab-control" rel="training">
				<div className="tab-item"><Tabs.NavTab to="select-program" className="active"> Мои</Tabs.NavTab></div>
			</div>
			
			<Tabs history={false}>
				
				<Tabs.Item id="select-program" active={true}>
					<div className="list">
						<ListPrograms selectMode={true} selectPid={id} onSelect={setNewSelect}/>
					</div>
				</Tabs.Item>
			</Tabs>
			<div className="wrapper">
				<div className="buttons flex grows">
					<Button title="Отмена" disabled={waitSubmit} onPress={close}/>
					<Button title="ОК" type="accent" spinner={waitSubmit} disabled={waitSubmit} onPress={submit}/>
				</div>
			</div>
		</Modal>

		<FormIntersect onSubmit={submit} waitSubmit={waitSubmit}/>
	</>);
};

export default ProgSelect;