import React,{ useEffect, useState } from "react";

const Spoiler = ({head, children, onOpen}) => {
	onOpen = onOpen ?? (()=>{});
	const [opened, setOpened] = useState(false);
	const toggle = () => {
		if (!opened)
			onOpen();
		setOpened(!opened);
	};

	return(<div className={"spoiler" + (opened ? ' active' : '')}>
		<div className="spoiler-head" onClick={toggle}>{head}</div>
		<div className="spoiler-content">{children}</div>
	</div>);
};

export default Spoiler;