import React from "react";
import { Link, NavLink } from "react-router-dom";
import File from "../File";
import style from './style.css';

class MenuSub extends React.Component {
	static menuSubs = {};
	constructor(props) {
		super(props);

		this.__open = this.__open.bind(this);
		this.__close = this.__close.bind(this);
		this.__toggle = this.__toggle.bind(this);

		this.containerRef = React.createRef();

		if (props.id)
			MenuSub.menuSubs[props.id] = this;
	}

	static toggle(id, e) {
		e.preventDefault();
		e.target.classList.toggle('active');
		if (MenuSub.menuSubs[id])
			MenuSub.menuSubs[id].__toggle();
		return false;
	}

	__open() {
		this.containerRef.current.classList.add('opened');
	}

	__close() {
		this.containerRef.current.classList.remove('opened');
	}

	__toggle() {
		this.containerRef.current.classList.toggle('opened');
	}

	static Toggler({id}) {
		return(<>
			<i className="ic toggler" onClick={(e) => MenuSub.toggle(id, e)}></i>
		</>);
	}

	render() {
		return(<>
		<div className="menu-sub-list" ref={this.containerRef}>
			{this.props.children}
		</div>
		</>);
	}
	
};

export default class Menu extends React.Component {
	static obj;
	constructor(props) {
		super(props);
		
		this.oldTouch = 0;
		this.mobileMenuref = React.createRef();
		
		this.__show = this.__show.bind(this);
		this.__hide = this.__hide.bind(this);
		this.swipeStart = this.swipeStart.bind(this);
		this.swipeStop = this.swipeStop.bind(this);
		this.swipeMenu = this.swipeMenu.bind(this);
		
		Menu.obj = this;
	}

	static show() {
		Menu.obj.__show();
	}

	static hide() {
		Menu.obj.__hide();
	}

	swipeStart(e) {
		const menuWidth = this.mobileMenuref.current.clientWidth;
		e.target.style.width = menuWidth + 'px';
		this.mobileMenuref.current.style.transition = 'unset';
	}

	swipeStop(e) {
		const menuWidth = this.mobileMenuref.current.clientWidth;
		e.target.style.width = null;
		this.mobileMenuref.current.style.left = null;
		this.mobileMenuref.current.style.transition = null;
		if (this.oldTouch > menuWidth / 2) {
			this.__show();
		}else{
			this.__hide();
		}
		
	}

	swipeMenu(e) {
		const menuWidth = this.mobileMenuref.current.clientWidth;
		const touch = e.nativeEvent.touches[0].clientX;
		if (touch <= menuWidth) {
			this.mobileMenuref.current.style.left = (touch - menuWidth) + 'px';
		}
		this.oldTouch = touch;
	}

	__show() {
		if (!this.mobileMenuref.current)
			return;
		this.mobileMenuref.current.classList.add("opened");
	}

	__hide() {
		if (!this.mobileMenuref.current)
			return;
		this.mobileMenuref.current.classList.remove("opened");
	}

	render() {
		return(
			<div className="side-content" id="m-menu" ref={this.mobileMenuref}>
				<div className="background-menu" onClick={this.__hide}></div>
				<div id="main-menu">
					<div className="user">
						<NavLink className="pic-box user-pic" to="user"><File.Avatar uid={this.props.user.id} size="50"/></NavLink>
						<NavLink className="user-name" to="user">{this.props.user.nick ?? '...'}</NavLink>
					</div>
					<div className="menu">
						<NavLink to="/posts" className={(this.props.location.pathname === '/' ? 'active' : '')}><i className="ic ic-news"></i><span>Новости</span></NavLink>
						<NavLink to="groups.htm"><i className="ic ic-locator"></i><span>Группы</span><MenuSub.Toggler id="group-sub-menu"/></NavLink>
						<MenuSub id="group-sub-menu">
							<a href="admin-group-timetable.htm"><i className="ic ic-ch-right"></i><span>Админка</span></a>
							<a href="group-my-visits.htm"><i className="ic ic-ch-right"></i><span>Мои записи</span></a>
							<a href="group-page-blogger.htm"><i className="ic ic-ch-right"></i><span>Группа блогера</span></a>
						</MenuSub>
						<a href="bloggers.htm"><i className="ic ic-c-play"></i><span>Блогеры</span></a>
						<a href="food.htm"><i className="ic ic-dish"></i><span>Питание</span><MenuSub.Toggler id="food-sub-menu"/></a>
						<MenuSub id="food-sub-menu">
							<a href="store.htm"><i className="ic ic-ch-right"></i><span>Спортпит</span></a>
							<a href="store-category.htm"><i className="ic ic-ch-right"></i><span>Доставка питания</span></a>
							<a href="store.htm"><i className="ic ic-ch-right"></i><span>Программы питания</span></a>
							<a href="food-test.htm"><i className="ic ic-ch-right"></i><span>Test</span></a>
						</MenuSub>
						<NavLink to="training"><i className="ic ic-barbell"></i><span>Тренировки</span></NavLink>
						<a href="notes.htm"><i className="ic ic-pen"></i><span>Заметки</span></a>
						<NavLink to="measurement"><i className="ic ic-measure"></i><span>Измерения</span></NavLink>
						<a href="calendar.htm"><i className="ic ic-calendar"></i><span>Календарь</span></a>
						<NavLink to="friends"><i className="ic ic-friends"></i><span>Друзья</span></NavLink>
						<a href="messages.htm"><i className="ic ic-message"></i><span>Сообщения</span></a>
						<a href="lottery.htm"><i className="ic ic-prize"></i><span>Лотерея</span></a>
						<a href="store.htm"><i className="ic ic-store"></i><span>Магазин</span></a>

						<NavLink to="/settings"><i className="ic ic-settings"></i><span>Настройки</span></NavLink>
						
						<a href="admin.htm" className="accent toggler" rel="adm-sub-menu"><i className="ic ic-setup"></i><span>Админ-панель</span><MenuSub.Toggler id="adm-sub-menu"/></a>
						<MenuSub  id="adm-sub-menu">
							<a href="admin-stats.htm"><i className="ic ic-ch-right"></i><span>Статистика</span></a>
							<a href="admin-mail.htm"><i className="ic ic-ch-right"></i><span>Рассылки</span></a>
							<a href="admin-sell.htm"><i className="ic ic-ch-right"></i><span>Продажи</span></a>
							<a href="food-prog.htm"><i className="ic ic-ch-right"></i><span>Питание</span></a>
							<a href="training-prog.htm"><i className="ic ic-ch-right"></i><span>Тренировки</span></a>
							<a href="food-product.htm"><i className="ic ic-ch-right"></i><span>Рецепт</span></a>
							<a href="store-product-edit.htm"><i className="ic ic-ch-right"></i><span>Товар</span></a>
						</MenuSub>
					</div>
				</div>
				<div className={style.swipeBlock} onTouchStart={this.swipeStart} onTouchEnd={this.swipeStop} onTouchMove={this.swipeMenu}/>
			</div>
		);
		}
	}