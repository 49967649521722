import { useEffect, useState } from "react";
import Modal from "../Inc/Modal";
import HeadLine from "../Inc/HeadLine/HeadLine";
import API from "../../Api/API";
import Message from "../../Message";
import App from "../../App";
import DivPlaceholder from "../Inc/DivPlaceholder";
import Button from "../Inc/Button/Button";

const ModalSelect = ({selectedItems, type, id, onSubmit}) => {
	onSubmit = onSubmit ?? (()=>{});
	const [items, setItems] = useState([]);
	
	var data = [];
	if (type === 'hobbies') {
		data = App.getMain().hobbies;
	}
	if (type === 'group_directions') {
		data = App.getMain().group_directions;
	}

	const submit = () => {
		onSubmit(items);
		Modal.close(id);
	};

	const reset = () => {
		setItems([]);
	};

	const setChecked = (id, status) => {
		var itemList = items;
		const index = itemList.indexOf(id);
		if (status) {
			if (index < 0)
				itemList.push(id);
		}else{
			if (index >= 0)
				itemList.splice(index, 1);
		}
		setItems([...itemList]);
	};

	const checkSelect = (id) => {
		return items.indexOf(id) >= 0 ? true : false;
	};

	useEffect(() => {
		setItems(selectedItems ?? []);
	},[selectedItems]);

	return(<Modal id={id}>
		<div className="wrapper">
			<div className="long-list">
			{data.map((item, i) => 
				<div key={i} className="item">
					<label className="checkbox">
						<input type="checkbox" defaultValue="1" onChange={(e) => setChecked(item.id, e.target.checked)} checked={checkSelect(item.id)}/>
						<i></i>
						<span>{item.title}</span>
					</label>
				</div>
			)}
			</div>
			<div className="actions">
				<a className="button" onClick={submit}>ОК</a>
				<a className="button primary" onClick={reset}>Сброс</a>
				<a className="button accent" onClick={() => Modal.close(id)}>Отмена</a>
			</div>
		</div>
	</Modal>);
};

const Data = () => {
	const [waitUpdate, setWaitUpdate] = useState(false);
	const [about, setAbout] = useState();
	const [experience, setExperience] = useState();
	const [club, setClub] = useState();
	const [hobbies, setHobbies] = useState([]);
	const [sports, setSports] = useState([]);
	const [isLoad, setIsLoaded] = useState(false);
	HeadLine.setTitle('Мои данные', 'Настройки');
	HeadLine.setProps({
		back: true
	});
	
	const getValueArr = (list) => {
		var ret = [];
		if (list) {
			for(let li of list) {
				ret.push(Number(li.value));
			}
		}
		return ret;
	};

	const getData = () => {
		API.get({
			path: 'settings/data/list',
			data: {},
			success: ({list}) => {
				setAbout(list.about);
				setExperience(list.experience);
				setClub(list.club);
				setHobbies(getValueArr(list.hobbies));
				setSports(getValueArr(list.sports));
				setIsLoaded(true);
			},
			fail: (e) => {
				console.error(e);
			}
		});
	};
	
	const update = (e) => {
		setWaitUpdate(true);
		API.post({
			path: 'settings/data/update',
			data: {
				about: about,
				experience: experience,
				club: club,
				hobbies: hobbies,
				sports: sports
			},
			success: (e) => {
				Message.success('Сохранено');
			},
			fail: (e) => {
				console.error(e);
			},
			complete: () => {
				setWaitUpdate(false);
			}
		});
		e.preventDefault();
	};

	const SelectedNames = ({type}) => {
		var data = [];
		var list = [];
		if (type === 'hobbies') {
			data = App.getMain().hobbies;
			list = hobbies;
		}
		if (type === 'group_directions') {
			data = App.getMain().group_directions;
			list = sports;
		}
		if (!list.length)
			return(<></>);
		return(<div className="setting-field button-list">
			{data.map((item, i) => list.indexOf(item.id) >= 0 ? <span key={i}>{item.title}</span> : null )}
		</div>);
	};

	HeadLine.setAction({
		show: true,
		mobile: true,
		check: update
	});

	useEffect(() => {
		getData();
	},[]);

	return(<>
	<div className="main-content">
		<form onSubmit={update} className="">
			<DivPlaceholder className="settings-items plate" wait={!isLoad}>
				<div className="setting-cap">Мои данные</div>
				<div className="setting-field">
					<textarea placeholder="О себе" value={about} onChange={(e) => setAbout(e.target.value)}/>
				</div>
				<div className="setting-field">
					<input placeholder="Стаж тренировок" value={experience} onChange={(e) => setExperience(e.target.value)}/>
				</div>
				<div className="setting-field">
					<input placeholder="Спортивный клуб" value={club} onChange={(e) => setClub(e.target.value)}/>
				</div>
			</DivPlaceholder>
			
			<DivPlaceholder className="settings-items plate" wait={!isLoad}>
				<div className="setting-cap">Спортивные направления</div>
				<SelectedNames type="group_directions"/>
				<div className="setting-field">
					<a className="button primary modal" onClick={() => Modal.show('sport-dir')}><i className="ic ic-pencil"></i> Редактировать</a>
				</div>
			</DivPlaceholder>
			
			<DivPlaceholder className="settings-items plate" wait={!isLoad}>
				<div className="setting-cap">Хобби и увлечения</div>
				<SelectedNames type="hobbies"/>
				<div className="setting-field">
					<a className="button primary" onClick={() => Modal.show('hobbie-dir')}><i className="ic ic-pencil"></i> Редактировать</a>
				</div>
			</DivPlaceholder>
			{isLoad ? <Button type="accent last" title="Сохранить" spinner={waitUpdate} disabled={waitUpdate} submit/> : null}
			
		</form>
	</div>

	<div className="side-content">

	</div>

	<ModalSelect type="hobbies" id="hobbie-dir" selectedItems={hobbies} onSubmit={(list) => setHobbies(list)}/>
	<ModalSelect type="group_directions" id="sport-dir" selectedItems={sports} onSubmit={(list) => setSports(list)}/>
	</>);
};
export default Data;