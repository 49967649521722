import API from "./API";

export default class Login {
	static user = {};

	static register(email, password, nick, succ = (() => {}), err = (() => {})) {
		API.post({
			path: 'auth/register',
			data: {
				email: email,
				password: password,
				nick: nick
			},
			success: (e) => {
				Login.user = e.user;
				succ(e);
			},
			fail: (e) => {
				err(e);
			},
			error: (e) => {
				err(e);
			}
		});
	}

	static auth(email, password, succ = (() => {}), err = (() => {})) {
		API.post({
			path: 'auth/login',
			data: {
				email: email,
				password: password
			},
			success: (e) => {
				Login.user = e.user;
				succ(e);
			},
			fail: (e) => {
				err(e);
			},
			error: (e) => {
				err(e);
			}
		});
	}

	static check(token, succ = (() => {}), err = (() => {})) {
		API.post({
			path: 'auth/get',
			data: {
				token: token,
			},
			success: (e) => {
				Login.user = e.user;
				succ(e);
			},
			fail: (e) => {
				err(e);
			},
			error: (e) => {
				err(e);
			}
		});
	}

	static getUser() {
		return Login.user;
	}

	static getUserAge() {
		var age = 0;
		const now = new Date();
		const birthday = new Date(Login.user.birthday);
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
		var birthdayNow = new Date(today.getFullYear(), birthday.getMonth(), birthday.getDate());
		age = today.getFullYear() - birthday.getFullYear();
		if (today < birthdayNow)
			age = age - 1;
		return age;
	}
}