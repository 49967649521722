import React,{ useEffect, useState } from "react";
import '../../css/calendar.css';
import Modal from "./Modal";

class Calendar extends React.PureComponent {
	/* Fields */
	
	view = null;
	items = null;
	
	next = null;
	prev = null;
	
	start = null;
	selected = null;
	today = null;
	
	full_mode = true;
	show_days = true;
	smooth = false;
	
	WEEK_MILLIS = 1000*7*24*3600;
	buffer = 3;
	item_margin = 10;
	
	/* events */
	onDaySelect = null;
	
	/* METHODS */
	
	constructor(props) {
		super(props);

		this.state = {
			selected: null
		};

		this.full_mode = this.props.mode === 'week' ? false : true;

		this.onChange = props.onChange ?? (()=>{});
		this.onSubmit = props.onSubmit ?? (()=>{});
		this.value = props.value ?? '';

		this.today = new Date();
		this.state.selected = new Date();

		this.is_scroll = null;
		this.containerRef = React.createRef();

		this.__show = this.__show.bind(this);
		this.__close = this.__close.bind(this);
		this.nextItem = this.nextItem.bind(this);
		this.prevItem = this.prevItem.bind(this);
		this.setItem = this.setItem.bind(this);
		this.scrollItem = this.scrollItem.bind(this);
		this.submit = this.submit.bind(this);
		this.DateString = this.DateString.bind(this);
	}

	componentDidMount() {
		if (!this.full_mode)
			this.init();
	}

	componentDidUpdate() {
		if (this.props.value !== this.value) {
			this.setState({
				selected: this.props.value ? new Date(this.props.value) : new Date()
			});
			this.value = this.props.value;
			this.init();
		}
	}
	
	init() {
		this.state.selected = this.props.value ? new Date(this.props.value) : new Date();
		this.view = this.containerRef.current;
		if (!this.view) return;
		
		this.items = this.view.querySelectorAll('.cv-items')[0];
		
		if (this.full_mode) {
			this.start = new Date(this.state.selected.getTime());
			this.start.setDate(1);
			this.start.setMonth(this.start.getMonth() - this.buffer);
		} else {
			this.start = this.getWeekStart(this.state.selected.getTime() - (this.buffer * this.WEEK_MILLIS))
		}
		
		this.createItems();
	}
	
	getSelectedDate(){
		return this.state.selected;
	}
	
	setSelectedDate(day, call_listener = false){
		if (Object.prototype.toString.call(day) !== '[object Date]') return;
		var old = this.state.selected;
		this.state.selected = day;
		this.init();
		if (call_listener && typeof this.onDaySelect === 'function') this.onDaySelect(day, old);
	}
	
	setOnDaySelectListener(callback){
		this.onDaySelect = callback;
	}
	
	getWeekStart(time) {
		var date = new Date(time);
		if (typeof date.getMonth !== 'function') date = new Date();
		date.setDate(date.getDate() - (date.getDay() == 0 ? 6 : date.getDay() - 1));
		return date;
	}

	loadItems(sign) {
		if (this.full_mode) this.start.setMonth(this.start.getMonth() + this.buffer * sign);
		else this.start.setTime(this.start.getTime() + (this.buffer * this.WEEK_MILLIS) * sign);
		this.createItems();
	}

	createItems() {
		
		if (this.smooth) this.view.classList.remove('smooth');
		
		var date = new Date(this.start.getTime());
		
		this.items.innerHTML = '';
		for (var i = 0; i <= (this.buffer << 1); i++) {
			var item;
			if (this.full_mode) {
				item = this.createMonth( date.getTime() )
				date.setMonth(date.getMonth() + 1);
			} else {
				item = this.createWeek( date.getTime() );
				date.setTime(date.getTime() + this.WEEK_MILLIS)
			}
			item.classList.add('cv-item');
			this.items.appendChild(item);
		}
		
		this.items.scrollLeft = (this.buffer * (this.items.offsetWidth + this.item_margin));
		
		if (this.smooth) this.view.classList.add('smooth');
	}

	createMonth(time){
		
		//time is first day of month
		var date = new Date(time);
		var start = this.getWeekStart(time);
		
		var month = document.createElement('div'); month.classList.add('month-item');
		var label = document.createElement('div'); label.classList.add('month-label');
		var m = date.getMonth();
		
		label.textContent = this.monthLabel(m) + ' ' + date.getFullYear();
		month.appendChild(label)
		
		// Days labels
		var w_days = false;
		if (this.show_days) {
			w_days = document.createElement('div'); w_days.classList.add('week-days');
			for (var i = 0; i < 7; i++) {
				var day = document.createElement('div');
				day.textContent = this.dayLabel((i+1) % 7);
				w_days.appendChild(day)
			}
			month.appendChild(w_days)
		}
		
		var today = this.getFmtDate(this.today);
		var active = this.getFmtDate(this.state.selected);
		
		// Weeks
		for (var w = 0; w < 6; w++) {
		
			var week = document.createElement('div'); week.classList.add('week');
			
			for (var i = 0; i < 7; i++) {
				var day = document.createElement('div');
				var rel = this.getFmtDate(start);
				day.classList.add('day');
				if (rel == active) day.classList.add('active');
				if (rel == today) day.classList.add('today');
				if (start.getMonth() != m) day.classList.add('off');
				day.setAttribute('rel', rel);
				day.innerHTML = '<i></i><b>'+start.getDate()+'</b>';
				week.appendChild(day);
				start.setDate(start.getDate() + 1);
			}
			
			month.appendChild(week);
		
		}
		
		return month;

	}

	createWeek(time){
		
		var date = new Date(time);
		
		var week = document.createElement('div'); week.classList.add('week-item');
		var label = document.createElement('div'); label.classList.add('week-label');
		var days = document.createElement('div'); days.classList.add('week');
		
		week.appendChild(label)
		week.appendChild(days)
		
		label.textContent = this.monthLabel(date.getMonth())
		
		var active = this.getFmtDate(this.state.selected);
		var month = date.getMonth(), update = false;
		var today = this.getFmtDate(this.today);
		
		for (var i = 0; i < 7; i++) {
			var day = document.createElement('div');
			var rel = this.getFmtDate(date);
			day.classList.add('day');
			if (rel == active) day.classList.add('active');
			if (rel == today) day.classList.add('today');
			day.setAttribute('rel', rel);
			day.innerHTML = '<i></i><b>'+date.getDate()+'</b><em>'+this.dayLabel(date.getDay())+'</em>';
			days.appendChild(day);
			if (i == 6) break;
			date.setDate(date.getDate() + 1);
		}
		
		if (month != date.getMonth()) label.textContent += ' / '+this.monthLabel(date.getMonth())
		label.textContent += ' '+date.getFullYear()
		
		return week;
	}
	
	nextItem() {
		this.items.scrollLeft += this.items.offsetWidth;
	}
	
	prevItem() {
		this.items.scrollLeft -= this.items.offsetWidth;
	}

	setItem(event) {
		var day = event.target.closest('.day');
		if (day && !day.classList.contains('off')) {
			var act = this.items.querySelectorAll('.active')[0];
			if (act) act.classList.remove('active');
			day.classList.add('active');
			var old = this.state.selected;
			this.state.selected = this.getDateFromFmt(day.getAttribute('rel'));
			if (typeof this.onDaySelect === 'function') this.onDaySelect(this.state.selected, old);
			this.onChange(this.state.selected);
		}
	}

	scrollItem() {
		clearTimeout(this.is_scroll);
		this.is_scroll = setTimeout(() => {
			if (this.items.scrollLeft == 0) {
				this.loadItems(-1);
			} else if (this.items.scrollLeft >= this.items.scrollWidth - this.items.offsetWidth - this.item_margin) {
				this.loadItems(+1);
			}
		}, 100);
	}

	submit() {
		this.onSubmit(this.state.selected);
		this.__close();
		this.forceUpdate();
	}
	
	getFmtDate(date, sep = '-') { 
		return date.getDate()+sep+(date.getMonth()+1)+sep+date.getFullYear()
	}
	
	getDateFromFmt(str) {
		var d = str.split('-');
		return new Date(d[2],d[1]-1,d[0]);
	}
	
	monthLabel(monthIndex) {
		return [ 'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь' ][monthIndex]
	}
	
	dayLabel(dayIndex) {
		return [ 'вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб' ][dayIndex]
	}

	__show() {
		Modal.show('app-calendar');
		setTimeout(() => {
			this.init();
		});
	}

	__close() {
		Modal.close('app-calendar');
	}

	DateString() {
		return String(this.state.selected.getDate()).padStart(2, "0") + '.' + String(this.state.selected.getMonth() + 1).padStart(2, "0") + '.' + this.state.selected.getFullYear();
	}

	render() {
		if (this.props.mode === 'week') {
			return(<div className="calendar-view" id="app-calendar" ref={this.containerRef}>
				<div className="cv-ctrl">
					<a className="cv-prev" onClick={this.prevItem}><i className="ic ic-ch-left"></i></a>
					<a className="cv-next" onClick={this.nextItem}><i className="ic ic-ch-right"></i></a>
				</div>
				<div className="cv-items wheel" onClick={this.setItem} onScroll={this.scrollItem}></div>
			</div>)
		}

		return(<>
			<a className="date link modal" onClick={this.__show}>
				<i className="ic ic-calendar"></i>
				<span> <this.DateString/> </span>
			</a>
			<Modal id="app-calendar">
				<div className="calendar-view dark" ref={this.containerRef}>
					<div className="cv-top-bg"></div>
					<div className="cv-ctrl">
						<a className="cv-prev" onClick={this.prevItem}><i className="ic ic-ch-left"></i></a>
						<a className="cv-next" onClick={this.nextItem}><i className="ic ic-ch-right"></i></a>
					</div>
					<div className="cv-items wheel" onClick={this.setItem} onScroll={this.scrollItem}></div>
				</div>
				<div className="wrapper">
					<div className="buttons flex grows">
						<a className="button cancel" onClick={this.__close}>Отмена</a>
						<a className="button ok accent" onClick={this.submit}>ОК</a>
					</div>
				</div>
			</Modal>
		</>);
	}
}

export default Calendar;