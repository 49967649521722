import { Link } from "react-router-dom";
import HeadLine from "../Inc/HeadLine/HeadLine";
import PostItem from "../Lists/PostItem";
import Tabs from "../Inc/Tabs/Tabs";
import App from "../../App";

const Posts = () => {
	HeadLine.setTitle('Новости');
	HeadLine.setAction({
		show: true,
		mobile: true,
		edit: () => App.navigate('/posts/edit')
	});
	return(
	<>
		<div className="main-content">
		
			<div className="mobile">
				<div className="tab-control pin" rel="post-tabs">
					<div className="tab-item icon"><Tabs.NavTab to="favourite"><i className="ic ic-star-full"></i></Tabs.NavTab></div>
					<div className="tab-item"><Tabs.NavTab to="tab-news">Новости</Tabs.NavTab></div>
					<div className="tab-item"><Tabs.NavTab to="tab-feed">Лента</Tabs.NavTab></div>
					<div className="tab-item"><Tabs.NavTab to="tab-blog">Блоггеры</Tabs.NavTab></div>
				</div>
			</div>
			
			<Tabs>
				
				<Tabs.Item id="favourite">
					<div className="post-list"><PostItem/></div>
				</Tabs.Item>
				
				<Tabs.Item id="tab-news" active={true}>
					<div className="post-list"><PostItem/></div>
				</Tabs.Item>
				
				<Tabs.Item id="tab-feed">
					<div className="post-list"><PostItem/></div>
				</Tabs.Item>
				
				<Tabs.Item id="tab-blog">&nbsp;</Tabs.Item>
				
			</Tabs>
		
		</div>
		<div className="side-content" id="right-menu">
			<div className="desktop">
			<div className="tab-control vert" rel="post-tabs">
				<div className="tab-item"><Tabs.NavTab to="favourite">Избранное</Tabs.NavTab></div>
				<div className="tab-item"><Tabs.NavTab to="tab-news">Новости</Tabs.NavTab></div>
				<div className="tab-item"><Tabs.NavTab to="tab-feed">Лента</Tabs.NavTab></div>
				<div className="tab-item"><Tabs.NavTab to="tab-blog">Блоггеры</Tabs.NavTab></div>
			</div>
			<Link className="button big accent" to="/posts/edit"><i className="ic ic-edit"></i> Новый пост</Link>
			</div>
		</div>
	</>
	);
};

export default Posts;