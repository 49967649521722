import React,{ useEffect, useState } from "react";
import { createBrowserHistory } from 'history';
import EventEmitter from "../../../EventEmitter";
import style from './style.css';
import API from "../../../Api/API";

const NavTab = React.memo(({to, className, children}) => {
	const [active, setActive] = useState(false);
	useEffect(() => {
		const listener = EventEmitter.subscribe('tab-' + to, setActive);
		return () => {
			listener.remove();
		}
	},[]);
	className = className ?? '';	
	return(<a className={(active ? 'active' : '')} onClick={() => Tabs.open(to)}>{children}</a>);
});

export default class Tabs extends React.PureComponent {
	static objs = [];
	static scrolls = {};
	constructor(props) {
		super(props);
		this.state = {
			selected: '',
			tab: <></>
		};
		this.scroll = 0;
		this.containerRef = React.createRef();
		this.listSelected = [];
		this.oldScrollTop = 0;
		this.oldScrollLeft = 0;
		this.history = props.history ?? true;
		this.RenderTabs = this.RenderTabs.bind(this);
		this.__open = this.__open.bind(this);
		this.__showWithScroll = this.__showWithScroll.bind(this);
		this.__scrollTop = this.__scrollTop.bind(this);
		//window.onpopstate = this.__historyBack;
	}
	
	static NavTab = NavTab;

	static Item() {
		return(null);
	}

	static open(id) {
		for(const tab of Tabs.objs) {
			tab.__open(id);
		}
	}

	static subscribe(id, callback) {
		EventEmitter.subscribe('tab-' + id, callback);
	}

	componentDidMount() {
		const history = createBrowserHistory();
		this.scrollKey = history.location.pathname + history.location.hash;
		if (Tabs.scrolls[this.scrollKey] === undefined) {
			Tabs.scrolls[this.scrollKey] = 0;
		}
		
		this.__setContainerSize();
		this.numTabs = Tabs.objs.push(this);
		const activeId = this.__getActiveTab();
		this.__open(activeId, true);
		this.__scrollTop();
	}

	componentWillUnmount() {
		Tabs.objs.splice(Tabs.objs.indexOf(this), 1);
	}

	__scrollTop() {
		if (Tabs.scrolls[this.scrollKey] <= 0)
			return;
		const leftOffset = this.containerRef.current.offsetLeft;
		//API.requestComplete((e) => {
			//console.log(e)
			//if (!this.containerRef.current)
				//return;
			//console.log(leftOffset, Tabs.scrolls[this.scrollKey])
			//this.containerRef.current.scrollTo(leftOffset, Tabs.scrolls[this.scrollKey]);
		//});
	}

	__historyBack() {
		this.containerRef.current.scrollTop = Tabs.scrolls[this.scrollKey];
		
	}

	__setContainerSize() {
		if (!this.containerRef.current)
			return;
		const updateSize = () => {
			const height = window.innerHeight;
			const element = this.containerRef.current;
			const elementPosition = element.getBoundingClientRect();
			element.style.height = height - elementPosition.top + 'px';
		};
		window.addEventListener('resize', updateSize);
		updateSize();
	}

	__getChildren() {
		if (!this.props.children)
			return [];
		return Array.isArray(this.props.children) ? this.props.children : [this.props.children];
	}

	__getActiveTab() {
		for(let tab of this.__getChildren()) {
			if (!React.isValidElement(tab))
				continue;
			if (tab.props.active)
				return tab.props.id;
		}
	}

	__getIdTabs() {
		return this.__getChildren().map((tab) => {
			if (tab.props.id)
				return tab.props.id;
		});
	}

	__setHistory(id, def) {
		if (!this.history)
			return;
		const history = createBrowserHistory();
		const location = history.location;
		const path = location.pathname;

		const hashGlobal = location.hash.replace('#','');
		const hashArr = hashGlobal.split('/');
		const hash = hashArr[this.numTabs - 1];
		if (hash && def) {
			id = hash;
		}
		
		if (hash && def && this.state.selected === id)
			return;

		var newHash = hashArr;
		newHash[this.numTabs - 1] = id;
		history.replace(path + '#' + newHash.join('/'));
	}

	__getHistory(id, def) {
		const history = createBrowserHistory();
		const location = history.location;

		const hashGlobal = location.hash.replace('#','');
		const hashArr = hashGlobal.split('/');
		const hash = hashArr[this.numTabs - 1];

		if (hash && def) {
			return hash;
		}
		
		if (hash && def && this.state.selected === id)
			return;
		
		return id;
	}

	__open(id, def = false, scroll = false) {
		if (this.selected === id)
			return;

		if (this.__getIdTabs().indexOf(id) < 0)
			return;

		var newId = '';
		if (newId = this.__getHistory(id, def)) {
			id = newId;
		}else{
			return;
		}

		this.setState({
			selected: id
		},() => {
			if (!scroll) {
				this.__setEvent(id);
				this.__setHistory(id, def);
				for(const tab of this.containerRef.current.children) {
					if (tab.id === id) {
						tab.focus();
						return;
					}
				}
			}
		});
		this.selected = id;
		if (this.listSelected.indexOf(id) < 0)
			this.listSelected.push(id);
	}

	__setEvent(id) {
		for(const tab of this.__getIdTabs()) {
			let status = (id === tab) ? true : false;
			EventEmitter.dispatch('tab-' + tab, status);
		}
	}

	__showWithScroll(e) {
		const scrollTop = e.target.scrollTop;
		const scrollLeft = e.target.scrollLeft;
		if (this.oldScrollTop !== scrollTop) {
			Tabs.scrolls[this.scrollKey] = scrollTop;
		}
		for(const tab of e.target.children) {
			if (tab.id !== this.selected) {
				if (
					(this.oldScrollLeft < scrollLeft && scrollLeft > (tab.offsetLeft - tab.clientWidth) + (tab.clientWidth / 5) && scrollLeft < (tab.offsetLeft - tab.clientWidth) + (tab.clientWidth / 2))
					||
					(this.oldScrollLeft > scrollLeft && scrollLeft < (tab.offsetLeft + tab.clientWidth) - (tab.clientWidth / 5) && scrollLeft > (tab.offsetLeft + tab.clientWidth) - (tab.clientWidth / 2))
				) {
					this.__open(tab.id, false, true);
				}
			}
			
			if (
				(this.oldScrollLeft < scrollLeft && scrollLeft > (tab.offsetLeft - tab.clientWidth) + (tab.clientWidth / 2) && scrollLeft < (tab.offsetLeft - tab.clientWidth) + tab.clientWidth)
				||
				(this.oldScrollLeft > scrollLeft && scrollLeft < (tab.offsetLeft + tab.clientWidth) - (tab.clientWidth / 2) && scrollLeft > (tab.offsetLeft + tab.clientWidth) - tab.clientWidth)
			) {
				if (this.isSetEvent === this.selected)
					continue;
				this.__setEvent(tab.id);
				this.__setHistory(tab.id, false);
				this.isSetEvent = this.selected;
			}
		}
		this.oldScrollTop = scrollTop;
		this.oldScrollLeft = scrollLeft;
	}

	RenderTabs() {
		const children = this.__getChildren();
		var ret = [];
		for(const key in children) {
			const tab = children[key];
			
			if (!React.isValidElement(tab))
				continue;
			ret.push(<div key={key} id={tab.props.id} tabIndex={1} className={style.content}>
				{this.listSelected.indexOf(tab.props.id) >= 0 ? tab.props.children : null}
			</div>)
		}
		return(ret);
	}

	render() {
		return(<>
			{this.props.children}
			<div className={style.container} ref={this.containerRef} onScroll={this.__showWithScroll}>
				<this.RenderTabs/>
			</div>
		</>);
	}
}