import Groups from "./Groups";
import App from "../../../App";
import SubMenu from "../../Inc/SubMenu/SubMenu";
import Modal from "../../Inc/Modal";


const MeasureItem = ({id, type, group_id, value, value2, value3, value4, old_values, date_time, onClick, onDelete, isGroup}) => {
	const dateItem = new Date(date_time);
	const time = String(dateItem.getHours()).padStart(2, '0') + ':' + String(dateItem.getMinutes()).padStart(2, '0');
	const date = String(dateItem.getDate()).padStart(2, '0') + '.' + String(dateItem.getMonth() + 1).padStart(2, '0') + '.' + dateItem.getFullYear();
	onClick = onClick ?? (()=>{});
	onDelete = onDelete ?? (()=>{});
	
	const click = () => {
		if (isGroup) {
			if (type === 1)
				App.navigate('/measurement/page/pressure');
			if (type === 2) {
				App.navigate('/measurement/page/' + group_id);
			}
		}else{
			onClick(id);
		}
	};

	const remove = () => {
		onDelete(id);
		Modal.close("measure-delete" + id);
	};

	if (type === 1)
		return(<>
		<a className="measure-item" onClick={click}>
			<div className="pic-box ic-heart-pulse"></div>
			<div className="measure-timer">
				<div><i className="ic ic-clock"></i><div className="time">{time}</div></div>
			</div>
			<div className="measure-blood"><span className="sist">{value}</span> /<span className="diast">{value2}</span> <i className="ic ic-heart-pulse"></i><span className="pulse">{value3}</span></div>
		</a>
		</>);
	if (type === 2) {
		const group = Groups.get(group_id);
		const typeVal = group_id === 2 ? 'кг' : 'см';
		var offsetVal = '';
		var offsetString = '';
		
		if (isGroup) {
			offsetVal = Number(old_values.length >= 1 ? value - old_values[0] : 0);
			offsetString = offsetVal > 0 ? '+ ' + offsetVal : '- ' + (-offsetVal);
		}
		
		return(<>
		<a className="measure-item" onClick={click}>
			<div className={"measure-pic pic-box ic-" + group.name}></div>
			{
				isGroup ? <>
					<div className="measure-name">{group.title}</div>
					{
						offsetVal === 0 ? null :
						<div className={"measure-ind " + (offsetVal > 0 ? 'up' : 'down')}>
							<b className={"ic ic-b-" + (offsetVal > 0 ? 'up' : 'down')}></b>
							<span>{offsetString} {typeVal}</span>
						</div>
					}
					<div className="measure-val"><b>{value}</b><span>{typeVal}</span></div>
				</> : <>
					<div className="measure-name">{date}</div>
					<div className="measure-val"><b>{value}</b><span>{typeVal}</span></div>
					<div className="measure-menu s-menu" onClick={(e) => SubMenu.show('measure-' + id, e)}><i className="ic ic-dots"></i></div>
				</>

			}
			
		</a>
		<SubMenu id={'measure-' + id}>
			<a onClick={() => Modal.show('measure-delete' + id)}><i className="ic ic-delete"></i>Удалить</a>
		</SubMenu>
		<Modal id={"measure-delete" + id}>
			<div className="wrapper">
				<div className="title">Удалить измерение?</div>
				<div className="actions">
					<a className="button" onClick={remove}>Да</a>
					<a className="button accent" onClick={() => Modal.close("measure-delete" + id)}>Отмена</a>
				</div>
			</div>
		</Modal>
		</>);
	}
		
};

export default MeasureItem;