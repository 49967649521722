import React,{ useLayoutEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

var navigateCallback = (() => {});
const navigate = (Component) => {
	
	const Wrapper = (props) => {
		const locat = useLocation();
		const nav = useNavigate();
		const navigate = (to, callback = (()=>{})) => {
			nav(to);
			navigateCallback = callback;
		};
		useLayoutEffect(() => {
			navigateCallback();
			navigateCallback = (() => {});
		}, [locat]);
		return (
			<Component
			navigate={navigate}
			location={locat}
			{...props}
			/>
		);
	};
	return Wrapper;
};

export default navigate;