import React,{ useEffect, useState, useRef } from "react";
import SubMenu from "../Inc/SubMenu/SubMenu";
import File from "../Inc/File";
import API from "../../Api/API";
import Message from "../../Message";
import App from "../../App";

const User = ({id, nick, firstname, lastname, middlename, city, sport_dirs, friend, onConfirm, onDelete, isRequest, isFriend}) => {
	onDelete = onDelete ?? (()=>{});
	onConfirm = onConfirm ?? (()=>{});
	sport_dirs = sport_dirs ? sport_dirs.split(',') : [];
	
	const [addedFriend, setAddedFriend] = useState(friend);
	
	const userName = firstname ? firstname + ' ' + lastname : nick;

	const add = () => {
		if (API.Login.getUser().id === id) {
			Message.error('Себя добавить в друзья нельзя :-)');
			return;
		}

		API.post({
			path: 'friends/add',
			data: {
				id: id
			},
			success: (e) => {
				setAddedFriend(0);
				Message.success('Запрос в друзья отправлен');
			},
			fail: (e) => {
				console.error(e);
			}
		});
	};

	const confirm = (status) => {
		API.post({
			path: 'friends/confirm',
			data: {
				id: id,
				status: status ? 1 : 0
			},
			success: () => {
				if (status) {
					Message.success('Добавлен');
				}else{
					Message.success('Заявка отменена');
				}
				onConfirm(id);
			},
			fail: (e) => {
				console.error(e);
			}
		});
	};

	const remove = () => {
		API.post({
			path: 'friends/delete',
			data: {
				id: id
			},
			success: () => {
				setAddedFriend(null);
				onDelete(id);
			},
			fail: (e) => {
				console.error(e);
			}
		});
	};

	return(<>
	<a className="user-item" onClick={() => App.navigate('/id' + id)}>
		<div className="user-pic pic-box"><File.Avatar uid={id} size="100"/></div>
		<div className="user-info">
			<div className="user-name">{userName}</div>
			{
				sport_dirs.length ? 
				<div className="user-sport"> {sport_dirs.map((dir, i) => (dir + ((sport_dirs.length - 1) > i ? ', ' : '')))} </div>
				: null
			}
			
			<div className="user-extra"> {city} </div>
		</div>
		<div className="user-menu s-menu" onClick={(e) => SubMenu.show("friends-" + isRequest + isFriend + id, e)}><i className="ic ic-dots"></i></div>
	</a>
	<SubMenu id={"friends-" + isRequest + isFriend + id}>
		{
			isRequest ?
			<>
				<a onClick={() => confirm(true)}><i className="ic ic-follow"></i> Подтвердить</a>
				<a onClick={() => confirm(false)}><i className="ic ic-delete"></i> Удалить</a>
			</> :
			<>
				{
					isFriend || (typeof addedFriend === 'number' && addedFriend >= 0) ?
						<a onClick={remove}><i className="ic ic-delete"></i> Удалить из друзей</a>
					:
						<a onClick={add}><i className="ic ic-follow"></i> Добавить в друзья</a>
				}
				<a><i className="ic ic-message"></i> Отправить сообщение</a>
			</>
		}
		
	</SubMenu>
	</>);
};

export default User;