import React,{ useEffect, useRef, useState } from "react";
import DivPlaceholder from "../../Inc/DivPlaceholder";
import SubMenu from "../../Inc/SubMenu/SubMenu";
import ProgItems from "../Prog/ProgItems";
import Modal from "../../Inc/Modal";
import API from "../../../Api/API";
import App from "../../../App";

const Day = ({title, dayId, onSelect, select}) => {
	onSelect = onSelect ?? (()=>{});
	const [isSelect, setSelect] = useState(false);
	const click = (e) => {		
		setSelect(true);
		onSelect();
	};
	
	useEffect(() => {
		setSelect(select);
	},[select]);
	return(<>
		<a className={"day" + (isSelect ? ' active' : '') + (dayId ? '' : ' disabled')} onClick={click}><i></i><b>{title ?? 0}</b></a>
	</>);
};

const ListDays = ({days, selectDay, isLoad, onSelect, setSelect}) => {
	days = days ?? [];
	if (isLoad)
		return(<>
			<DivPlaceholder className="day" wait={true}/>
			<DivPlaceholder className="day" wait={true}/>
			<DivPlaceholder className="day" wait={true}/>
		</>);

	return(<>
		{
			days.map((day, key) => <Day key={key} dayId={day} select={selectDay === day - 1 ? true : false} onSelect={() => {
				setSelect(day - 1);
				onSelect(day - 1);
			}} title={day}/>)
		}
	</>)
};
var progDaySelect = {};
var progDays = {};

const Days = ({id, progSave, onChange, onSelect}) => {

	progSave = progSave ?? (()=>{});
	onSelect = onSelect ?? (()=>{});
	onChange = onChange ?? (()=>{});

	const apiObj = useRef({});

	const [isLoad, setSetIsLoad] = useState(false);
	const [deleteDays, setDeleteDays] = useState([]);
	const [days, __setDays] = useState(progDays[id] ?? []);
	const [selectDay, __setSelect] = useState(progDaySelect[id] ?? 0);
	const daysRef = useRef();

	const setDays = (listDays, force) => {
		if (!force && progDays[id])
			return;
		__setDays([...listDays]);
		progDays[id] = listDays;
	};

	const clearCache = () => {
		ProgItems.clearCache();
		apiObj.current.clearCache();
		delete progDays[id];
	};
	
	const setSelect = (val) => {
		progDaySelect[id] = val;
		__setSelect(val);
	};

	const listDays = (sign) => {
		const days = daysRef.current;
		days.scrollLeft = days.scrollLeft + (sign)*days.children[0].offsetWidth;
	};

	const newDay = () => {
		const count = days.length + 1;
		days.push(count);
		setDays(days, true);
		onChange(count);
	};

	const addDay = () => {
		newDay();
	};

	const getDays = () => {
		setSetIsLoad(true);
		apiObj.current = API.get({
			path: 'training/program/days/list/' + id,
			data: {},
			cache: true,
			success: ({list}) => {
				if (list.length <= 0)
					return;
				setDays(list);
			},
			fail: (e) => {
				console.error(e);
			},
			complete: () => {
				setSetIsLoad(false);
			}
		});
	};

	const saveDelete = (callback) => {
		callback = callback ?? (()=>{});
		if (deleteDays.length <= 0)
			return;

		API.post({
			path: 'training/program/days/deletes/' + id,
			data: {
				list: deleteDays
			},
			success: () => {
				//apiObj.current.clearCache();
				clearCache();
				getDays();
				setDeleteDays([]);
			},
			fail: (e) => {
				console.error(e);
			},
			complete: (e) => {
				callback(e);
			}
		});
	};
	
	const deleteDay = () => {
		days.splice(selectDay, 1);
		setDays([...days]);
		const deleteList = deleteDays;
		deleteList.push(selectDay);
		setDeleteDays([...deleteList]);
		setSelect(selectDay - 1);
		onChange(days.length);
		Modal.close('prog-day-delete');
	};

	const deleteForm = () => {
		Modal.show('prog-day-delete');
	};

	useEffect(() => {
		if (id > 0) {
			getDays();
		}
	},[]);
	
	Days.save = (callback) => {
		callback = callback ?? (()=>{});
		// update();
		saveDelete(callback);
		if (ProgItems.save)
			ProgItems.save();
	};
	
	return(<>
	<div className="prog-days">
		<div className="cap">
			<div><a className="prev" onClick={() => listDays(-1)}><i className="ic ic-ch-left"></i></a></div>
			<div className="center"><b>Дни программы</b> <a className="add-day" onClick={addDay}><i className="ic ic-o-add"></i></a></div>
			<div><a className="next" onClick={() => listDays(+1)}><i className="ic ic-ch-right"></i></a></div>
		</div>
		<div id="days" ref={daysRef} className="days wheel selective">
			<ListDays days={days} selectDay={selectDay} isLoad={isLoad} onSelect={onSelect} setSelect={setSelect}/>
		</div>
	</div>
	{
		selectDay >= 0 && days.length > 0 ?
		<>
			<div className="training-day plate">
				<div className="training-day-actions flex">
					<div className="grow"><a className="add-exercise" onClick={() => ProgItems.addExe()}><i className="ic ic-o-add"></i> Добавить упражнение</a></div>
					<div className=""><a className="link modal" onClick={deleteForm}>Удалить <i className="ic ic-delete"></i></a></div>
				</div>
			</div>
			<div id="training-exercises">
				<ProgItems id={id} selectDay={selectDay}/>
			</div>
		</>
		: null
	}
	
	<Modal id="prog-day-delete">
		<div className="wrapper">
			<div className="title">Удалить день программы №{selectDay + 1}?</div>
			<div className="actions">
				<a className="button" onClick={deleteDay}>Удалить</a>
				<a className="button accent" onClick={() => Modal.close('prog-day-delete')}>Отмена</a>
			</div>
		</div>
	</Modal>
	</>)
};

export default Days;