import React,{ useEffect, useState, useRef } from "react";
import HeadLine from "./Inc/HeadLine/HeadLine";
import Tabs from "./Inc/Tabs/Tabs";
import API from "../Api/API";
import DivPlaceholder from "./Inc/DivPlaceholder";
import User from "./Lists/User";

var searchTimeout;

const Friends = () => {
	const searchRef = useRef();
	const [isLoad, setIsLoad] = useState(false);
	const [showSearch, setShowSearch] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [searchResult, setSearchResult] = useState([]);
	const [friendsList, setFriendsList] = useState([]);
 	HeadLine.setTitle('Друзья');
	
	HeadLine.setAction({
		show: true,
		search: () => setShowSearch(!showSearch)
	});

	useEffect(() => {
		if (showSearch)
			searchRef.current.focus();
	},[showSearch]);

	const search = () => {
		API.post({
			path: 'user/search',
			data: {
				value: searchText
			},
			success: ({list}) => {
				setSearchResult(list);
				console.log(list);
			},
			fail: (e) => {
				console.error(e);
			}
		});
	};

	const inputSearch = (e) => {
		setSearchText(e.target.value);
		clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			search();
		}, 500);
	};

	const getFriends = (request = false) => {
		setIsLoad(false);
		API.get({
			path: 'friends/list',
			data: {
				request: (request ? 1 : 0)
			},
			success: ({list}) => {
				setFriendsList(list);
			},
			fail: (e) => {
				console.error(e);
			},
			complete: () => {
				setIsLoad(true);
			}
		});
	};

	const removeFriend = (id, i) => {
		friendsList.splice(i, 1);
		setFriendsList([...friendsList]);
		
	};
	
	useEffect(() => {
		Tabs.subscribe('tab-friends', (status) => {
			if (status) {
				getFriends();
			}
		});

		Tabs.subscribe('tab-request', (status) => {
			if (status) {
				getFriends(true);
			}
		});
	},[]);

	const ListUsers = ({isRequest, isFriend}) => {
		return(<div className="user-list plates">
		{
			isLoad ?
				friendsList.length ? friendsList.map((props, i) => <User key={i} isRequest={isRequest} isFriend={isFriend} onConfirm={(id) => removeFriend(id, i)} onDelete={(id) => removeFriend(id, i)} {...props}/>)
				:
				<div>Пусто</div>
			:
			<>
				<DivPlaceholder className="user-item" wait={true}/>
				<DivPlaceholder className="user-item" wait={true}/>
				<DivPlaceholder className="user-item" wait={true}/>
			</>
		}
		</div>);
	};

	return(<>
	<div className="main-content">
	
		<div className={"search-form" + (showSearch ? ' opened' : '')} id="search">
			<input placeholder="Поиск по имени" type="text" ref={searchRef} value={searchText} onChange={inputSearch}/>
			<a><i className="ic ic-search"></i></a>
			<a><i className="ic ic-filter"></i></a>
		</div>
		
		<div className="mobile">
			<div className="tab-control pin" rel="users-panels">
				<div className="tab-item"><Tabs.NavTab to="tab-friends">Мои друзья</Tabs.NavTab></div>
				<div className="tab-item"><Tabs.NavTab to="tab-bloggers">Блоггеры</Tabs.NavTab></div>
				<div className="tab-item"><Tabs.NavTab to="tab-request">Заявки</Tabs.NavTab></div>
			</div>
		</div>
		
		{showSearch ? <div className="user-list plates">
			{searchResult.map((props, i) => <User key={i} isFriend={false} {...props}/>)}
		</div> : null}
	
		<Tabs>

			<Tabs.Item id="tab-friends" active={true}>
				<ListUsers isFriend={true}/>
			</Tabs.Item>
			
			<Tabs.Item id="tab-bloggers">
				<div className="user-list plates">
					<div>Пусто</div> 
				</div>
			</Tabs.Item>
			
			<Tabs.Item id="tab-request">
				<ListUsers isRequest={true}/>
			</Tabs.Item>
			
		</Tabs>
	
	</div>
	
	<div className="side-content">
		<div className="desktop">
			<div className="tab-control vert" rel="friends-tabs">
				<div className="tab-item"><Tabs.NavTab to="tab-friends">Мои друзья</Tabs.NavTab></div>
				<div className="tab-item"><Tabs.NavTab to="tab-bloggers">Блоггеры</Tabs.NavTab></div>
				<div className="tab-item"><Tabs.NavTab to="tab-request">Заявки</Tabs.NavTab></div>
			</div>
		</div>
	</div>
	</>);
};

export default Friends;