import React,{ useRef } from "react";
import * as ReactDOM from 'react-dom';
import EventEmitter from "../../../EventEmitter";
import styles from './style.css';

export default class SubMenu extends React.Component {
	static menus = {};
	static wrapperRef = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			show: false
		};

		this.containerRef = React.createRef();
		this.menuRef = React.createRef();
		this.onSelect = props.onSelect ?? (()=>{});

		this.__show = this.__show.bind(this);
		this.__hide = this.__hide.bind(this);
		this.__forceHide = this.__forceHide.bind(this);
		this.clickSub = this.clickSub.bind(this);
		if (props.id)
			SubMenu.menus[props.id] = this;
	}

	static Wrapper() {
		return(<div ref={SubMenu.wrapperRef}></div>);
	}

	static show(id, e) {
		SubMenu.menus[id].__show({
			x: e.pageX,
			y: e.pageY
		});
		e.preventDefault();
		e.stopPropagation();
	}

	static hide(id) {
		SubMenu.menus[id].__hide();
	}

	static Link = ({to, className, style, children}) => {
		const containerRef = useRef();
		const show = (e) => {
			const position = containerRef.current.getBoundingClientRect();
			if (SubMenu.menus[to])
				SubMenu.menus[to].__show({
					x: e.pageX,
					y: e.pageY
				});
			e.preventDefault();
			e.stopPropagation();
		};
		return(<div onClick={show} className={className} style={style} ref={containerRef}>{children}</div>);
	}

	static subscribeSelect(id, callback, selectVal = 0) {
		if (SubMenu.menus[id]) {
			const list = (val = 0) => {
				for(let item of SubMenu.menus[id].props.children) {
					if (selectVal === item.props.value || val === item.props.value)
						callback(item.props.value, item.props.children);
				}
			};
			list();
			EventEmitter.subscribe('sub-menu-select-' + id, (val) => {
				list(val);
			});
		}
	}

	__show(position) {
		this.setState({
			show: true
		},() => {
			setTimeout(() => {
				this.containerRef.current.classList.add(styles.active);
				var clientWidth = this.menuRef.current.clientWidth;
				var offset = (clientWidth > window.innerWidth - position.x ? clientWidth : 0);
				this.menuRef.current.style.transform = 'translate(' + (position.x - offset) + 'px, ' + position.y + 'px)';
				this.menuRef.current.focus();
			});
		});
	}

	__forceHide() {
		this.__hide(true);
	}

	__hide(force) {
		if (force === true) {
			this.setState({
				show: false
			});
		}
		if (!this.containerRef.current)
			return;
		this.containerRef.current.ontransitionend = ({propertyName}) => {
			if (propertyName === 'opacity') {
				this.setState({
					show: false
				});
				this.containerRef.current.ontransitionend = null;
			}
		};
		this.containerRef.current.classList.remove(styles.active);
	}

	clickSub(e) {
		var value = e.target.getAttribute('value');
		EventEmitter.dispatch('sub-menu-select-' + this.props.id, value);
		this.onSelect(value, e);
	}

	__render() {
		return(<>
			{
				this.state.show ? 
					<div className={styles['sub-menu-container']} ref={this.containerRef}>
						<div className={styles['sub-menu']} tabIndex="1" onBlur={this.__hide} onClick={this.__forceHide} ref={this.menuRef}>
							{React.Children.map(this.props.children,(item) => item ? React.cloneElement(item, {...item.props, onClick: item.props.onClick ?? this.clickSub}) : null)}
						</div>
					</div>
				: null
			}
		</>);
	}

	render() {
		if (!SubMenu.wrapperRef.current)
			return(<></>);
		return ReactDOM.createPortal(
			this.__render(),
			SubMenu.wrapperRef.current
		);	
	}
}