export const declOfNum = (number, words) => {  
    return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]];
};

export const numOfDays = (number) => {
	return number + ' ' + declOfNum(number, ['день', 'дня', 'дней']);
};

export const declOfAges = (number) => {
	return declOfNum(number, ['год', 'года', 'лет']);
}; 

export const numOfAges = (number) => {
	return number + ' ' + declOfAges(number);
};

export const dateDay = (date) => {
	date = typeof date === 'string' ? new Date(date) : date;
	return date.getDate() + '.' + String(date.getMonth() + 1).padStart(2, '0') + '.' + date.getFullYear();
};