import HeadLine from "../Inc/HeadLine/HeadLine";

const About = () => {
	HeadLine.setTitle('О программе', 'Настройки');
	HeadLine.setProps({
		back: true
	});
	return(<>
	<div className="main-content">
	
		<div className="settings-items plate">
			<div className="logo" style={{width: '100%', borderRadius: '10px'}}><img src="../../img/logo.svg" style={{width: '100%', borderRadius: '10px'}}/></div>
			<div className="setting-field">
				<div id="client"><span>Client</span> <b>1.0.5</b></div>
				
			</div>
			<div className="setting-field">
				<div id="core"><span>Core</span> <b>0.1</b></div>
			</div>
			<div className="setting-field">
				<a href="">Пользовательское соглашение</a>
			</div>
			<div className="setting-field">
				<a href="">Политика конфинденциальности</a>
			</div>
		</div>

	
	</div>
	<div className="side-content">

	</div>
	</>);
};
export default About;