import HeadLine from "../Inc/HeadLine/HeadLine";

const Notify = () => {
	HeadLine.setTitle('Уведомления', 'Настройки');
	HeadLine.setProps({
		back: true
	});
	HeadLine.setAction({
		show: true,
		check: () => {}
	});
	return(<>
	<div className="main-content">

		<div className="settings-items plate">
			<div className="setting-cap">Общие</div>
			<div className="setting-field">
				<label className="switch">
					<input type="checkbox" checked/>
					<span>Включить уведомления</span>
					<i></i>
				</label>
			</div>
			<div className="setting-field">
				<label className="switch">
					<input type="checkbox"/>
					<span>Системный звук</span>
					<i></i>
				</label>
			</div>
			<div className="setting-field">
				<label className="switch">
					<input type="checkbox" checked/>
					<span>Вибрация</span>
					<i></i>
				</label>
			</div>
			<div className="setting-field">
				<label className="switch">
					<input type="checkbox"/>
					<span>Мигать экраном</span>
					<i></i>
				</label>
			</div>
			<div className="setting-field">
				<input placeholder="Длительность сигнала"/>
			</div>
		</div>

		<div className="settings-items plate">
			<div className="setting-cap">Уведомления</div>
			<div className="setting-field">
				<label className="switch">
					<input type="checkbox" checked/>
					<span>Сообщения</span>
					<i></i>
				</label>
			</div>
			<div className="setting-field">
				<label className="switch">
					<input type="checkbox" checked/>
					<span>Приём пищи</span>
					<i></i>
				</label>
			</div>
			<div className="setting-field">
				<label className="switch">
					<input type="checkbox" checked/>
					<span>Тренировка</span>
					<i></i>
				</label>
			</div>
			<div className="setting-field">
				<label className="switch">
					<input type="checkbox" checked/>
					<span>Заметка</span>
					<i></i>
				</label>
			</div>
			<div className="setting-field">
				<label className="switch">
					<input type="checkbox" checked/>
					<span>Таймер</span>
					<i></i>
				</label>
			</div>
		</div>

		<div className="settings-items plate">
			<div className="setting-cap">Мелодии</div>
			<div className="setting-field">
				<input placeholder="Сообщение"/>
			</div>
			<div className="setting-field">
				<input placeholder="Приём пищи"/>
			</div>
			<div className="setting-field">
				<input placeholder="Тренировка"/>
			</div>
			<div className="setting-field">
				<input placeholder="Заметка"/>
			</div>
			<div className="setting-field">
				<input placeholder="Таймер"/>
			</div>
		</div>


	</div>
	<div className="side-content">

	</div>
	</>);
};
export default Notify;