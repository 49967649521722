import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from './ScrollToTop';
import './fonts/roboto.css';
import './css/reset.css';
import './css/style.css';
import './css/theme.css';
import './css/anim.css';
import './css/mobile.css';
import './css/icons.css';

import navigate from './Navigate';
import App from './App';
import Message from './Message';
import Modal from './App/Inc/Modal';
import SubMenu from './App/Inc/SubMenu/SubMenu';
import reportWebVitals from './reportWebVitals';

const RenderApp = navigate(App);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<ScrollToTop/>
			<RenderApp />
			<Message/>
			<Modal.Wrapper/>
			<SubMenu.Wrapper/>
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
