export const Loader = ({show}) => {
	if(show)
		return(<div className="row">Загрузка контента</div>);
	else
		return(<></>);
};

export const getExerciseCatImg = (name) => {
	try {
		return require('../../../assets/exercises/cats/' + name + '.png');
	}catch(e) {
		return null;
	}
};

export const getExerciseImg = (name) => {
	try {
		return require('../../../assets/exercises/icons/' + name + '.png');
	}catch(e) {
		return null;
	}
};