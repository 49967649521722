import React,{ useRef, useEffect, useState } from "react";
import Book from "../Book";
import API from "../../../Api/API";
import Exercise from "../Inc/Exercise";
import DragItems from "../Inc/DragItems";
import ProgItemSets from "../Prog/ProgItemSets";
import Modal from "../../Inc/Modal";
import DivPlaceholder from "../../Inc/DivPlaceholder";
import HeadLine from "../../Inc/HeadLine/HeadLine";

const DiaryItems = ({selectDayDate}) => {
	selectDayDate = selectDayDate.toLocaleString({timeZone: "UTC"});
	const apiObj = useRef({});
	const timeoutUpdate = useRef(null);
	const [waitUpdate, setWaitUpdate] = useState(false);
	const [wait, setWait] = useState(false);
	const [waitItems, setWaitItems] = useState([]);
	const [items, setItems] = useState([]);
	const [itemSets, setItemSets] = useState([]);
	const [item, setItem] = useState({});
	const [itemExistsSets, setItemExistsSets] = useState([]);
	DiaryItems.setItems = setItems;
	DiaryItems.setWaitItems = setWaitItems;
	
	const updateItems = (ids) => {
		if (ids) {
			DiaryItems.setWaitItems(ids);
		}
		setWaitUpdate(true);
		API.post({
			path: 'training/diary/update_items',
			data: {
				items: ids,
				sets: itemSets,
				date_day: selectDayDate
			},
			success: ({list, sets}) => {
				DiaryItems.setItems(list);
				setItemExistsSets(sets);
				apiObj.current.clearCache();
			},
			fail: (e) => {
				console.error(e);
			},
			complete: () => {
				DiaryItems.setWaitItems([]);
				setWaitUpdate(false);
			}
		});
	};

	useEffect(() => {
		if (itemSets.length <= 0)
			return;
		setWaitUpdate(true);
		clearTimeout(timeoutUpdate.current);
		timeoutUpdate.current = setTimeout(() => {
			updateItems();
		}, 500);
	},[itemSets]);

	HeadLine.setAction({
		spinner: waitUpdate
	});

	DiaryItems.add = () => {
		Book.setSelectMode(true, (ids) => {
			updateItems(ids);
		});
	};

	const formDeleteItemClose = () => {
		setItem({});
		Modal.close('training-diary-item-delete');
	};

	const formDeleteItem = (item) => {
		setItem(item);
		Modal.show('training-diary-item-delete');
	};

	const findDeleteItem = () => {
		const newItems = items;
		for(const key in items) {
			if (items[key].diary_item_id === item.diary_item_id) {
				newItems.splice(key, 1);
				setItems([...newItems]);
				return;
			}
		}
	};

	const deleteItem = () => {
		formDeleteItemClose();
		API.get({
			path: 'training/diary/item/delete/' + item.diary_item_id,
			data: {},
			success: findDeleteItem,
			fail: (e) => {
				console.error(e);
			}
		});
	};

	const getList = (force) => {
		if (force) {
			apiObj.current.clearCache();
		}
		setWait(true);
		apiObj.current = API.post({
			path: 'training/diary/list_items',
			data: {
				date_day: selectDayDate
			},
			cache: 'diary-items',
			success: ({list, sets}) => {
				setItems(list);
				setItemExistsSets([...sets]);
				
			},
			fail: (e) => {
				console.error(e);
			},
			complete: () => {
				setWait(false);
			}
		});
	};

	DiaryItems.getList = getList;

	useEffect(() => {
		getList();
	},[selectDayDate]);

	if (wait) {
		return(<>
		<DivPlaceholder wait={true}/>
		<DivPlaceholder wait={true}/>
		<DivPlaceholder wait={true}/>
		</>);
	}
	
	return(<>
		{waitItems.map((item, i) => <DivPlaceholder key={i} wait={true}/>)}	
		<DragItems onChange={() => {}}>
		{
			items.map((item, i) => 
				item.diary_prog_id > 0 ? 
				<div key={i} index={i} id={item.id} className="list plate mgbs training-exercise">
					<Exercise key={item.item_id} day={item.day_id} id={item.id} icon={item.pic} title={item.title}/>
					<ProgItemSets id={item.diary_item_id} title={item.title} type={item.type} icon={item.pic} dataItemSets={itemExistsSets} readonly={true}/>
				</div> 
				:
				<DragItems.Item key={i} index={i} id={item.id} className="list plate mgbs training-exercise">
					<Exercise key={item.item_id} day={item.day_id} id={item.id} icon={item.pic} title={item.title} draggable={true} onDelete={() => formDeleteItem(item)}/>
					<ProgItemSets id={item.diary_item_id} title={item.title} type={item.type} icon={item.pic} dataItemSets={itemExistsSets} onChange={setItemSets}/>
				</DragItems.Item>
			)
		}
		</DragItems>
		<Modal id="training-diary-item-delete">
			<div className="wrapper">
				<div className="title">Удалить из дневника "{item.title}"?</div>
				<div className="actions">
					<a className="button" onClick={deleteItem}>Удалить</a>
					<a className="button accent" onClick={formDeleteItemClose}>Отмена</a>
				</div>
			</div>
		</Modal>
	</>);
};

export default DiaryItems;