import HeadLine from "../Inc/HeadLine/HeadLine";

const PostEdit = () => {
	HeadLine.setTitle('Создать пост');
	HeadLine.setProps({
		back: true
	});
	HeadLine.setAction({
		show: true,
		check: () => {}
	});
	return(<>
	<div className="main-content">
	
		<div className="post-item plate" style={{display: 'flex', flexDirection: 'column'}}>
			
			<div className="row"><input style={{width: '100%'}} placeholder="Заголовок новости" /></div>

			<div className="row" style={{flexGrow: 1, flexBasis: '100%'}}><textarea style={{width: '100%', height: '40vh'}} placeholder="Текст новости"></textarea></div>

			<div className="row"><input style={{width: '100%'}} placeholder="Видео" /></div>

			<div className="row">
				<label className="switch">
					<input type="checkbox"/>
					<span>Разрешить комментарии</span>
					<i></i>
				</label>
			</div>

			<div className="row mgbs">
				<div className="caption"><i className="ic ic-image"></i><span>Фотографии</span><a className="s-menu" rel="photo-menu"><i className="ic ic-dots"></i></a></div>
				<div className="photo-grid-list"> include('lists/pics.htm'); </div>
				<div className="buttons auto">
					<a className="button accent"><i className="ic ic-o-add"></i> Добавить</a>
					<a className="button"><i className="ic ic-delete"></i> Очистить</a>
				</div>
			</div>

			<div className="row mgbs">
				<div className="caption"><i className="ic ic-dish"></i><span>Питание</span><a className="s-menu" rel="food-menu"><i className="ic ic-dots"></i></a></div>

					<div id="breakfast">
					<div className="head food">
						<div className="flex"><span className="name grow">Завтрак</span><span className="ccal">--</span></div>
					</div>
					<div className="meals-list mgbs"></div>
					</div>
					
					<div id="dinner">
					<div className="head food">
						<div className="flex"><span className="name grow">Обед</span><span className="ccal">--</span></div>
					</div>
					<div className="meals-list mgbs"></div>
					</div>
					
					<div id="supper">
					<div className="head food">
						<div className="flex"><span className="name grow">Ужин</span><span className="ccal">--</span></div>
					</div>
					<div className="meals-list mgbs"></div>
					</div>
					
					<hr/>
					
					<div className="head food total">
						<div className="flex">
							<div className="name grow">Итого</div>
							<div className="food-pfc" style={{width: '45%'}}>
								<span className="proteins"><v></v>г</span>
								<span className="fats"><v></v>г</span>
								<span className="carbons"><v></v>г</span>
							</div>
							<div className="ccal"><b><v></v></b></div>
						</div>
					</div>

				
				<div className="buttons auto">
					<a className="button primary" href="food-diary.htm"><i className="ic ic-o-add"></i> Дневник</a>
					<a className="button accent" href="food-book.htm"><i className="ic ic-o-add"></i> Справочник</a>
					<a className="button"><i className="ic ic-delete"></i> Очистить</a>
				</div>
			</div>
			
			<div className="row mgbs">
				<div className="caption"><i className="ic ic-barbell"></i><span>Тренировки</span><a className="s-menu" rel="training-menu"><i className="ic ic-dots"></i></a></div>
				
				<div id="training-exercises" className="mgbs"></div>
				
				<div className="buttons auto">
					<a className="button primary" href="food-diary.htm"><i className="ic ic-o-add"></i> Дневник</a>
					<a className="button accent" href="food-book.htm"><i className="ic ic-o-add"></i> Справочник</a>
					<a className="button"><i className="ic ic-delete"></i> Очистить</a>
				</div>
			</div>
			
			<div className="row mgbs">
				<div className="caption"><i className="ic ic-store"></i><span>Товары</span><a className="s-menu" rel="good-menu"><i className="ic ic-dots"></i></a></div>
				<div className="goods-grid-list"> include('lists/goods.htm'); </div>
				<div className="buttons auto">
					<a className="button accent" href="store-category.htm"><i className="ic ic-o-add"></i> Добавить</a>
					<a className="button"><i className="ic ic-delete"></i> Очистить</a>
				</div>
			</div>
			

			
		</div>
		
		<div className="row" style={{margin: '10px 5px'}}><button style={{width: '100%'}}>Сохранить</button></div>
	
	</div>
	<div className="side-content">
	</div>
	</>);
};
export default PostEdit;