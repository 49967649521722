import React from "react";
import Modal from "./Inc/Modal";
import App from "../App";
import API from "../Api/API";
import Message from "../Message";

export default class Auth extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			password: '',
			nick: '',
			birthday: '',
			gender: 'unset',
			wait: false
		};

		this.blockSubmit = this.blockSubmit.bind(this);
		this.login = this.login.bind(this);
		this.register = this.register.bind(this);
		this.changeEmail = this.changeEmail.bind(this);
		this.changePassword = this.changePassword.bind(this);
		this.changeNick = this.changeNick.bind(this);
		this.changeBirthday = this.changeBirthday.bind(this);
		this.changeGender = this.changeGender.bind(this);
		this.remind = this.remind.bind(this);
	}

	changeBirthday(e) {
		this.setState({
			birthday: e.target.value
		});
	}

	changeEmail(e) {
		this.setState({
			email: e.target.value
		});
	}

	changePassword(e) {
		this.setState({
			password: e.target.value
		});
	}

	changeNick(e) {
		this.setState({
			nick: e.target.value
		});
	}

	changeGender(e) {
		this.setState({
			gender: e.target.value
		});
	}

	blockSubmit(status) {
		this.setState({
			wait: status
		});
	}

	login(e) {
		this.blockSubmit(true);
		API.Login.auth(this.state.email, this.state.password,({user}) => {
			App.SetAuth(true, user);
			this.blockSubmit(false);
		},(e) => {
			console.log(e);
			App.SetAuth(false);
			this.blockSubmit(false);
		});
		e.preventDefault();
	}

	register(e) {
		this.blockSubmit(true);
		API.Login.register(this.state.email, '123456', this.state.nick, ({user}) => {
			App.SetAuth(true, user);
			this.blockSubmit(false);
		},(e) => {
			App.SetAuth(false);
			this.blockSubmit(false);
		});
		e.preventDefault();
	}

	remind(e) {

		e.preventDefault();
	}

	render() {
		return(<>
			<div className="main-content bg-dark-radial">
				<div id="auth-screen">
					<div id="logo"></div>
					<div id="auth-buttons">
						<button onClick={() => Modal.show('auth')}>Войти</button>
						<button onClick={() => Modal.show('register')}>Регистрация</button>
						<div id="auth-accounts">
							<div className="caption">Войти через соц сети</div>
							<a className="ic ic-soc-vk circle"></a>
							<a className="ic ic-soc-fb circle"></a>
							<a className="ic ic-soc-in circle"></a>
						</div>
						<button onClick={() => Modal.show('remind')}>Забыли пароль</button>
					</div>

					<div id="links" className="row float">
						<div className="col-1-2"><a href="">Пользовательское соглашение</a></div>
						<div className="col-1-2"><a href="">Политика конфинденциальности</a></div>
					</div>
				</div>
			</div>

			<Modal id="auth">
				<div className="wrapper">
					<form onSubmit={this.login}>
						<div className="row"><input name="email" ref={API.setinput} type="email" value={this.state.email} onChange={this.changeEmail} placeholder="Email"/></div>
						<div className="row"><input name="password" ref={API.setinput} type="password" value={this.state.password} onChange={this.changePassword} placeholder="Пароль"/></div>
						<div className="row">
							{
								this.state.wait ? <div>Подождите...</div> : <button type="submit">Войти</button>
							}
							
						</div>
						<div className="status">Пожалуйста авторизируйтесь.<br/>Используйте email и пароль.</div>
					</form>
				</div>
			</Modal>

			<Modal id="register">
				<div className="wrapper">
					<form onSubmit={this.register}>
						<div className="row float">
							<div className="col-1-2"><input type="date" value={this.state.birthday} onChange={this.changeBirthday} placeholder="День рождения"/></div>
							<div className="col-1-2">
								<select value={this.state.gender} onChange={this.changeGender}>
									<option disabled hidden value="unset">Пол</option>
									<option value="male">Мужчина</option>
									<option value="female">Женщина</option>
								</select>
							</div>
						</div>
						<div className="row"><input type="text" name="nick" ref={API.setinput} value={this.state.nick} onChange={this.changeNick} placeholder="Никнейм"/></div>
						<div className="row"><input type="email" name="email" ref={API.setinput} value={this.state.email} onChange={this.changeEmail} placeholder="Email"/></div>
						<div className="row"><div className="agreement">Подтверждаю что <a href="">Пользовательское соглашение</a> и <a href="">Политика конфинденциальности</a> прочитаны и приняты.</div></div>
						<div className="row"><button type="submit">Создать аккаунт</button></div>
						<div className="status">Заполните необходимые поля.<br/>На ваш email мы пришдём пароль.</div>
					</form>
				</div>
			</Modal>

			<Modal id="remind">
				<div className="wrapper">
					<form onSubmit={this.remind}>
						<div className="row"><input type="text" value={this.state.email} onChange={this.changeEmail} placeholder="Email"/></div>
						<div className="row"><button type="submit">Восстановить</button></div>
						<div className="status">Укажите email вашего аккаунта.<br/>Мы вышлем вам ссылку для сброса пароля.</div>
					</form>
				</div>
			</Modal>
		</>);
	}
};