import { useEffect, useState } from "react";
import API from "../../../Api/API";

import { Loader, getExerciseImg, getExerciseCatImg } from "./Helpers";
import Exercise from "./Exercise";

const ListFavourites = () => {
	const [favourites, setFavourites] = useState();
	useEffect(() => {
		API.get({
			path: 'training/get_exercises/0/1',
			success: ({list}) => {
				setFavourites(list);
			},
			error: () => {
	
			}
		});
	},[]);
	
	if (!favourites)
		return(<Loader show={true}/>);
	if (!favourites.length)
		return(<div>Пусто</div>);
	return(<>
	{
		favourites.map(({id, title, pic}, i) => (
			<Exercise key={i} id={id} title={title} icon={pic} favourite="true" short="true"/>
		))
	}
		
	</>);
};
export default ListFavourites;