import React from "react";

export default class FileSelector extends React.Component {
	constructor(props) {
		super(props);

		this.change = props.onChange ?? (()=>{});

		this.dialog = this.dialog.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	componentDidMount(){
		this.fileSelector = this.buildFileSelector();
	}

	buildFileSelector() {
		const fileSelector = document.createElement('input');
		fileSelector.setAttribute('type', 'file');
		fileSelector.setAttribute('accept', 'image/*');
		//fileSelector.setAttribute('multiple', 'multiple');
		return fileSelector;
	}

	onChange(e) {
		if (e.target.files) {
			const reader = new FileReader();
			for(let file of e.target.files) {
				reader.onload = (evt) => {
					var arrData = evt.target.result.split(';base64,');
					var mime = arrData[0].replace('data:', '');
					var data = arrData[1];
					this.change({
						name: file.name,
						type: file.type,
						size: file.size,
						mime: mime,
						data: data
					});
				};
				reader.readAsDataURL(file);
			}
		}
	}

	dialog(e) {
		this.fileSelector.click();
		this.fileSelector.onchange = this.onChange;
		e.preventDefault();
	}

	render() {
		return(<div onClick={this.dialog}>
		{this.props.children}
		</div>)
	}
}