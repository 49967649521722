import React,{ useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../Inc/Modal";
import SubMenu from "../../Inc/SubMenu/SubMenu";
import { Loader } from "./Helpers";
import Exercise from "./Exercise";
import Book from "../Book";
import App from "../../../App";

import API from "../../../Api/API";
import Tabs from "../../Inc/Tabs/Tabs";

const Complex = ({num, id, title, count, listComplex}) => {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [listItems, setListItems] = useState([]);
	const [complexName, setComplexName] = useState(title);

	useEffect(() => {
		Complex.editList = [];
	},[]);
	

	const show = (callback = (()=>{}), cache = true) => {
		setOpen(true);
		API.get({
			path: 'training/complex/list_items/' + id,
			success: ({list}) => {
				setListItems(list);
				callback(list);
			},
			error: (e) => {
				console.error(e);
			},
			cache: cache
		});
	};

	const toggle = () => {
		if (!open) {
			show();
		}
		setOpen(!open)
	};

	const edit = () => {
		show((list) => {
			for(let item of list) {
				Book.toggleSelect(item.id,
					<Exercise key={item.id} {...item} icon={item.pic} favourite={item.favourite_id}/>
				);
			}
			Tabs.open('choise');
			Book.setSelectMode(true,(list) => {
				//App.navigate('/training/book#complexes', () => {
					setTimeout(() => {
						Tabs.open('complexes');
						Complex.editList = list;
						Modal.show("complex-edit-" + id);
					})
					
				//}, true);
			});
		});
	};

	const editSubmut = (e) => {
		API.post({
			path: 'training/complex/update/' + id,
			data: {
				id: id,
				name: complexName,
				items: Complex.editList
			},
			success: (e) => {
				listComplex(false);
				show((()=>{}),false);
				Modal.close("complex-edit-" + id);
			},
			error: (e) => {
				console.error(e);
			},
			complete: () => {

			}
		});
		e.preventDefault();
	};

	const remove = () => {
		API.post({
			path: 'training/complex/delete',
			data: {
				id: id
			},
			success: (e) => {
				listComplex(false);
			},
			error: (e) => {
				console.error(e);
			},
			complete: () => {
				Modal.close('complex-delete-' + id);
			}
		});
	};

	return(<>
		<div className={'category exercise' + (open ? '' : ' closed')}>
			<a className={"header complex-item plate"} onClick={toggle}>
				<div className="complex-pic">{num}</div>
				<div className="complex-name">{title}</div>
				<div onClick={(e) => SubMenu.show('edit-complex-' + id,e)}><i className="ic ic-dots"></i></div>
				<div className="counter">{count}</div>
				<div className="ic toggle"></div>
			</a>
			<div className="list plates">
			{listItems.length ? listItems.map((props, i) => (<Exercise key={i} {...props} icon={props.pic} favourite={props.favourite_id}/>)) : <Loader show={true}/>}
			</div>
		</div>
		<SubMenu id={"edit-complex-" + id}>
			<a onClick={edit}><i className="ic ic-edit"></i> Редактировать</a>
			<a onClick={() => Modal.show('complex-delete-' + id)}><i className="ic ic-delete"></i> Удалить</a>
		</SubMenu>
		<Modal id={"complex-delete-" + id}>
			<div className="wrapper">
				<div className="title">Удалить комплекс "{title}"?</div>
				<div className="actions">
					<a className="button" onClick={remove}>Удалить</a>
					<a className="button accent" onClick={() => Modal.close('complex-delete-' + id)}>Отмена</a>
				</div>
			</div>
		</Modal>
		<Modal id={"complex-edit-" + id}>
			<div className="wrapper">
				<form onSubmit={editSubmut}>
					<div className="row"><input type="text" name="name" ref={API.setinput} value={complexName} onChange={e => setComplexName(e.target.value)} placeholder="Имя комлекса"/></div>
					<div className="row"><button type="submit">Сохранить</button></div>
				</form>
			</div>
		</Modal>
	</>)
};

const ListComplex = () => {
	const [name, setName] = useState('');
	const [listComplexes, setListComplexes] = useState([]);
	
	const [items, setItems] = useState([]);
	ListComplex.setItems = setItems;
	
	useEffect(() => {
		listComplex();
	},[]);

	const listComplex = (cache = true) => {
		API.get({
			path: 'training/complex/list',
			success: ({list}) => {
				setListComplexes(list);
			},
			error: (e) => {
				console.error(e);
			},
			cache: cache
		});
	};

	
	const newComplex = (e) => {
		API.post({
			path: 'training/complex/create',
			data: {
				name: name,
				items: items
			},
			success: (e) => {
				listComplex(false);
				Modal.close('new-complex');
				console.log(e);
			},
			error: (e) => {
				console.log(e);
			}
		});
		e.preventDefault();
		e.stopPropagation();
	};

	return(<>
		<div className="list">
			{!listComplexes.length ? <div className="plates"><Loader show={true}/></div> : listComplexes.map((complex, i) => (
				<Complex key={i} num={i + 1} {...complex} listComplex={listComplex}/>
			))}
			
		</div>
		<Modal id="new-complex">
			<div className="wrapper">
				<form onSubmit={newComplex}>
					<div className="row"><input type="text" name="name" ref={API.setinput} value={name} onChange={e => setName(e.target.value)} placeholder="Имя комлекса"/></div>
					<div className="row"><button type="submit">Сохранить</button></div>
				</form>
			</div>
		</Modal>
	</>);
};
export default ListComplex;