import { useState, useEffect } from "react";
import { useParams } from "react-router";

import API from "../../Api/API";
import HeadLine from "../Inc/HeadLine/HeadLine";
import SubMenu from "../Inc/SubMenu/SubMenu";
import Message from "../../Message";

import { Loader, getExerciseImg } from "./Inc/Helpers";

const Exercise = ({id}) => {
	const params = useParams();
	const [data, setData] = useState({});
	const [favouriteActive, setFavouriteActive] = useState(false);

	var id = params.id ?? id;
	HeadLine.setTitle('Упражнение', data.title);
	HeadLine.setAction({
		show: true,
		dots: (e) => SubMenu.show('exe-menu', e)
	});
	HeadLine.setProps({
		back: true
	});

	useEffect(() => {
		API.get({
			path: 'training/get_exercise/' + id,
			success: ({data}) => {
				setData(data);
				setFavouriteActive(data.favourite_id ? true : false);
			},
			error: () => {

			}
		});
	},[]);

	const favoutiteToggle = (id) => {
		API.post({
			path: 'favourites/toggle',
			data: {
				item: id,
				type: 1
			},
			success: ({status}) => {
				setFavouriteActive(status);
				Message.success(status ? 'Добавлено' : 'Убрано');
			},
			error: (e) => {
				console.error(e);
			}
		});
	};
	
	if (!data.id)
		return(<div className="main-content">
			<Loader show={true}/>
		</div>);

	return(<>
		<SubMenu id="exe-menu">
			<a onClick={() => favoutiteToggle(data.id)}><i className={"ic ic-star" + (favouriteActive ? ' ic-star-full' : '')}></i> {favouriteActive ? 'Убрать из избранного' : 'В избранное'}</a>
			<a><i className="ic ic-share"></i> Поделиться</a>
		</SubMenu>
		<div className="main-content">
			<div className="exercise-header">
				<div className="exercise-video">
					<video loop autoPlay={true} controlsList="nodownload">
						<source id="video-src" src={'https://rushfit.ru/data/exercises/videos/'+data.video+'.mp4'} type="video/mp4"/>
					</video>
				</div>
					
				<div className="exercise-item">
					<div className="exercise-pic pic-box"><img src={getExerciseImg(data.pic)}/></div>
					<div className="exercise-name">{data.title}</div>
					<div className="exercise-lvl"></div>
				</div>
			</div>

			<div className="exercise-muscles plate mgb">
				<b>Задействованные мышцы</b>
				<div className="exercise-muscles-list">
					{data.muscles.split(',').map((muscle, i) => (
						<span key={i}>{muscle}</span>
					))}
				</div>
				<b>Мышцы-ассистенты</b>
				<div className="exercise-muscles-list assist">
					{data.m_assist.split(',').map((muscle, i) => (
						<span key={i}>{muscle}</span>
					))}
				</div>
			</div>

			<div className="exercise-technic plate">
				<div className="caption"><i className="ic ic-training"></i>Техника выполнения</div>
				<div className="content"></div>
				<ol className="ol-list">
					{data.technic.split("||").map((muscle, i) => (
						<li key={i}>{muscle}</li>
					))}
				</ol>
			</div>

			<div className="exercise-tips plate">
				<div className="caption"><i className="ic ic-lamp"></i>Советы</div>
				<div className="content"></div>
				{data.tips.split("||").map((tip, i) => (
					<li key={i}>{tip}</li>
				))}
			</div>

			<div className="exercise-sport plate">
				<div className="caption"><i className="ic ic-sport"></i>Спорт</div>
				<div className="content"></div>
				{data.sport.split("||").map((sport, i) => (
					<p key={i} className="text">{sport}</p>
				))}
			</div>

		</div>
		<div className="side-content">
			<div className="tab-control vert desktop" rel="food">
				<div className="tab-item"><a rel="diary">Техника</a></div>
				<div className="tab-item"><a rel="offers">Советы</a></div>
				<div className="tab-item"><a rel="offers">Спорт</a></div>
			</div>
		</div>
	</>)
};
export default Exercise;