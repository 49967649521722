import React,{ useState, useEffect, useRef, useMemo } from "react";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

const DragItems = ({children, onChange}) => {
	const [listChildren, setListChildren] = useState([]);
	
	useEffect(() => {
		setListChildren(children);
	},[children]);

	onChange = onChange ?? (()=>{});

	const grid = 8;

	const getListStyle = isDraggingOver => ({
		background: isDraggingOver ? "lightblue" : "lightgrey",
		padding: grid
	});

	const reorder = (list, startIndex, endIndex) => {
		var sort = [];
		var result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		result = result.map((item, i) => {
			if (item.props.id)
				sort.push(item.props.id);
			return React.cloneElement(item, {key: i, index: i})
		})
		
		return {
			listChildren: result,
			listSort: sort
		};
	};

	const onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}
		
		const sortResult = reorder(
			listChildren,
			result.source.index,
			result.destination.index
		);
		setListChildren(sortResult.listChildren);
		onChange(
			sortResult.listSort,
			result.source.index,
			result.destination.index
		);
	};
	
	return(<DragDropContext onDragEnd={onDragEnd}>
		<Droppable droppableId="droppable" direction="vertical">
		{(provided, snapshot) => (
			<div
				{...provided.droppableProps}
				ref={provided.innerRef}
				style={getListStyle(snapshot.isDraggingOver)}
			>
			{listChildren}
			{provided.placeholder}
			</div>
		)}
		</Droppable>
	</DragDropContext>);
};

const Item = ({id, index, children, className}) => {

	const grid = 8;

	const getItemStyle = (isDragging, draggableStyle) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: "none",
		padding: grid * 2,
		margin: `0 0 ${grid}px 0`,
		
		// change background colour if dragging
		background: isDragging ? "lightgreen" : null,
		
		// styles we need to apply on draggables
		...draggableStyle
	});

	const changeFocus = (e) => {
		const findParents = (el, findEl) => {
			if (!el || !findEl)
				return false;
			if (el.parentNode === findEl) {
				return true;
			}else{
				return findParents(el.parentNode, findEl);
			}
		};

		if (e && findParents(e.target, e.currentTarget))
			e.currentTarget.focus();
	};
	
	return(
		<Draggable key={'item-' + id} draggableId={'item-' + id} index={Number(index)}>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					className={className}
					style={getItemStyle(
						snapshot.isDragging,
						provided.draggableProps.style
					)}
					onMouseDown={changeFocus}
				>
					{children}
				</div>
			)}
		</Draggable>
	);
};
DragItems.Item = Item;
export default DragItems;