import React from "react";

const style = {
	container: {
		position: "fixed",
		top: 0,
		left: -50,
		opacity: 0,
		padding: 15,
		zIndex: 999
	},
	block: {
		padding: 10,
		paddingLeft: 15,
		paddingRight: 15,
		borderWidth: 1,
		borderRadius: 5
	},
	error: {
		color: '#f7f7f7',
		fontWeight: 'bold',
		backgroundColor: '#FFA8A8',
		borderColor: '#f44',
	},
	success: {
		color: '#f7f7f7',
		fontWeight: 'bold',
		backgroundColor: '#73C450',
		borderColor: '#3AC400',
	}
}

export default class Message extends React.Component {
	static obj;
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			type: 1,
			text: ''
		};
		this.containerRef = React.createRef();

		this.__hide = this.__hide.bind(this);

		this.timeout = 0;
		Message.obj = this;
	}

	static error(text) {
		Message.obj.setState({
			show: true,
			type: 2,
			text: text
		},() => {
			Message.obj.animateShow();
		});
	}

	static success(text) {
		Message.obj.setState({
			show: true,
			type: 1,
			text: text
		},() => {
			Message.obj.animateShow();
			setTimeout(() => {
				Message.obj.animateHide();
			}, 2500);
		});
	}

	static hide() {

	}

	__hide() {
		this.animateHide(() => {
			this.setState({
				show: false,
				text: ''
			});
		});
	}

	animateShow() {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.containerRef.current.classList.add('opened');
		});
	}

	animateHide(callback) {
		clearTimeout(this.timeout);
		this.containerRef.current.classList.remove('opened');
		this.timeout = setTimeout(callback, 1000);
	}

	render() {
		if (this.state.show) {
			return(<>
				<div className="dynamic-modal" ref={this.containerRef} onClick={this.__hide}>
					{
						this.state.type === 1 ? 
						<div style={{...style.block, ...style.success}}>
							{this.state.text}
						</div> : null
					}

					{
						this.state.type === 2 ? 
						<div style={{...style.block, ...style.error}}>
							{this.state.text}
						</div> : null
					}
				</div>
			</>);
		}else{
			return(<></>);
		}
		
	}
}