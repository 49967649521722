const listGroups = [
	{
		id: 1,
		name: 'height',
		title: 'Рост'
	},
	{
		id: 2,
		name: 'weight',
		title: 'Вес'
	},
	{
		id: 3,
		name: 'neck',
		title: 'Шея'
	},
	{
		id: 4,
		name: 'shoulder-right',
		title: 'Плечо правое'
	},
	{
		id: 5,
		name: 'shoulder-left',
		title: 'Плечо левое'
	},
	{
		id: 6,
		name: 'biceps-right',
		title: 'Бицепс правый'
	},
	{
		id: 7,
		name: 'biceps-left',
		title: 'Бицепс левый'
	},
	{
		id: 8,
		name: 'triceps-right',
		title: 'Трицепс правый'
	},
	{
		id: 9,
		name: 'triceps-left',
		title: 'Трицепс левый'
	},
	{
		id: 10,
		name: 'arm-right',
		title: 'Предплечье правое'
	},
	{
		id: 11,
		name: 'arm-left',
		title: 'Предплечье левое'
	},
	{
		id: 12,
		name: 'chest',
		title: 'Грудь'
	},
	{
		id: 13,
		name: 'waist',
		title: 'Талия'
	},
	{
		id: 14,
		name: 'butt',
		title: 'Ягодицы'
	},
	{
		id: 15,
		name: 'hip-right',
		title: 'Бедро правое'
	},
	{
		id: 16,
		name: 'hip-left',
		title: 'Бедро левое'
	},
	{
		id: 17,
		name: 'shin-right',
		title: 'Голень правая'
	},
	{
		id: 18,
		name: 'shin-left',
		title: 'Голень левая'
	}
];

const get = (id) => {
	for(let group of listGroups) {
		if (Number(group.id) === Number(id))
			return group;
	}
};

const Groups = {
	get: get,
	list: listGroups
};

export default Groups;