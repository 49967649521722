import React,{ useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HeadLine from "../Inc/HeadLine/HeadLine";
import Tabs from "../Inc/Tabs/Tabs";
import Modal from "../Inc/Modal";
import imageMan from "../../img/man.png";
import FormMeasure from "./Inc/FormMeasure";
import MeasureItem from "./Inc/MeasureItem";
import Bmi from "./Inc/Bmi";
import Groups from "./Inc/Groups";
import API from "../../Api/API";
import DivPlaceholder from "../Inc/DivPlaceholder";

const Measurement = () => {
	const [isLoad, setIsLoad] = useState(false);
	const [listItems, setListItems] = useState([]);
	const [bmiParams, setBmiParams] = useState({
		weight: 0,
		height: 0
	});
	HeadLine.setTitle('Измерения');
	HeadLine.setAction({
		show: true,
		share: () => {},
		add: () => FormMeasure.show()
	});

	const user = API.Login.getUser();
	const userAge = API.Login.getUserAge();

	const existsPressure = () => {
		for(let obj of listItems) {
			if (obj.type === 1)
				return true;
		}
		return false;
	};

	const checkWeightHeight = (list) => {
		var ret = false;
		for(let obj of list) {
			if (obj.type === 2 && (obj.group_id >= 1 && obj.group_id <= 2)) {
				if (obj.group_id === 1)
					setBmiParams((state) => ({...state, height: obj.value}));
				if (obj.group_id === 2)
					setBmiParams((state) => ({...state, weight: obj.value}));
					
				ret = true;
			}
		}
		return ret;
	};
	
	const existsParams = () => {
		for(let obj of listItems) {
			if (obj.type === 2 && (obj.group_id > 2))
				return true;
		}
		return false;
	};

	const getList = () => {
		setIsLoad(false);
		API.get({
			path: 'measure/list',
			data: {},
			success: ({list}) => {
				setListItems(list);
				checkWeightHeight(list);
				setIsLoad(true);
			},
			fail: (e) => {
				console.error(e);
			}
		});
	};

	useEffect(() => {
		getList();
	},[]);

	return(<>
	<div className="main-content">
	
	<div className="mobile">
		<div className="tab-control" rel="training">
			<div className="tab-item"><Tabs.NavTab to="human">Мои параметры</Tabs.NavTab></div>
			<div className="tab-item"><Tabs.NavTab to="measures">Списком</Tabs.NavTab></div>
		</div>
	</div>
	
	<Tabs>
		
		<Tabs.Item id="human">
			<div id="human">
				<img src={imageMan}/>
				<div className="handlers">
					{
						Groups.list.map((group, i) => group.id > 2 ? <Link key={i} to={'/measurement/page/' + group.id}><div title={group.title} className={group.name.replace('-',' ')}></div></Link> : null)
					}
				</div>
				<div className="buttons">
					<Link className="button" rel="blood-pressure" to="/measurement/page/pressure"><i className="ic ic-tonometer"></i><span>Давление</span></Link>
					<Link className="button" rel="weight-height" to="/measurement/page/2"><i className="ic ic-weight"></i><span>Вес и рост</span></Link>
				</div>
			</div>
		</Tabs.Item>
		
		<Tabs.Item id="measures" active={true}>
			{
				isLoad ?
				
				<div id="measures">
					{
						existsPressure() ? <>
						<div className="plate caption">Давление</div>
						<div className="measure-list plates">
							{listItems.map((item, i) => item.type === 1 ? <MeasureItem key={i} isGroup={true}  {...item}/> : null)}
						</div>
						</> : null
					}
					
					{
						bmiParams.height || bmiParams.weight ? <>
						<div className="plate caption">Вес и рост</div>
						<div className="measures-list plates">
							{listItems.map((item, i) => (item.type === 2 && (item.group_id >= 1 && item.group_id <= 2)) ? <MeasureItem key={i} isGroup={true} {...item}/> : null)}
							
							<Bmi gender={user.gender} age={userAge} weight={bmiParams.weight} height={bmiParams.height}/>
						</div>
						</> : null
					}
					
					{
						existsParams() ? <>
						<div className="plate caption">Объёмы</div>
						<div className="measures-list plates">
							{listItems.map((item, i) => (item.type === 2 && (item.group_id > 2)) ? <MeasureItem key={i} isGroup={true}  {...item}/> : null)}
						</div>
						</> : null
					}
					
					
				</div>
				:
				<>
				<DivPlaceholder wait={true} className="plate caption"/>
				<DivPlaceholder wait={true} className="plate caption"/>
				<DivPlaceholder wait={true} className="plate caption"/>
				</>
			}
		</Tabs.Item>
		
	</Tabs>
	
	</div>
	<div className="side-content">
		<div className="desktop">
			<div className="tab-control vert" rel="training">
				<div className="tab-item"><Tabs.NavTab to="human">Мои параметры</Tabs.NavTab></div>
				<div className="tab-item"><Tabs.NavTab to="measures">Списком</Tabs.NavTab></div>
			</div>
		</div>
	</div>
	<FormMeasure onSave={getList}/>
	</>);
};
export default Measurement;