import React,{ useEffect, useState } from "react";
import Modal from "../../Inc/Modal";
import Calendar from "../../Inc/Calendar";
import API from "../../../Api/API";
import Groups from "./Groups";
import DivPlaceholder from "../../Inc/DivPlaceholder";
import DatePicker from "../../Inc/DatePicker/DatePicker";

const FormMeasure = ({type, group, wait, onSave}) => {
	onSave = onSave ?? (()=>{});
	group = group ?? '';
	wait = wait ?? true;

	const defaultValues = {
		id: 0,
		group_id: group,
		value: '',
		value2: '',
		value3: '',
		value4: '',
		comment: '',
		date_time: ''
	}

	const [values, setValues] = useState(defaultValues);
	const [thisType, setThisType] = useState(type);
	FormMeasure.setThisType = setThisType;
	FormMeasure.setValues = (vals) => {
		if (Object.keys(vals).length > 0)
			setValues(vals);
		else
			setValues(defaultValues);
	};
	
	const dateItem = values.date_time ? new Date(values.date_time) : new Date();
	const time = String(dateItem.getHours()).padStart(2, '0') + ':' + String(dateItem.getMinutes()).padStart(2, '0');
	const date = String(dateItem.getDate()).padStart(2, '0') + '.' + String(dateItem.getMonth() + 1).padStart(2, '0') + '.' + dateItem.getFullYear();

	const save = (e) => {
		API.post({
			path: 'measure/' + (values.id > 0 ? 'update/' + values.id : 'create'),
			data: {
				...values,
				type: thisType
			},
			success: (e) => {
				onSave(values, e);
				setThisType(type);
				Modal.close('measurement');
				console.log(e);
			},
			fail: (e) => {
				console.error(e);
			}
		});
		e.preventDefault();
	};
	
	if (thisType === 1)
		return(<>
		<Modal id="measurement">
			<DivPlaceholder wait={!wait} className="wrapper" id="blood-pressure">
				<form onSubmit={save}>
					<div className="row pressure-items">
						<input placeholder="120" name="sist" value={values.value} onChange={(e) => setValues((state) => ({...state, value:e.target.value}))}/>
						<span>/</span>
						<input placeholder="80" name="diast" value={values.value2} onChange={(e) => setValues((state) => ({...state, value2:e.target.value}))}/>
						<i className="ic ic-heart-pulse"></i>
						<input placeholder="60" name="pulse" value={values.value3} onChange={(e) => setValues((state) => ({...state, value3:e.target.value}))}/>
					</div>
					<div className="row dates">
						<div><Calendar value={values.date_time} onSubmit={(e) => console.log(e)}/></div>
						<div><DatePicker value={values.date_time} onSubmit={(e) => console.log(e)}/></div>
					</div>
					<div className="row">
						<label className="switch">
							<input type="checkbox" checked={values.value4 ? true : false} onChange={(e) => setValues((state) => ({...state, value4:!values.value4}))}/>
							<span>Аритмия</span>
							<i></i>
						</label>
						<div className="tip">Выберите, если тонометр показал аритмию</div>
					</div>
					<div className="row">
						<select className="health-level" name="group_id" value={values.group_id} onChange={(e) => setValues((state) => ({...state, group_id:e.target.value}))}>
							<option disabled value="">Самочувствие</option>
							<option value="-2">Плохое</option>
							<option value="-1">Удовлетворительное</option>
							<option value="0">Нормальное</option>
							<option value="1">Хорошее</option>
							<option value="2">Отличное</option>
						</select>
					</div>
					<div className="row"><textarea placeholder="Комментарий" value={values.comment} onChange={(e) => setValues((state) => ({...state, comment:e.target.value}))}/></div>
				</form>
				<div className="actions">
					<a className="button" onClick={save}>Сохранить</a>
					<a className="button accent" onClick={() => Modal.close('measurement')}>Отмена</a>
				</div>
			</DivPlaceholder>
		</Modal>
		</>);
	if (thisType === 2) {
		const Ico = () => {
			const group = Groups.get(values.group_id);
			return(<div className={"pic-box ic-" + (group ? group.name : 'measure')}></div>);
		};
		return(<>
			<Modal id="measurement">
				<DivPlaceholder wait={!wait} className="wrapper" id="measurement">
					<form onSubmit={save}>
						<div className="row header">
							<Ico/>
							<div className="grow">
							<select className="measure-type" name="group_id" value={values.group_id} onChange={(e) => setValues((state) => ({...state, group_id:e.target.value}))}>
								<option disabled hidden value="">Тип</option>
								{Groups.list.map((group, i) => <option key={i} value={group.id}>{group.title}</option>)}
							</select>
							</div>
						</div>
						<div className="row"><input placeholder="Значение" name="value" value={values.value} onChange={(e) => setValues((state) => ({...state, value:e.target.value}))}/></div>
						<div className="row dates">
							<div><Calendar value={values.date_time} onSubmit={(e) => console.log(e)}/></div>
							<div><DatePicker value={values.date_time} onSubmit={(e) => console.log(e)}/></div>
						</div>
						<div className="row"><textarea placeholder="Комментарий" value={values.comment} onChange={(e) => setValues((state) => ({...state, comment:e.target.value}))}/></div>
					</form>
					<div className="actions">
						<a className="button" onClick={save}>Сохранить</a>
						<a className="button accent" onClick={() => Modal.close('measurement')}>Отмена</a>
					</div>
				</DivPlaceholder>
			</Modal>
			
			</>);
	}
		

	return(<>
	<Modal id="measurement">
		<div className="wrapper">
			<div className="actions">
				<a className="button" onClick={() => setThisType(1)}>Давление</a>
				<a className="button" onClick={() => setThisType(2)}>Параметры</a>
				<a className="button accent" onClick={() => Modal.close('measurement')}>Отмена</a>
			</div>
		</div>
	</Modal>
	</>);
};
FormMeasure.show = (type = 0, values = {}) => {
	FormMeasure.setValues(values);
	FormMeasure.setThisType(type);
	Modal.show('measurement');
};

FormMeasure.close = () => {
	Modal.close('measurement');
};
export default FormMeasure;