import React, { useState } from "react";
import HeadLine from "../Inc/HeadLine/HeadLine";
import Modal from "../Inc/Modal";
import App from "../../App";
import Message from "../../Message";
import SubMenu from "../Inc/SubMenu/SubMenu";
import FileSelector from "../Inc/FileSelector";
import File from "../Inc/File";
import DivPlaceholder from "../Inc/DivPlaceholder";
import Button from "../Inc/Button/Button";

import API from "../../Api/API";

const Account = ({user, main, setAuth}) => {
	
	const [waitUpdate, setWaitUpdate] = useState(false);
	const [waitPassword, setWaitPassword] = useState(false);
	const [waitAvatar, setWaitAvatar] = useState(false);

	const [nick, setNick] = useState(user.nick);
	const [lastname, setLastname] = useState(user.lastname);
	const [firstname, setFirstname] = useState(user.firstname);
	const [middlename, setMiddlename] = useState(user.middlename);
	const [birthday, setBirthday] = useState(user.birthday);
	const [gender, setGender] = useState(user.gender);
	const [country, setCountry] = useState(user.country);
	const [city, setCity] = useState(user.city);

	const [password, setPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');

	const update = (e) => {
		setWaitUpdate(true);
		API.post({
			path: 'settings/account/update',
			data: {
				nick: nick,
				lastname: lastname,
				firstname: firstname,
				middlename: middlename,
				birthday: birthday,
				gender: gender,
				country: country,
				city: city
			},
			success: ({user}) => {
				setAuth(true, user);
				Message.success('Сохранено');
			},
			error: (e) => {
				Message.error('Ошибка при сохранении');
				console.error(e)
			},
			complete: () => {
				setWaitUpdate(false);
			}
		});
		e.preventDefault();
	};

	const changePassword = (e) => {
		setWaitPassword(true);
		API.post({
			path: 'settings/account/update_password',
			data: {
				password: password,
				newPassword: newPassword
			},
			success: (e) => {
				Message.success('Сохранено');
			},
			error: (e) => {
				Message.error('Ошибка при сохранении');
				console.error(e)
			},
			complete: () => {
				setWaitPassword(false);
			}
		});
		e.preventDefault();
	};

	const changeAvatar = (data) => {
		setWaitAvatar(true);
		API.post({
			path: 'files/update_avatar',
			data: {
				name: data.name,
				size: data.size,
				data: data.data
			},
			success: (e) => {
				Message.success('Сохранено');
				File.Avatar.update();
			},
			fail: (e) => {
				Message.error('Ошибка при сохранении');
				console.error(e)
			},
			complete: () => {
				setWaitAvatar(false);
			}
		});
	};

	HeadLine.setProps({
		title: 'Мой аккаунт',
		sub: 'Настройки',
		back: true,
		action: {
			show: true,
			mobile: true,
			check: update
		}
	});

	return(<>
		<Modal id="pass-change">
			<div className="wrapper">
				<form onSubmit={changePassword}>
					<div className="row"><input type="password" name="password" ref={API.setinput} value={password} onChange={e => setPassword(e.target.value)} placeholder="Старый пароль"/></div>
					<div className="row"><input type="password" name="newPassword" ref={API.setinput} value={newPassword} onChange={e => setNewPassword(e.target.value)} placeholder="Новый пароль"/></div>
					<div className="row">
						<Button type='accent full' title="Сменить пароль" spinner={waitPassword} disabled={waitPassword} submit/>
					</div>
					<div className="status">Укажите старый пароль.<br/>И введите новый.</div>
				</form>
			</div>
		</Modal>

		<Modal id="logout">
			<div className="wrapper">
				<div className="title">Выйти из аккаунта?</div>
				<div className="actions">
					<Button title="Да" onPress={() => App.SetAuth(false)}/>
					<Button type='accent' title="Отмена" onPress={() => Modal.closeAll()}/>
				</div>
			</div>
		</Modal>

		<Modal id="delete-avatar">
			<div className="wrapper">
				<div className="title">Удалить аватар?</div>
				<div className="actions">
					<a className="button" >Да</a>
					<a className="button accent" onClick={() => Modal.closeAll()}>Отмена</a>
				</div>
			</div>
		</Modal>

		<SubMenu id="avatar">
			<FileSelector onChange={changeAvatar}>
				<a><i className="ic ic-pencil"></i> Редактировать</a>
			</FileSelector>
			<a onClick={() => Modal.show('delete-avatar')}><i className="ic ic-delete"></i> Удалить</a>
		</SubMenu>

		<div className="main-content">
			<div className="settings-user flex plate hlines">
				<DivPlaceholder className="user-pic pic-box s-menu" onClick={e => SubMenu.show('avatar', e)} wait={waitAvatar}>
					<File.Avatar uid={user.id} size="100"/>
				</DivPlaceholder>
				<div className="user-login mgbs grow">
					<div className="label">Ваш email (логин)</div>
					<div className="user-email">{user.email}</div>
					<div className="actions">
						<Button type="accent" title="Сменить пароль" onPress={() => Modal.show('pass-change')}/>
						<Button title="Выйти" onPress={() => Modal.show('logout')}/>
					</div>
				</div>
			</div>
			
			<form onSubmit={update}>
				<div className="settings-items plate">
					<div className="setting-cap">Персональные данные</div>
					<div className="setting-field user-nick">
						<input placeholder="Никнейм" name="nick" ref={API.setinput} value={nick} onChange={e => setNick(e.target.value)}/>
						<div className="label">Ваш псевдоним (любые символы)</div>
					</div>
					<div className="setting-field user-lastname">
						<input placeholder="Ваша фамилия" name="lastname" ref={API.setinput} value={lastname} onChange={e => setLastname(e.target.value)}/>
						<div className="label">Фамилия</div>
					</div>
					<div className="setting-field user-firstname">
						<input placeholder="Ваше имя" name="firstname" ref={API.setinput} value={firstname} onChange={e => setFirstname(e.target.value)}/>
						<div className="label">Имя</div>
					</div>
					<div className="setting-field user-middlename">
						<input placeholder="Ваше отчество" name="middlename" ref={API.setinput} value={middlename} onChange={e => setMiddlename(e.target.value)}/>
						<div className="label">Отчество</div>
					</div>
					<div className="setting-field user-birthday">
						<input placeholder="дд.мм.гггг" type="date" name="birthday" ref={API.setinput} value={birthday} onChange={e => setBirthday(e.target.value)}/>
						<div className="label">День рождения</div>
					</div>
					<div className="setting-field flex grows user-gender">
						<label className="radio">
							<input type="radio" name="gender" ref={API.setinput} checked={gender === 'male' ? true : false} value="male" onChange={e => setGender(e.target.value)}/>
							<i></i><span>Мужчина</span>
						</label>
						<label className="radio">
							<input type="radio" name="gender" ref={API.setinput} checked={gender === 'female' ? true : false} value="female" onChange={e => setGender(e.target.value)}/>
							<i></i><span>Женщина</span>
						</label>
					</div>
					<div className="setting-field user-country row">
						<select value={country} onChange={e => setCountry(e.target.value)}>
							<option disabled hidden>Страна</option>
							{
								main.countrys ?
									main.countrys.map((country, i) => (
										<option key={i} value={country.id}>{country.name}</option>
									)) : null
							}
						</select>
					</div>
					<div className="setting-field user-city">
						<input placeholder="Город" name="city" ref={API.setinput} value={city} onChange={e => setCity(e.target.value)}/>
						<div className="label">Город</div>
					</div>
				</div>
				<Button type='accent last' title="Сохранить" spinner={waitUpdate} disabled={waitUpdate} submit/>
			</form>
		</div>
		<div className="side-content">

		</div>
	</>);
};

export default Account;