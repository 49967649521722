import { useRef, useEffect, useState } from "react";
import HeadLine from "../Inc/HeadLine/HeadLine";
import Modal from "../Inc/Modal";
import DivPlaceholder from "../Inc/DivPlaceholder";
import Message from "../../Message";
import API from "../../Api/API"
import Button from "../Inc/Button/Button";

const AdderessItem = ({id, city, street, zip, phone, is_default, onClick, onDelete}) => {
	onClick = onClick ?? (()=>{});
	onDelete = onDelete ?? (()=>{});

	const click = () => {
		onClick(id);
	};

	const remove = () => {
		onDelete(id);
		Modal.close("address-remove" + id);
	};

	return(<>
	<div className="address-item plate flex">
		<a className="addr-main grow" onClick={click}>
			<div className="addr-city"><i className="ic ic-locator"></i>{city}</div>
			<div className="addr-str"><i className="ic ic-locator"></i>{street}</div>
			<div className="addr-zip">{zip}</div>
			<div className="addr-tel">{phone}</div>
			{is_default ? <div className="addr-def">Основной адрес</div> : null}
		</a>
		<a className="addr-remove modal" onClick={() => Modal.show('address-remove' + id)}><i className="ic ic-delete"></i></a>
	</div>
	<Modal id={"address-remove" + id}>
		<div className="wrapper">
			<div className="title">Удалить адрес?</div>
			<div className="addr-city"><i className="ic ic-locator"></i>{city}</div>
			<div className="addr-str"><i className="ic ic-locator"></i>{street}</div>
			<div className="actions">
				<a className="button" onClick={remove}>Да</a>
				<a className="button accent" onClick={() => Modal.close('address-remove' + id)}>Отмена</a>
			</div>
		</div>
	</Modal>
	</>)
};

const Address = () => {
	const defaultData = {
		phone: '',
		country: '',
		region: '',
		city: '',
		street: '',
		street2: '',
		zip: '',
		sort: 0,
		is_default: 0
	};
	const adressApi = useRef({});
	const countrys = (API.getMain()).countrys ?? [];
	const [waitSave, setWaitSave] = useState(false);
	const [isLoadList, setIsLoadList] = useState(false);
	const [isLoad, setIsLoad] = useState(false);
	const [id, setId] = useState(0);
	const [list, setList] = useState([]);
	const [listIndex, setListIndex] = useState(0);
	const [data, setData] = useState(defaultData);
	
	HeadLine.setTitle('Мои адреса', 'Настройки');
	HeadLine.setProps({
		back: true
	});

	const flushData = () => {
		setData(defaultData);
		setId(0);
	};

	const getList = () => {
		setIsLoadList(false);
		API.get({
			path: 'settings/address/list',
			data: {},
			cache: true,
			success: ({list}) => {
				setList(list);
			},
			fail: (e) => {
				console.error(e);
			},
			complete: () => {
				setIsLoadList(true);
			}
		});
	};

	const save = (e) => {
		setWaitSave(true);
		API.post({
			path: 'settings/address/' + (id > 0 ? 'update/' + id : 'create'),
			data: {...data},
			success: (result) => {
				if (id > 0) {
					list[listIndex] = data;
					Message.success('Сохранено');
				}else{
					list.push({...data, id: result.id});
					Modal.close('address-form');
				}
				setList([...list]);
				adressApi.current.clearCache();
			},
			fail: (e) => {
				console.error(e);
			},
			complete: () => {
				setWaitSave(false);
			}
		});
		
		e.preventDefault();
	};

	const add = () => {
		setIsLoad(true);
		flushData();
		Modal.show('address-form');
	};

	const remove = (deleteId, listIndex) => {
		setIsLoadList(false);
		API.post({
			path: 'settings/address/delete/' + deleteId,
			data: {},
			success: () => {
				list.splice(listIndex, 1);
				setList([...list]);
			},
			fail: (e) => {
				console.error(e);
			},
			complete: () => {
				setIsLoadList(true);
			}
		});
	};

	const show = (showId, listIndex) => {
		setIsLoad(false);
		Modal.show('address-form');
		adressApi.current = API.get({
			path: 'settings/address/get/' + showId,
			data: {},
			cache: true,
			success: ({item}) => {
				setId(item.id);
				setListIndex(listIndex);
				setData({...item});
			},
			fail: (e) => {
				console.error(e);
			},
			complete: () => {
				setIsLoad(true);
			}
		});
	};

	useEffect(() => {
		getList();
	},[]);

	HeadLine.setAction({
		show: true,
		mobile: true,
		add: add
	});

	return(<>
	<div className="main-content">
		{isLoadList ? <>
			{list.map((address, i) => <AdderessItem key={i} {...address} onClick={(id) => show(id, i)} onDelete={(id) => remove(id, i)}/>)}
			<button className="last-button modal" onClick={add}><i className="ic ic-o-add"></i> Добавить</button>
		</> : 
			<>
			<DivPlaceholder className="address-item plate flex" wait={true}/>
			<DivPlaceholder className="address-item plate flex" wait={true}/>
			<DivPlaceholder className="address-item plate flex" wait={true}/>
			</>
		}
	</div>
	<div className="side-content">

	</div>

	<Modal id="address-form">
		<DivPlaceholder className="wrapper" wait={!isLoad}>
			<form onSubmit={save}>
				<div className="row">
					<select value={data.country} name="country" onChange={(e) => setData((state) => ({...state, country: e.target.value}))}>
						<option disabled hidden value="">Страна</option>
						{
							countrys ?
								countrys.map((country, i) => (
									<option key={i} value={country.id}>{country.name}</option>
								)) : null
						}
					</select>
				</div>
				<div className="row"><input placeholder="Регион" name="region" value={data.region} onChange={(e) => setData((state) => ({...state, region: e.target.value}))}/></div>
				<div className="row"><input placeholder="Город" name="city" value={data.city} onChange={(e) => setData((state) => ({...state, city: e.target.value}))}/></div>
				<div className="row"><input placeholder="Индекс" name="zip" value={data.zip} onChange={(e) => setData((state) => ({...state, zip: e.target.value}))}/></div>
				<div className="row"><input placeholder="Улица, дом, квартира" name="street" value={data.street} onChange={(e) => setData((state) => ({...state, street: e.target.value}))}/></div>
				<div className="row"><input placeholder="Телефон" name="phone" value={data.phone} onChange={(e) => setData((state) => ({...state, phone: e.target.value}))}/></div>
				<div className="row">
					<label className="switch">
						<input type="checkbox" checked={data.is_default ? true : false} onChange={(e) => setData((state) => ({...state, is_default: !data.is_default}))}/>
						<i></i><span>Основной адрес</span>
					</label>
				</div>
				<div className="actions">
					<Button title="Сохранить" spinner={waitSave} disabled={waitSave} onPress={save}/>
					<Button type="accent" title="Отмена" disabled={waitSave} onPress={() => Modal.close('address-form')}/>
				</div>
			</form>
		</DivPlaceholder>
	</Modal>
	</>)
};
export default Address;