import React,{ useEffect, useState } from "react";
import { useParams } from "react-router";
import HeadLine from "../Inc/HeadLine/HeadLine";
import API from "../../Api/API";
import Groups from "./Inc/Groups";
import MeasureItem from "./Inc/MeasureItem";
import FormMeasure from "./Inc/FormMeasure";
import DivPlaceholder from "../Inc/DivPlaceholder";

const MeasurePage = () => {
	const params = useParams();
	const id = params.id ?? 0;
	const type = id === 'pressure' ? 1 : 2;
	const group = Groups.get(id);
	const [isLoad, setIsLoad] = useState(true);
	const [isLoadList, setIsLoadList] = useState(false);
	const [listItems, setListItems] = useState([]);

	HeadLine.setTitle(
		type === 1 ? 'Давление' : group.title,
		'Измерения'
	);
	HeadLine.setProps({
		back: true
	});
	HeadLine.setAction({
		show: true,
		share: () => {},
		add: () => FormMeasure.show(type)
	});

	const getList = () => {
		setIsLoadList(false);
		API.get({
			path: 'measure/list/' + id,
			data: {},
			success: ({list}) => {
				setListItems(list);
				setIsLoadList(true);
			},
			fail: (e) => {
				console.error(e);
			}
		});
	};

	const show = (itemId) => {
		setIsLoad(false);
		FormMeasure.show(type);
		API.get({
			path: 'measure/item/' + itemId,
			data: {},
			success: ({item}) => {
				FormMeasure.show(type, item);
				setIsLoad(true);
			},
			fail: (e) => {
				console.error(e);
			}
		});
	};

	const remove = (itemId) => {
		setIsLoadList(false);
		var items = listItems;
		API.post({
			path: 'measure/delete/' + itemId,
			data: {},
			success: (e) => {
				for(let key in items) {
					if (items[key].id === itemId) {
						items.splice(key, 1);
						break;
					}
				}
				setListItems([...items]);
			},
			fail: (e) => {
				console.error(e);
			},
			complete: () => {
				setIsLoadList(true);
			}
		});
	};

	const onSave = (data, {id}) => {
		var items = listItems;
		if (data.id > 0) {
			for(let key in items) {
				if (items[key].id === data.id) {
					items[key] = data;
					break;
				}
			}
		}else{
			data.id = id;
			data.type = type;
			data.date_time = new Date();
			items.unshift(data);
		}
		setListItems([...items]);
	};

	useEffect(() => {

		getList();

	},[]);
	
	

	const ListItems = () => {
		var ret = [];
		var listDates = [];
		var i = 0;
		for(let item of listItems) {
			const dateItem = new Date(item.date_time);
			const date = String(dateItem.getDate()).padStart(2, '0') + '.' + String(dateItem.getMonth() + 1).padStart(2, '0') + '.' + dateItem.getFullYear();
			if (listDates.indexOf(date) < 0) {
				ret.push(<div key={i} className="list-date"><span>{date}</span></div>);
				listDates.push(date);
				++i;
			}
			ret.push(<MeasureItem key={i} {...item} onClick={show} onDelete={remove}/>);
			++i;
		}
		return(ret);
	};
	 
	return(<>
	<div className="main-content">
		<div className="measures-list plates">
			{
				isLoadList ? 
					<ListItems/>
				:
				<>
					<DivPlaceholder wait={true}/>
					<DivPlaceholder wait={true}/>
					<DivPlaceholder wait={true}/>
				</>
			}
			
			
		</div>
		<button className="last-button modal" onClick={() => FormMeasure.show(type)}><i className="ic ic-o-add"></i> Добавить</button>
	</div>
	<div className="side-content"></div>
	<FormMeasure type={type} group={type === 2 ? id : 0} wait={isLoad} onSave={onSave}/>
	</>);
};

export default MeasurePage;