import HeadLine from "../Inc/HeadLine/HeadLine";

const General = () => {
	HeadLine.setTitle('Основные', 'Настройки');
	HeadLine.setProps({
		back: true
	});
	HeadLine.setAction({
		show: true,
		check: () => {}
	});
	return(<>
	<div className="main-content">

		<div className="settings-items plate">
			<div className="setting-cap">Общие</div>
			<div className="setting-field">
				Тема оформления
			</div>
			<div className="setting-field">
				<label className="checkbox">
					<input type="checkbox"/>
					<span>Отключить рекламу</span>
					<i></i>
				</label>
			</div>
			<div className="setting-field">
				Язык
			</div>
			<div className="setting-field">
				Метрическая система
			</div>
		</div>

	</div>
	<div className="side-content">

	</div>
	</>);
};
export default General;