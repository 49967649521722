import Core from "./Core";
import Login from "./Login";

export default class API extends Core {
	static Login = Login;

	static status(succ = (() => {}), err = (() => {})) {
		API.get({
			path: 'status',
			data: {
				
			},
			success: (e) => {
				API.setCache('status', e);
				succ(e);
			},
			error: (e) => {
				err(e);
			}
		});
	}

	static main(succ = (() => {}), err = (() => {})) {
		API.get({
			path: 'main',
			data: {
				
			},
			success: (e) => {
				API.setCache('main', e);
				succ(e);
			},
			error: (e) => {
				err(e);
			}
		});
	}

	static getStatus() {
		return API.getCache('status');
	}

	static getMain() {
		return API.getCache('main');
	}
}