import { getAllByPlaceholderText } from "@testing-library/react";
import { Link, NavLink } from "react-router-dom";
import HeadLine from "../Inc/HeadLine/HeadLine";
import SubMenu from "../Inc/SubMenu/SubMenu";

const Settings = ({server}) => {
	HeadLine.setTitle('Настройки');

	return(<>
		<SubMenu id="test">
			<a rel=""><i className="ic ic-clock"></i> Назначить на период</a>
			<a rel=""><i className="ic ic-copy"></i> Дублировать</a>
		</SubMenu>
		<div className="main-content">
		
			<div className="settings-items plate hlines">
				<a className="setting-cap">Профиль</a>
				<Link className="setting-item" to="account"><i className="ic ic-user"></i> Мой аккаунт</Link>
				<Link className="setting-item" to="data"><i className="ic ic-vcard"></i> Мои данные</Link>
				<Link className="setting-item" to="address"><i className="ic ic-locator"></i> Мои адреса</Link>
				<Link className="setting-item" to="privacy"><i className="ic ic-shield"></i> Приватность</Link>
				<Link className="setting-item" to="anketa"><i className="ic ic-list"></i> Опросник от тренера</Link>
			</div>
			
			<div className="settings-items plate hlines">
				<a className="setting-cap">Приложение</a>
				<Link className="setting-item" to="general"><i className="ic ic-settings"></i> Основные</Link>
				<Link className="setting-item" to="notify"><i className="ic ic-bell-full"></i> Уведомления</Link>
				<Link className="setting-item" to="feedback"><i className="ic ic-support"></i> Обратная связь</Link>
				<Link className="setting-item" to="about"><i className="ic ic-o-info"></i> О программе</Link>
			</div>
		
		</div>
		<div className="side-content" id="right-menu">
			<div className="plate desktop">
				<div className="caption"><i className="ic ic-o-info"></i><span>Версия</span></div>
				<div><span>Client</span> <b>0.0.1</b></div>
				<div><span>Server</span> <b>{server.version}</b></div>
			</div>
		</div>
		</>);
};
export default Settings;