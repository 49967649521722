import React from 'react';
import { Routes, Route, Link } from "react-router-dom";
import Boot from './App/Boot'
import PageError from './App/PageError';
import Menu from './App/Inc/Menu/Menu';
import HeadLine from './App/Inc/HeadLine/HeadLine';
import UserPage from './App/UserPage';
import Auth from './App/Auth';
import API from './Api/API';
import File from './App/Inc/File';
import MenuBlock from './App/Inc/MenuBlock';

import Posts from './App/Posts/Posts';
import PostEdit from './App/Posts/PostEdit';

import Settings from './App/Settings/Settings';
import Account from './App/Settings/Account';
import Data from './App/Settings/Data';
import Address from './App/Settings/Address';
import Privacy from './App/Settings/Privacy';
import Anketa from './App/Settings/Anketa';
import General from './App/Settings/General';
import Notify from './App/Settings/Notify';
import Feedback from './App/Settings/Feedback';
import About from './App/Settings/About';

import Training from './App/Training/Training';
import Book from './App/Training/Book';
import Programs from './App/Training/Programs';
import Exercise from './App/Training/Exercise';
import Prog from './App/Training/Prog';

import Measurement from './App/Measurement/Measurement';
import MeasurePage from './App/Measurement/MeasurePage';

import Friends from './App/Friends';

import Message from './Message';

class App extends React.Component {
	static obj;
	constructor(props) {
		super(props);

		this.state = {
			run: false,
			auth: false,
			user: {},
			main: {},
			server: {}
		};

		this.navigate = props.navigate;
		this.runApp = this.runApp.bind(this);
		this.setAuth = this.setAuth.bind(this);

		App.obj = this;
	}

	componentDidMount() {
		const auth = () => {
			Boot.setMessage('Авторизация...');
			var token = localStorage.getItem('token');
			if (token) {
				API.Login.check(token,({user}) => {
					Boot.setMessage('Готово');
					App.SetAuth(true, user);
					this.runApp();
				},(e) => {
					App.SetAuth(false);
					this.runApp();
				});
			}else{
				this.setAuth(false);
				this.runApp();
			}
		};

		const main = () => {
			Boot.setMessage('Получаем начальные данные...');
			API.main((e) => {
				Boot.setMessage('Готово');
				File.setRoutes(e.routes);
				this.setMain(e);
				auth();
			},(e) => {
				Boot.setMessage('Ошибка подключения (Попробуйте обновить страницу)');
			});
		};

		const check = () => {
			Boot.setMessage('Подключаемся к серверу...');
			API.status((e) => {
				Boot.setMessage('Готово');
				this.setServerStatus(e);
				main();
			},(e) => {
				Boot.setMessage('Ошибка подключения (Попробуйте обновить страницу)');
			});
		};
		
		check();
	}

	static getUser() {
		return App.obj.state.user;
	}

	static getMain() {
		return App.obj.state.main;
	}

	static back(callback = (()=>{})) {
		App.navigate(-2, callback);
	}

	static navigate(to, callback = (()=>{}), isWaitRequest = false) {
		if (isWaitRequest) {
			App.obj.navigate(to,() => {
				const listener = API.requestComplete(() => {
					setTimeout(() => {
						callback();
						listener.remove();
					},100);
				});
			});
		}else{
			App.obj.navigate(to, callback);
		}
	}

	runApp() {
		this.setState({
			run: true
		});
	}

	setMain(data) {
		delete data.success;
		this.setState({
			main: data
		});
	}

	setServerStatus(data) {
		delete data.success;
		this.setState({
			server: data
		});
	}

	static SetAuth(status, user = {}) {
		API.setToken(user.token);
		App.obj.setAuth(status, user);
	}

	setAuth(status, user = {}) {
		if (status) {
			this.setState({
				auth: status,
				user: user
			});
			localStorage.setItem('token', user.token);
		}else{
			this.setState({
				auth: status,
				user: {}
			});
			localStorage.removeItem('token');
		}
	}

	render() {
		if (!this.state.run) {
			return(<Boot/>);
		}
		if (this.state.auth) {
			return (
				<div>
					<HeadLine {...this.props} user={this.state.user}/>
					<div id="main-container">
						<Menu {...this.props} user={this.state.user}/>
						<div style={{display: 'flex', flexDirection: 'row'}}>
							<Routes>
								<Route path="/">
									<Route path="" element={<Posts />} />
									<Route path="posts">
										<Route path="" element={<Posts />} />
										<Route path="edit/:id" element={<PostEdit />} />
										<Route path="edit" element={<PostEdit />} />
									</Route>
									<Route path="user" element={<UserPage user={this.state.user}/>} />
									<Route path="/id:id" element={<UserPage user={this.state.user}/>} />
									<Route path="settings">
										<Route path="" element={<Settings server={this.state.server}/>} />
										<Route path="account" element={<Account user={this.state.user} main={this.state.main} setAuth={this.setAuth}/>} />
										<Route path="data" element={<Data user={this.state.user}/>} />
										<Route path="address" element={<Address/>} />
										<Route path="privacy" element={<Privacy/>} />
										<Route path="anketa" element={<Anketa/>} />
										<Route path="general" element={<General/>} />
										<Route path="notify" element={<Notify/>} />
										<Route path="feedback" element={<Feedback/>} />
										<Route path="about" element={<About/>} />
									</Route>
									<Route path="training">
										<Route path="" element={<Training {...this.props}/>}/>
										<Route path="book" element={<Book {...this.props}/>}/>
										<Route path="programs" element={<Programs {...this.props} user={this.state.user}/>}/>
										<Route path="exercise/:id" element={<Exercise {...this.props}/>}/>
										<Route path="prog/:id" element={<Prog {...this.props} user={this.state.user}/>}/>
									</Route>
									<Route path="measurement">
										<Route path="" element={<Measurement {...this.props}/>}/>
										<Route path="page/:id" element={<MeasurePage {...this.props}/>}/>
									</Route>
									<Route path="friends">
										<Route path="" element={<Friends {...this.props}/>}/>
									</Route>
								</Route>
								<Route path='*' element={<PageError text="404"/>}/>
							</Routes>
						</div>
						<Book.Wrapper/>
						<MenuBlock locationKey={this.props.location.key}/>
					</div>
				</div>
			);
		}else
			return(<Auth/>);
	}
}

export default App;
