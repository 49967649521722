import React,{useState, useEffect, useRef} from "react";
import { useNavigate } from "react-router";
import API from "../../../Api/API";
import SubMenu from "../../Inc/SubMenu/SubMenu";
import { Loader, getExerciseImg, getExerciseCatImg } from "./Helpers";
import Book,{ ExerciseSelectMode } from "../Book";
import Exercise from "./Exercise";

var exerciseCatCache = {};
const ExerciseCat = ({id, title, count, icon, selectMode, onSelect}) => {
	const [open, __setOpen] = useState(exerciseCatCache[id] ?? false);
	const [listExercise, setListExercise] = useState([]);
	
	const setOpen = (status) => {
		__setOpen(status);
		exerciseCatCache[id] = status;
	};
	
	if (open && listExercise.length === 0) {
		API.get({
			path: 'training/get_exercises/' + id,
			success: (result) => {
				setListExercise(result.list);
			},
			error: () => {
	
			},
			cache: true
		});
	}

	return(<>
		<div className={"category exercise" + (open ? '' : ' closed')}>
			<div className="header plate" onClick={() => setOpen(!open)}>
				<div className="pic-box"><img src={getExerciseCatImg(icon)}/></div>
				<div className="name">{title}</div>
				<div className="counter">{count}</div>
				<div className="ic toggle"></div>
			</div>
			<div className="list plates">
				<Loader show={listExercise.length ? false : true}/>
				{listExercise.map((data, i) => <Exercise key={i} id={data.id} icon={data.pic} title={data.title} favourite={data.favourite_id} selectMode={selectMode} onSelect={onSelect}/>)}
			</div>
		</div>
	</>);
};

const ListExercises = ({selectMode, onSelect}) => {
	const [listCat, setListCat] = useState([]);
	useEffect(() => {
		API.get({
			path: 'training/get_exercises_categories',
			success: (result) => {
				setListCat(result.list);
			},
			error: () => {
	
			},
			cache: true
		});
	},[]);
	
	if (!listCat.length)
		return(<div className="plates"><Loader show={true}/></div>);
	return(<>
		{listCat.map((data, i) => <ExerciseCat key={i} id={data.id} icon={data.name} title={data.title} count={data.count} selectMode={selectMode} onSelect={onSelect}/>)}
	</>);
};

export default ListExercises;