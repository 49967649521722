import React,{ useEffect, useState } from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import Tabs from "../Inc/Tabs/Tabs";
import App from "../../App";
import HeadLine from "../Inc/HeadLine/HeadLine";
import MenuBlock from "../Inc/MenuBlock";
import Calendar from "../Inc/Calendar";
import ProgItems from "./Prog/ProgItems";
import API from "../../Api/API";
import Message from "../../Message";
import ProgRun from "./Prog/ProgRun";
import ProgSelect from "./Prog/ProgSelect";
import DiaryItems from "./Diary/DiaryItems";

export const Offers = () => {

	return(<>
		<div className="plate grad-<? echo $s_cat['grd']; ?>">
			<div className="caption"><i className="ic <? echo $s_cat['icon']; ?>"></i><span> echo $s_cat['name']; </span><a className="small link" href="store-group.htm?id=<? echo $n; ?>">Cмотреть всё</a></div>
			<div className="snap-slider goods-list wheel ">
			

			<div className="good-item" data-group="<? echo $n; ?>" data-id="<? echo $item['icon']; ?>">
				<div className="good-pic"><img src="/assets/<? echo $n . '/' . $item['icon']; ?>.jpg"/></div>
				<div className="good-name"> echo $item['name'];; </div>
				<div className="good-desc"> // echo $good['desc']; </div>
			</div>
			
			</div>
		</div>
	</>);
};
var progSelectDayDate = new Date();
var progSelectDayId = 0;
const Training = React.memo(() => {
	const [selectDayDate, __setSelectDayDate] = useState(progSelectDayDate);
	const [selectDayId, __setSelectDayId] = useState(progSelectDayId);
	const [isChangeItems, setIsChangeItems] = useState(true);
	
	const setSelectDayDate = (date) => {
		__setSelectDayDate(date);
		progSelectDayDate = date;
	};

	const setSelectDayId = (dayId) => {
		__setSelectDayId(dayId);
		progSelectDayId = dayId;
	};

	const loadItems = (items) => {
		console.log(items)
	};

	const changeItems = () => {
		setIsChangeItems(true);
		setTimeout(() => {
			Training.save();
		});
	};

	const changeProg = () => {
		DiaryItems.getList(true);
	};

	const createDay = (callback = (()=>{})) => {
		API.post({
			path: 'training/program/days/create',
			data: {
				date: selectDayDate.toLocaleString({timeZone: "UTC"})
			},
			success: ({id}) => {
				setSelectDayId(id);
				callback(id);
				Message.success('Сохранено');
			},
			fail: (e) => {
				console.error(e);
				Message.error('Ошибка');
			}
		});
	};
	
	Training.save = () => {
		if (selectDayId > 0) {
			ProgItems.save();
			Message.success('Сохранено');
		}else{
			createDay((id) => {
				ProgItems.save(id);
			});
		}
	};

	HeadLine.setTitle('Тренировки');
	HeadLine.setAction({
		show: true,
		mobile: true,
		task: () => App.navigate('training/programs'),
		book: () => App.navigate('training/book'),
		share: () => {}
	});
	
	return(<>
	<div className="main-content">
	
		<div className="mobile">
			<div className="tab-control" rel="training">
				<div className="tab-item"><Tabs.NavTab to="diary">Дневник</Tabs.NavTab></div>
				<div className="tab-item"><Tabs.NavTab to="offers">Предложения</Tabs.NavTab></div>
			</div>
		</div>

		<Tabs>
			<Tabs.Item id='diary' active={true}>
				<>
				<Calendar mode="week" value={selectDayDate} onChange={setSelectDayDate}/>
				
				<div className="training-day plate">
				
					<div className="training-day-head flex">
						<div className="grow">Тренировка за день</div>
						<Calendar value={selectDayDate} onSubmit={setSelectDayDate}/>
					</div>
					
					<div className="training-day-stats">
						<div className="training-start">
							<div className="icon"><i className="ic ic-flag-start s24"></i></div>
							<div className="text"><span>Начало</span><b>--:--</b></div>
						</div>
						<div className="training-end">
							<div className="icon"><i className="ic ic-flag-finish s24"></i></div>
							<div className="text"><span>Конец</span><b>--:--</b></div>
						</div>
						<div className="training-time">
							<div className="icon"><i className="ic ic-timer s24"></i></div>
							<div className="text"><span>Всего</span><b>--:--</b></div>
						</div>
						<div className="training-run">
							<a className="button primary small modal" onClick={() => ProgRun.show()}>Старт</a>
						</div>
					</div>
					
					<ProgSelect selectDayDate={selectDayDate} onChange={changeProg}/>
					
					<div className="training-day-actions flex">
						<div className="grow"><a className="add-exercise" onClick={() => DiaryItems.add()}><i className="ic ic-o-add"></i> Добавить упражнение</a></div>
						<div className=""><a className="link modal" rel="reminder">Напомнить <i className="ic ic-bell"></i></a></div>
					</div>
				
				</div>
				
				<div className="list plates" id="training-exercises">
				
				<DiaryItems selectDayDate={selectDayDate}/>

				{/* <ProgItems id={0} selectDay={selectDayId} onChange={changeItems} onLoad={loadItems}/> */}
				
				</div>
				</>
			</Tabs.Item>

			<Tabs.Item id="offers">
				<>
				<div className="plate grad-<? echo $s_cat['grd']; ?>">
					<div className="caption"><i className="ic <? echo $s_cat['icon']; ?>"></i><span> echo $s_cat['name']; </span><a className="small link" href="store-group.htm?id=<? echo $n; ?>">Cмотреть всё</a></div>
					<div className="snap-slider goods-list wheel ">
					

					<div className="good-item" data-group="<? echo $n; ?>" data-id="<? echo $item['icon']; ?>">
						<div className="good-pic"><img src="/assets/<? echo $n . '/' . $item['icon']; ?>.jpg"/></div>
						<div className="good-name"> echo $item['name'];; </div>
						<div className="good-desc"> // echo $good['desc']; </div>
					</div>
					
					</div>
				</div>
				</>
			</Tabs.Item>

		</Tabs>
	
	</div>

	<div className="side-content">
		<div className="desktop">
			<div className="tab-control vert" rel="training">
				<div className="tab-item"><Tabs.NavTab to="diary">Дневник</Tabs.NavTab></div>
				<div className="tab-item"><Tabs.NavTab to="offers">Предложения</Tabs.NavTab></div>
			</div>
			<NavLink className="button big primary" to="programs"><i className="ic ic-task"></i> Программы</NavLink>
			<NavLink className="button big primary" to="book"><i className="ic ic-book"></i> Справочник</NavLink>
		</div>
	</div>
	<ProgRun/>
	</>);
});
export default Training;