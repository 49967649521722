import React,{ useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import API from "../../../Api/API";
import { Loader } from "./Helpers";
import File from "../../Inc/File";
import {
	numOfDays,
	dateDay
} from "../../Inc/Helpers";

var oldSelect = (()=>{});
const Program = ({user, id, uid, days, title, text, user_nick, selectMode, selected, onSelect, created_at, updated_at}) => {
	const [select, setSelect] = useState(selected ?? false);

	useEffect(() => {
		if (selected) {
			oldSelect = setSelect;
		}
	},[selected]);

	const click = (e) => {
		if (selectMode) {
			e.preventDefault();
			if (typeof onSelect === 'function') {
				setSelect(!select);
				
				if (select) {
					onSelect(0, {
						title: ''
					});
					
				}else{
					onSelect(id, {
						title: title
					});
				}
				if (oldSelect !== setSelect) {
					oldSelect(false);
					oldSelect = setSelect;
				}
			}
			return false;
		}
	};

	return(<>
	<NavLink onClick={click} className={'prog-item plate personal' + (select ? ' selected' : '')} to={"/training/prog/" + id}>
		<div className="prog-pic pic-box ic-task"><img src=""/></div>
		<div className="prog-info">
			<div className="prog-name">
				{title}
				{uid === user.id ? <i className="ic ic-user"></i> : null}
			</div>
			<div className="prog-desc">{text}</div>
			
			<div className="prog-extra" style={{margin: '0 0 5px'}}>
			<div className="user-pic pic-box"><File.Avatar uid={uid} size="20"/></div>
			<div className="user-name">{user_nick}</div>
			<span></span>
			</div>
			
			<div className="prog-extra">
				
				<div className="prog-days"><i className="ic ic-calendar"></i><span>{numOfDays(days)}</span></div>
				<div className="prog-load"><i className="ic ic-download"></i><span>0</span></div>
				
				
				<div className="prog-days"><i className="ic ic-calendar"></i><span>{numOfDays(days)}</span></div>
				<div className="prog-edit"><i className="ic ic-pen"></i><span>{dateDay(updated_at)}</span></div>
				<div className="prog-create">{dateDay(created_at)}</div>
				
			</div>
		</div>
	</NavLink>
	</>)
};

const ListPrograms = ({selectMode, selectPid, onSelect}) => {
	const user = API.Login.getUser();
	const [listData, setListData] = useState();

	const selectProgram = (...args) => {
		if (typeof onSelect === 'function') {
			onSelect(...args);
		}
	};

	useEffect(() => {
		API.get({
			path: 'training/program/list',
			success: ({list}) => {
				setListData(list);
			},
			error: (e) => {
				console.error(e);
			},
			complete: () => {
				
			}
		});
	},[]);

	if (!listData)
		return(<div className="plates"><Loader show={true}/></div>);

	if (!listData.length)
		return(<div className="plates">Пусто</div>);

	return(<>{listData.map((el, i) => <Program key={i} user={user} selectMode={selectMode} selected={selectPid === el.id} onSelect={selectProgram} {...el}/>)}</>);
};
export default ListPrograms;