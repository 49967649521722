const Page404 = () => {
	return(<>
	<div className="main-content">
		404
	</div>
	<div className="side-content">

	</div>
	</>);
};

export default Page404;