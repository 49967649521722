import React,{ useState } from "react";
import * as ReactDOM from 'react-dom';

export default class Modal extends React.Component {
	static modals = {};
	static wrapperRef = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			show: false
		};
		this.modalRef = React.createRef();

		this.__show = this.__show.bind(this);
		this.__close = this.__close.bind(this);
		this.__closeBackground = this.__closeBackground.bind(this);

		if (props.id)
			Modal.modals[props.id] = this;
	}
	
	static show(id) {
		if (Modal.modals[id])
			Modal.modals[id].__show();
	}

	static close(id) {
		if (Modal.modals[id])
			Modal.modals[id].__close();
	}

	static closeAll() {
		for(var modal in Modal.modals) {
			Modal.modals[modal].__close();
		}
	}

	static Wrapper() {
		return(<div ref={Modal.wrapperRef}></div>);
	}

	__show() {
		this.setState({
			show: true
		},() => {
			if (this.modalRef.current) {
				this.modalRef.current.classList.add('opened');
			}
		});
	}

	__close() {
		if (this.modalRef.current)
			this.modalRef.current.classList.remove('opened');	
		this.setState({
			show: false
		});
	}

	__closeBackground(e) {
		if (e.target !== e.currentTarget)
			return;
		this.__close();
    }

	__render() {
		return(<>
			{
				this.state.show ? 
					<>
					<div className="modal-background" onClick={this.__closeBackground}>
						<div className="modal-window anim-fadein" ref={this.modalRef}>
							{this.props.children}
						</div>
					</div>
					</>
				: null
			}
		</>);
	}

	render() {
		if (!Modal.wrapperRef.current)
			return(<></>);
		return ReactDOM.createPortal(
			this.__render(),
			Modal.wrapperRef.current
		  );
	}
}