import React from "react";

export default class Boot extends React.Component {
	static obj;
	constructor(props) {
		super(props);
		this.state = {
			message: ''
		};
		Boot.obj = this;
	}

	static setMessage(text) {
		Boot.obj.setState({
			message: text
		});
	}

	render() {
		return(
			<div className="main-content bg-dark-radial">
				<div id="init-screen">
					<div id="logo" className="anim-pulse"></div>
					<div id="logo-text">
						<span id="big">Rush</span><span>Fitness</span>
					</div>
					<div id="status">{this.state.message}</div>
					<div id="version">
						<div id="client"><span>Client</span> <b>0.0.1</b></div>
						<div id="core"><span>Core</span> <b>0.1</b></div>
					</div>
				</div>
			</div>
		);
	}
}