import { NavLink } from "react-router-dom";
import HeadLine from "../Inc/HeadLine/HeadLine";
import Tabs from "../Inc/Tabs/Tabs";
import ListPrograms from "./Inc/ListPrograms";

const Programs = ({navigate, user}) => {
	HeadLine.setTitle('Программы тренировок');
	HeadLine.setProps({
		back: true
	});
	HeadLine.setAction({
		show: true,
		mobile: true,
		search: () => {},
		add: () => navigate('/training/prog/0')
	});
	
	return(<>
	<div className="main-content">
	
		<div className="mobile">
			<div className="tab-control" rel="programs">
				<div className="tab-item"><Tabs.NavTab to="prog-my">Мои</Tabs.NavTab></div>
				<div className="tab-item"><Tabs.NavTab to="prog-bought">Загружено</Tabs.NavTab></div>
				<div className="tab-item"><Tabs.NavTab to="prog-store">Магазин</Tabs.NavTab></div>
			</div>
		</div>
		
		<Tabs>
			
			<Tabs.Item id="prog-my" active={true}>
				<div className="prog-list">
					<ListPrograms user={user}/>
				</div>
			</Tabs.Item>
			
			<Tabs.Item id="prog-bought">
				<div className="prog-list bought"> $p_type=2; include('lists/programs.htm'); </div>
			</Tabs.Item>
			
			<Tabs.Item id="prog-store">
				<div className="good-prog-list plates"> $p_type=3; include('lists/goods-prog.htm'); </div>
			</Tabs.Item>
			
		</Tabs>
	
	</div>

	<div className="side-content">
		<div className="desktop">
			<div className="tab-control vert desktop" rel="programs">
				<div className="tab-item"><Tabs.NavTab to="prog-my">Мои</Tabs.NavTab></div>
				<div className="tab-item"><Tabs.NavTab to="prog-bought">Загружено</Tabs.NavTab></div>
				<div className="tab-item"><Tabs.NavTab to="prog-store">Магазин</Tabs.NavTab></div>
			</div>
			<a className="button big primary"><i className="ic ic-search"></i> Поиск</a>
			<NavLink className="button big accent" to="/training/prog/0"><i className="ic ic-o-add"></i> Добавить</NavLink>
		</div>
	</div>
	</>);
};
export default Programs;