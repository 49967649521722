import ReactDOM from 'react-dom';
import md5 from 'md5';
import Message from '../Message';
import EventEmitter from '../EventEmitter';
const URL = 'https://testlaravel.inmsk.net/api/';
//const URL = 'http://127.0.0.1:8000/api/';

export default class Core {
	static inputObjects = {};
	static token = '';
	static cache = {};
	static completeTimeout = null;

	static get(params) {
		return this.request('GET', params);
	}

	static post(params) {
		return this.request('POST', params);
	}

	static request(method, params) {
		const success = (...args) => {
			if (typeof params.success === 'function')
				params.success(...args);
		};
		const fail = params.fail ?? (() => {});
		const error = params.error ?? (() => {});
		const complete = (...args) => {
			if (typeof params.complete === 'function')
				params.complete(...args);
				
			//clearTimeout(Core.completeTimeout);
			//Core.completeTimeout = setTimeout(() => {
				//console.log(new Date().getTime())
				EventEmitter.dispatch('api-request-complete', params);
			//}, 500);
		};

		const encodeUrlParams = (data) => {
			return Object.keys(data).map(function(k) {
				return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
			}).join('&');
		};

		var path = params.path;
		var fetchParams = {
			method: method
		};

		fetchParams.headers = {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Token': Core.token
		};

		if (method === 'POST') {
			fetchParams.body = JSON.stringify(params.data)
		}

		if (method === 'GET') {
			if (params.data)
				path = params.path + '?' + encodeUrlParams(params.data);
		}
		const pathCache = md5(path + JSON.stringify(params.data))
		var dataCache;
		if (params.cache) {
			if (dataCache = this.getCache(pathCache)) {
				success(dataCache);
				complete(dataCache);
			}	
		}else{
			this.deleteCache(pathCache);
		}
		if (!dataCache) {
			fetch(URL + path, fetchParams)
			.then(res => res.json())
			.then(
				(result) => {
					if (result.success) {
						if (params.cache)
							this.setCache(pathCache, result, (typeof params.cache === 'boolean' ? undefined : params.cache))
						success(result);
					}else {
						fail(result);
					}
					complete(result);
					this.DOMErrors(result);
				},
				(result) => {
					error(result);
					complete(result);
					Message.error('Ошибка при выполнении запроса :-(');
				}
			);
		}
		return {
			clearCache: () => {
				this.deleteCache(pathCache);
			}
		}
	}

	static requestComplete(callback) {
		return EventEmitter.subscribe('api-request-complete', callback);
	}

	static getCache(request) {
		const key = md5(request);
		if (this.cache[key]) {
			if ((new Date()).getTime() <= (this.cache[key].time + 3600000000)) {
				return this.cache[key].data;
			}else{
				delete this.cache[key];
			}
		}
	}

	static setCache(request, data, group) {
		this.cache[md5(request)] = {
			time: (new Date()).getTime(),
			data: data,
			group: group
		};
	}

	static deleteCache(request) {
		const keyCache = md5(request);
		if (!this.cache[keyCache])
			return;

		const group = this.cache[keyCache].group;
		if (group) {
			for(const key in this.cache) {
				if (this.cache[key].group === group) {
					delete this.cache[key];
				}
			}
		}
		delete this.cache[keyCache];
	}

	static setToken(token) {
		Core.token = token;
	}

	static setinput(e) {
		if (e)
			Core.inputObjects[e.name] = e;
	}

	static DOMErrors(errors) {
		const deleteByClass = (classname) => {
			const elements = document.getElementsByClassName(classname);
			while(elements.length > 0){
				elements[0].parentNode.removeChild(elements[0]);
			}
		};

		const deleteClass = (classname) => {
			const elements = document.getElementsByClassName(classname);
			while(elements.length > 0){
				elements[0].classList.remove(classname);
			}
		};
		deleteByClass('dynamic-errors');
		deleteClass('field-error');
		
		for(let field in errors.errors) {
			for(let error of errors.errors[field]) {
				document.querySelectorAll('[name="'+field+'"]').forEach((el) => {
					const err = document.createElement("span");
					err.classList.add('dynamic-errors');
					err.innerText = error;
					el.parentNode.prepend(err);
					el.classList.add('field-error');
				});
			}
		}
	}
}