import React,{ useEffect, useState } from "react";
import Spoiler from "../Inc/Spoiler";
import DivPlaceholder from "../Inc/DivPlaceholder";
import API from "../../Api/API";

const PageAbout = ({id}) => {
	const [isLoad, setIsLoad] = useState(false);
	const [data, setData] = useState({
		city: '',
		about_text: '',
		sport_dirs: ''
	});
	
	const getAbout = () => {
		API.get({
			path: 'user/about/' + id,
			data: {},
			success: ({list}) => {
				setIsLoad(true);
				setData(list);
			},
			fail: (e) => {
				console.error(e);
			},
			complete: () => {
				
			}
		});
	};

	return(<Spoiler onOpen={getAbout} head={<><i className="ic ic-vcard"></i><span>Подробнее</span></>}>
		<DivPlaceholder wait={!isLoad}>
		<div className="caption"><i className="ic ic-sport"></i> Спортивные направления</div>
		<div className="txt user-sport">{data.sport_dirs}</div>
		
		<div className="caption"><i className="ic ic-locator"></i> Город</div>
		<div className="txt user-city">{data.city}</div>
		<div className="caption"><i className="ic ic-user"></i> О себе</div>
		<div className="txt user-desc">{data.about_text}</div>
		</DivPlaceholder>
	</Spoiler>);
};

export default PageAbout;