import React,{ useRef, useEffect, useState } from "react";
import Modal from "../../Inc/Modal";
import { getExerciseImg } from "../Inc/Helpers";
import TextChange from "../Inc/TextChange";
import Prog from "../Prog";

var ProgDataSets = [];

export const encType = (name) => {
	var ret = {};
	switch(name) {
		case 'power': 
		ret = {
			type: 1,
			list: [
				{
					title: 'Вес',
					default: 0,
					definition: 'кг'
				},
				{
					title: 'Повторения',
					default: 0,
					definition: 'x'
				}
			]
		}
		break;
		case 'functional_repeats':
			ret = {
				type: 2,
				list: [
					{
						title: 'Повторения',
						default: 0,
						definition: 'x'
					}
				]
			}
		break;
		case 'functional_seconds':
			ret = {
				type: 3,
				list: [
					{
						title: 'Время',
						default: 0,
						definition: 'м'
					}
				]
			}
		break;
		case 'cardio':
			ret = {
				type: 4,
				list: [
					{
						title: 'Время',
						default: 0,
						definition: 'м'
					}
				]
			}
		break;
		default:
			ret = {
				type: 1,
				list: [
					{
						title: 'Вес',
						default: 0,
						definition: 'кг'
					},
					{
						title: 'Повторения',
						default: 0,
						definition: 'x'
					}
				]
			}
		break;
	}
	return ret;
};

const ProgItemSets = ({id, title, icon, type, readonly, dataItemSets, onChange, onBlur}) => {
	const encTypeObj = encType(type);
	const containerRef = useRef();
	const [sets, setSets] = useState(dataItemSets);

	useEffect(() => {
		ProgDataSets = dataItemSets;
		setSets(dataItemSets);
	},[dataItemSets]);

	const handleBlur = () => {
		if (typeof onBlur === 'function') {
			onBlur();
		}
	};

	const setFocus = () => {
		if (!containerRef.current) {
			return;
		}
		containerRef.current.focus();
	};

	const handleChange = (...args) => {
		if (typeof onChange === 'function') {
			onChange(...args);
		}
		setFocus();
	};
	
	const addSet = () => {
		ProgDataSets.push({
			id: 0,
			iid: id,
			type: encTypeObj.type,
			list_values: encTypeObj.list.map((item) => item.default).join(',')
		});
		setSets([...ProgDataSets]);
		handleChange([...ProgDataSets]);
	};
	
	const editSet = (index, params) => {
		
		const indexType = params.type;
		const value = params.value;
		var arrValues = ProgDataSets[index].list_values.split(',');
		arrValues[indexType] = value;
		ProgDataSets[index].list_values = arrValues.join(',');
		
		setSets([...ProgDataSets]);
		handleChange([...ProgDataSets]);
	};

	const existsIID = () => {
		for(let set of ProgDataSets) {
			if (set.iid === id)
				return true;
		}
		return false;
	};

	const deleteSet = (index) => {
		ProgDataSets.splice(index, 1);
		if (!existsIID())
			ProgDataSets.push({
				id: -1,
				iid: id
			});
		setSets([...ProgDataSets]);
		handleChange([...ProgDataSets]);
	};
	
	const ItemSet = ({index, num, values}) => {
		const arrValues = values.split(',');
		
		return(<div className="set-item">
			<div className="set-num"> {num} </div>
			<div className="set-params">
				<div className="set-param">
					{
						encTypeObj.list.map((item, i) => (
							<React.Fragment key={i}>
								<span>{item.title}</span>
								{
									readonly ? 
									<b> <div style={{display: 'inline', width: 80, textAlign: 'right'}}>{arrValues[i]}</div> {item.definition}</b>
									:
									<b> <TextChange style={{display: 'inline', width: 80, textAlign: 'right'}} placeholder="..." autoSelect={true} value={arrValues[i]} onChange={(val) => editSet(index, {type: i, value: val})}/> {item.definition}</b>
								}
								
							</React.Fragment>
						))
					}
					{readonly ? null : <a onClick={() => deleteSet(index)}><i className="ic ic-delete"></i></a>}
				</div>

			</div>
		</div>);
	};

	var num = 0;

	return(<>
		<div className="sets-list" tabIndex="2" onBlur={handleBlur} ref={containerRef}>
			{sets.map((set, i) => {
				if (set.iid === id && set.id >= 0) {
					++num;
					return(<ItemSet key={i} index={i} num={num} values={set.list_values}/>);
				}
			})}
		</div>
		{readonly ? null : <div className="new-set"><a className="link" onClick={addSet}><i className="ic ic-o-add"></i> Новый подход</a></div>}
		

		<Modal id={"exercise-set" + id}>
			<div className="wrapper set-form mgbs hlines">
				<div className="exercise-item">
					<div className="exercise-pic pic-box"><img src={getExerciseImg(icon)}/></div>
					<div className="exercise-name">{title}</div>
					<div className="exercise-action"><i className="ic ic-o-info"></i></div>
				</div>
				<div className="params">
					<div className="set-num">1</div>
					<input placeholder="Вес кг" type="number"/>
					<input placeholder="Кол-во раз" type="number"/>
				</div>
				
				<div className="buttons">
					<a className="button cancel" onClick={() => Modal.close('exercise-set' + id)}>Отмена</a>
					<a className="button delete alert">Удалить</a>
					<a className="button save accent">ОК</a>
				</div>
			</div>
		</Modal>
	</>)
};

export default React.memo(ProgItemSets);