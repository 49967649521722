import React, { useRef, useEffect, useState } from "react";
import styles from './style.css';

const Button = ({onPress, title, href, className, active, disabled, spinner, style, type, submit, firstContent, lastContent}) => {
	className = className ? ' ' + className : '';
	style = style ?? {};
	active = active ? true : false;
	disabled = disabled ? true : false;

	const buttonRef = useRef();
	const [classType, setType] = useState('');

	const press = (e) => {
		if (disabled || href)
			return;
		if (typeof onPress === 'function')
			onPress(e);
		e.stopPropagation();
	};

	useEffect(() => {
		if (!buttonRef.current)
			return;
		const classList = buttonRef.current.classList;
		active ? classList.add(styles.active) : classList.remove(styles.active);
		
	},[buttonRef.current, active]);

	useEffect(() => {
		if (!buttonRef.current)
			return;
		const classList = buttonRef.current.classList;
		disabled ? classList.add(styles.disabled) : classList.remove(styles.disabled);
	},[buttonRef.current, disabled]);

	useEffect(() => {
		if (!buttonRef.current)
			return;
		const classList = buttonRef.current.classList;
		spinner ? classList.add(styles.spinner) : classList.remove(styles.spinner);
	},[buttonRef.current, spinner]);

	useEffect(() => {
		if (typeof type !== 'string') {
			return;
		}
		const arrStyle = [];
		for(const styleType of type.split(' ')) {
			switch(styleType) {
				//colors
				case 'primary':
					arrStyle.push(styles.primary);
				break;
				case 'accent':
					arrStyle.push(styles.accent);
				break;
				case 'alert':
					arrStyle.push(styles.alert);
				break;
				case 'contour':
					arrStyle.push(styles.contour);
				break;
				//types
				case 'last':
					arrStyle.push(styles.last);
				break;
				case 'small':
					arrStyle.push(styles.small);
				break;
				case 'big':
					arrStyle.push(styles.big);
				break;
				case 'full':
					arrStyle.push(styles.full);
				break;
			}
		}
		
		setType(arrStyle.length > 0 ? ' ' + arrStyle.join(' ') : '');
	},[type]);

	return(<button
		href={href}
		type={submit ? 'submit' : null}
		className={styles.button + classType + className}
		ref={buttonRef}
		style={style}
		onClick={press}
	>{firstContent ? firstContent : null}{title}{lastContent ? lastContent : null}</button>);
};
export default Button;