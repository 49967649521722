import React from "react";
import Modal from "../../Inc/Modal";

const ProgRun = () => {

	return(<>
		<Modal id="program-run">
			<div className="exercise-video opened" id="ex-vid">
				{/* <video loop controls autoplay>
					<source src="assets/videos/4.20.mp4" type="video/mp4">
				</video> */}
			</div>
			<div className="wrapper set-form mgbs hlines">
				<div className="exercise-item">
					<div className="exercise-pic pic-box"><img src="/assets/exe/1.png"/></div>
					<div className="exercise-name">Жим штанги на скамье с наклоном вверх</div>
					<div className="exercise-action toggler" rel="ex-vid"><i className="ic ic-c-play "></i></div>
				</div>
				<div className="params">
					<div className="set-num">1</div>
					<input placeholder="Вес кг" value="120" type="number"/>
					<input placeholder="Кол-во раз" value="3" type="number"/>
				</div>
				<div className="time-range-items">
					<div className="time-range-item">
						<div className="block range">
							<div className="val"><i className="ic ic-flag-start"></i><span>--:--:--</span></div>
							<div className="val"><i className="ic ic-flag-finish"></i><span>--:--:--</span></div>
						</div>
					</div>
					<div className="time-range-item">
						<div className="block timer">
							<div className="val">Таймер отдыха</div>
							<div className="val"><i className="ic ic-c-play"></i><span>--:--:--</span><i className="ic ic-refresh"></i></div>
						</div>
					</div>
				</div>
				<div className="automate">
					<label className="switch">
						<input type="checkbox"/>
						<i></i><span>Автопереход</span>
					</label>
				</div>
				<div className="buttons">
					<a className="button primary">Начать <i className="ic ic-c-play"></i></a>
					<a className="button accent">Следующий <i className="ic ic-ch-right"></i></a>
				</div>
			</div>
		</Modal>
	</>);
};
ProgRun.show = () => {
	Modal.show('program-run');
};

export default ProgRun;