import React,{ useEffect, useState } from "react";
import { useParams } from "react-router";
import PostItem from "./Lists/PostItem";
import HeadLine from "./Inc/HeadLine/HeadLine";
import File from "./Inc/File";
import API from "../Api/API";
import DivPlaceholder from "./Inc/DivPlaceholder";
import PageAbout from "./User/PageAbout";
import Page404 from "./PageError";

const UserPage = (props) => {
	const params = useParams();
	const id = params.id ?? 0;
	const [isNotFound, setIsNotFound] = useState(false);
	const [isLoad, setIsLoad] = useState(false);
	const [user, setUser] = useState({});

	const getUser = () => {
		API.get({
			path: 'user/get/' + id,
			success: ({user}) => {
				setUser(user);
			},
			fail: (e) => {
				setIsNotFound(true);
				console.error(e);
			},
			complete: () => {
				setIsLoad(true);
			}
		});
	};

	useEffect(() => {
		setIsLoad(false);
		if (id > 0) {
			getUser();
		}else{
			setUser(props.user);
			setIsLoad(true);
		}
	},[id]);
	
	HeadLine.setTitle('');

	if (isNotFound)
		return(<Page404/>);

	return(<>
	<div className="main-content">
	
		<div className="page-head">
		
			<div className="head-bg">0</div>
			
			<div className="head-block">
				<div className="head-side">
					{
						isLoad ? <>
							<a className="icon" href="users-list.htm">
								<i className="ic ic-friends circle"></i>
								<span>{user.friends_count}</span>
							</a>
							<a className="link" href="#mini-shop">
								<i className="ic ic-store"></i>
								<span>Товары</span>
							</a>
						</> : null
					}
				</div>
				<div className="head-main">
					<DivPlaceholder wait={!isLoad} className="user-pic pic-box"><File.Avatar uid={user.id} size="300"/></DivPlaceholder>
				</div>
				<div className="head-side">
					{
						isLoad ? <>
							<a className="icon" href="#feed">
								<i className="ic ic-news circle"></i>
								<span>0</span>
							</a>
							<a className="link" href="#tariffs">
								<i className="ic ic-o-play"></i>
								<span>Видео</span>
							</a>
						</> : null
					}
				</div>
			</div>
			
			<DivPlaceholder wait={!isLoad} className="info-block plate">
				<div className="user-name">{user.firstname + ' ' + user.lastname}</div>
				
				<div className="user-tituls">
					<div className=""><i className="ic ic-heart"></i> Друг проекта</div>
					<div className=""><i className="ic ic-barbell"></i> 100+ кг жим</div>
					<div className=""><i className="ic ic-biceps-left"></i> 40+ бицепс</div>
					<div className=""><i className="ic ic-prize"></i> 4 года с нами</div>
				</div>
				
				<PageAbout id={user.id}/>
				
				<div className="actions">
					<a className="button primary">Подписаться <i className="ic ic-follow"></i></a>
					<a className="button accent" href="message-dialog.htm">Сообщение <i className="ic ic-send"></i></a>
				</div>
			</DivPlaceholder>
			
			
			
		</div>
	
	
		<div className="plate" id="mini-shop">
			<div className="caption"><i className="ic ic-store fc-accent"></i><span>Товары</span><a className="small link">Смотреть все</a></div>
			<div className="snap-slider goods-list wheel ">0</div>
		</div>
	
		<div className="plate">
			<div className="feed-actions">
				<a><i className="ic ic-list"></i></a>
				<a><i className="ic ic-image"></i></a>
				<a><i className="ic ic-vcard"></i></a>
				<input type="text" />
				<a><i className="ic ic-search"></i></a>
			</div>
		</div>
	
		<div className="tab-panels" id="post-tabs">
			
			<div className="panel active" id="tab-news">
				<div className="post-list"><PostItem/></div>
			</div>
			
			<div className="panel" id="tab-feed">
				<div className="post-list"><PostItem/></div>
			</div>
			
			<div className="panel" id="tab-blog">&nbsp;</div>
			
		</div>
	
	</div>
	<div className="side-content">
		
	</div>

	</>);
};

export default UserPage;