import React,{ useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import Tabs from "../Inc/Tabs/Tabs";
import SubMenu from "../Inc/SubMenu/SubMenu";
import HeadLine from "../Inc/HeadLine/HeadLine";
import File from "../Inc/File";
import { getExerciseImg } from "./Inc/Helpers";
import Modal from "../Inc/Modal";
import Message from "../../Message";
import Days from "./Inc/Days";
import { numOfDays } from "../Inc/Helpers";
import Book from "./Book";
import { Exercise } from "./Inc/ListExercises";
import DivPlaceholder from "../Inc/DivPlaceholder";
import FileSelector from "../Inc/FileSelector";
import TextChange from "./Inc/TextChange";
import DragItems from "./Inc/DragItems";
import ProgItems from "./Prog/ProgItems";
import EventEmitter from "../../EventEmitter";
import Button from "../Inc/Button/Button";

import API from "../../Api/API";

const prog_props = {
	prog_aim: [
		'Набор массы',
		'Диета',
		'Сушка',
		'Кардио'
	],
	prog_place: [
		'Для дома',
		'Тренажерный зал',
		'Улица'
	],
	prog_age: [
		'Детская',
		'Юношеская',
		'Взрослая'
	],
	prog_gender: [
		'Для мужчин',
		'Для женщин',
		'Для всех'
	],
	prog_level: [
		'Легко',
		'Нормально',
		'Сложно'
	]
};

const getNameProp = (name, id) => {
	id = id - 1;
	switch(name) {
		case 'prog_aim':
			return prog_props.prog_aim[id];
		case 'prog_place':
			return prog_props.prog_place[id];
		case 'prog_age':
			return prog_props.prog_age[id];
		case 'prog_gender':
			return prog_props.prog_gender[id];
		case 'prog_level':
			return prog_props.prog_level[id];
	}
};

var progData = {};
var progsItems = {};
var progDays = {};
var progDaySelect = {};
var progDayDelete = {};
var progNewItems = {};
var progDataItemSets = {};

const Prog = React.memo(({user, navigate}) => {
	const params = useParams();
	const [waitDelete, setWaitDelete] = useState(false);
	const [waitSave, setWaitSave] = useState(false);
	const [isLoad, setIsLoad] = useState(false);
	const [notFound, setNotFound] = useState(false);
	var id = params.id ?? 0;

	const [data, setData] = useState(progData[id] ?? {
		user_name: '',
		user_id: 0,
		prog_name: '',
		prog_description: '',
		prog_days: 1,
		prog_day_select: 1,
		prog_aim: '1',
		prog_place: '1',
		prog_age: '1',
		prog_gender: '1',
		prog_level: '1'
	});
	const [dataItems, setDataItems] = useState([]);
	const [dataItemSets, setDataItemSets] = useState(progDataItemSets[id] ?? {});
	const [items, setItems] = useState(progsItems[id] ?? {});
	
	progData[id] = data;
	progsItems[id] = items;
	progDataItemSets[id] = dataItemSets;

	const setDays = (days) => {
		setData((state) => ({...state, prog_days: days}));
	};

	HeadLine.setTitle(data.prog_name, 'Программа тренировок');
	HeadLine.setProps({
		back: true
	});
	
	const getProgram = () => {
		API.get({
			path: 'training/program/get/' + id,
			success: ({item, items, sets}) => {
				setDataItems(items);
				setDataItemSets(sets);
				
				const props = item.params.split(',');
				if(progData[id].user_id <= 0) {
					setData((state) => ({
						...state,
						user_name: item.user_firstname+' '+item.user_middlename,
						user_id: item.uid,
						prog_name: item.title,
						prog_description: item.text,
						prog_days: progDays[id] ?? item.days,
						prog_day_select: progDaySelect[id] ?? 1,
						prog_aim: props[0],
						prog_place: props[1],
						prog_age: props[2],
						prog_gender: props[3],
						prog_level: props[4]
					}));
				}
				setIsLoad(true);
			},
			fail: (e) => {
				console.log(e);
				setNotFound(true);
			}
		});
	};

	const changeLogo = (e) => {
		console.log(e);
	};

	const save = (callback) => {
		callback = (typeof callback === 'function') ? callback : (()=>{});
		setWaitSave(true);
		if (Days.save)
			Days.save();
		EventEmitter.dispatch('prog-save', true);
		setIsLoad(false);
		const params = [
			data.prog_aim,
			data.prog_place,
			data.prog_age,
			data.prog_gender,
			data.prog_level,
		];
		API.post({
			path: 'training/program/' + (id > 0 ? 'update/' + id : 'create'),
			data: {
				name: data.prog_name,
				text: data.prog_description,
				params: params,
				days: data.prog_days,
				days_delete: progDayDelete[id]
			},
			success: (result) => {
				console.log(result)
				if (id <= 0) {
					navigate('/training/prog/' + result.id);
				}else{
					if(typeof ProgItems.setNewItems === 'function')
						ProgItems.setNewItems({});
					getProgram();
				}
				callback({
					status: true,
					id: result.id
				});
				Message.success('Сохранено');
			},
			fail: (e) => {
				callback({
					status: false
				});
				console.error(e);
			},
			error: (e) => {
				console.error(e);
			},
			complete: () => {
				setIsLoad(true);
				setWaitSave(false);
			}
		});
	};

	const deleteProg = (id) => {
		if (id <= 0)
			return;
		setWaitDelete(true);
		API.post({
			path: 'training/program/delete/' + id,
			data: {},
			success: (result) => {
				console.log(result)
				navigate('/training/programs#prog-my');
			},
			error: (e) => {
				console.error(e);
			},
			complete: () => {
				setWaitDelete(false);
			}
		});
	}
	
	HeadLine.setAction({
		show: true,
		mobile: true,
		spinner: waitSave,
		check: data.user_id === user.id ? save : null,
		dots: (e) => SubMenu.show('prog-control', e)
	});

	useEffect(() => {
		if (id > 0) {
			getProgram();
		}else{
			setData((state) => ({
				...state,
				user_id: user.id,
				user_name: user.firstname+' '+user.lastname
			}));
			setIsLoad(true);
		}
	
	},[]);
	
	if (notFound)
		return(<div className="main-content"><div className="plates">Программа не найдена :-(</div></div>);

	return(<>
	<div className="main-content">
	
	<div className="mobile">
		<div className="tab-control" rel="prog-tabs">
			<div className="tab-item"><Tabs.NavTab to="prog-desc">Основное</Tabs.NavTab></div>
			<div className="tab-item"><Tabs.NavTab to="prog-content">Программа</Tabs.NavTab></div>
		</div>
	</div>
	
	<Tabs>
		
		<Tabs.Item id="prog-desc" active={true}>
			<DivPlaceholder className="food-caption" wait={!isLoad}>
				<FileSelector onChange={changeLogo}>
					<div className="food-bg"><File.Avatar uid={data.user_id} size="600"/></div>
				</FileSelector>
				<div className="header flex">
					<div className="food-pic pic-box"><File.Avatar uid={data.user_id} size="50"/></div>
					<div className="food-info">
						<TextChange className="food-name" placeholder="Название программы" value={data.prog_name} onChange={(text) => setData((state) => ({...state, prog_name: text}))}/>
						<div className="food-cat">{data.user_name}</div>
					</div>
				</div>
			</DivPlaceholder>

			<DivPlaceholder className="prog-params plate" wait={!isLoad}>
				<div><i className="ic ic-clock"></i> <span>{numOfDays(data.prog_days)}</span></div>
				<div className="s-menu" onClick={e => SubMenu.show('prog-aim', e)} rel="prog-aim"><i className="ic ic-aim"></i>  <span>{getNameProp('prog_aim', data.prog_aim)}</span></div>
				<div className="s-menu" onClick={e => SubMenu.show('prog-place', e)} rel="prog-place"><i className="ic ic-place"></i> <span>{getNameProp('prog_place', data.prog_place)}</span></div>
				<div className="s-menu" onClick={e => SubMenu.show('prog-age', e)} rel="prog-age"><i className="ic ic-users"></i> <span>{getNameProp('prog_age', data.prog_age)}</span></div>
				<div className="s-menu" onClick={e => SubMenu.show('prog-gender', e)} rel="prog-gender"><i className="ic ic-user"></i>  <span>{getNameProp('prog_gender', data.prog_gender)}</span></div>
				<div className="s-menu" onClick={e => SubMenu.show('prog-level', e)} rel="prog-level"><i className="ic ic-level"></i> <span>{getNameProp('prog_level', data.prog_level)}</span></div>
			</DivPlaceholder>
			
			<DivPlaceholder className="food-about plate" wait={!isLoad}>
				<div className="caption"><i className="ic ic-o-info"></i><span>Описание программы</span></div>
				<TextChange className="food-text" placeholder="Описание" textarea={true} value={data.prog_description} onChange={(text) => setData((state) => ({...state, prog_description: text}))}/>
			</DivPlaceholder>
			
		</Tabs.Item>

		
		<Tabs.Item id="prog-content">
			<Days id={id} progSave={save} onChange={setDays}/>
			
			{/* <div id="training-exercises">
				<ProgItems id={id} wait={!isLoad} selectDay={data.prog_day_select} dataItems={dataItems} dataItemSets={dataItemSets} onLoad={(list) => setItems(list)} onChange={(list) => setItems(list)} onChangeSets={(list) => setDataItemSets(list)}/>
			</div> */}
			
		</Tabs.Item>
		
	</Tabs>
	
	</div>
	<div className="side-content">
		<div className="tab-control vert desktop" rel="prog-tabs">
			<div className="tab-item"><Tabs.NavTab to="prog-desc">Основное</Tabs.NavTab></div>
			<div className="tab-item"><Tabs.NavTab to="prog-content">Программа</Tabs.NavTab></div>
			{data.user_id === user.id ? <div>
				<Button type="big" title=" Сохранить" onPress={save} spinner={waitSave} disabled={waitSave} firstContent={<i className="ic ic-check"></i>}/>
				<Button type="big" title=" Дополнительно" onPress={(e) => SubMenu.show('prog-control', e)} firstContent={<i className="ic ic-dots"></i>}/>
			</div> : null}
		</div>
	</div>

	<SubMenu id="prog-control">
		<a onClick={() => Modal.show('prog-prog-delete')}><i className="ic ic-delete"></i>Удалить</a>
	</SubMenu>

	<SubMenu id="prog-aim" onSelect={(v, e) => setData((state) => ({...state, prog_aim: v}))}>
		{prog_props.prog_aim.map((name, key) => <a key={key} value={key + 1}>{name}</a>)}
	</SubMenu>

	<SubMenu id="prog-place" onSelect={(v, e) => setData((state) => ({...state, prog_place: v}))}>
		{prog_props.prog_place.map((name, key) => <a key={key} value={key + 1}>{name}</a>)}
	</SubMenu>

	<SubMenu id="prog-gender" onSelect={(v, e) => setData((state) => ({...state, prog_gender: v}))}>
		{prog_props.prog_gender.map((name, key) => <a key={key} value={key + 1}>{name}</a>)}
	</SubMenu>

	<SubMenu id="prog-level" onSelect={(v, e) => setData((state) => ({...state, prog_level: v}))}>
		{prog_props.prog_level.map((name, key) => <a key={key} value={key + 1}>{name}</a>)}
	</SubMenu>

	<SubMenu id="prog-age" onSelect={(v, e) => setData((state) => ({...state, prog_age: v}))}>
		{prog_props.prog_age.map((name, key) => <a key={key} value={key + 1}>{name}</a>)}
	</SubMenu>

	<Modal id="prog-prog-delete">
		<div className="wrapper">
			<div className="title">Удалить программу "{data.prog_name}"?</div>
			<div className="actions">
				<Button title="Удалить" spinner={waitDelete} disabled={waitDelete} onPress={() => deleteProg(id)}/>
				<Button type="accent" title="Отмена" disabled={waitDelete} onPress={() => Modal.close('prog-prog-delete')}/>
			</div>
		</div>
	</Modal>
	</>);
});
Prog.onSave = (callback) => {
	return EventEmitter.subscribe('prog-save', callback);
};
export default Prog;