import HeadLine from "../Inc/HeadLine/HeadLine";

const Feedback = () => {
	HeadLine.setTitle('Обратная связь', 'Настройки');
	HeadLine.setProps({
		back: true
	});
	return(<>
	<div className="main-content">

		<div className="settings-items plate">
			<div className="setting-field">
				<input placeholder="Причина обращения" className="modal" rel="reason"/>
			</div>
			<div className="setting-field">
				<textarea placeholder="Сообщение" style={{minHeight: '40vh'}}></textarea>
			</div>
		</div>

		<button className="last-button">Отправить</button>

	</div>
	<div className="side-content">

	</div>
	</>)
};
export default Feedback;