import { declOfAges } from "../../Inc/Helpers";

const Bmi = ({weight, height, age, gender}) => {

	if (!weight || !height)
		return(null);

	const value = Number(weight / ((height / 100) * 2)).toFixed(1);

	const normalWeight = (height - 100) - (height - 150) / 2;

	var sex = '-';
	switch(gender) {
		case 'male':
			sex = 'мужской';
		break;
		case 'female':
			sex = 'женский';
		break;
	}
	var pointer = 0;
	if (value <= 18.5) {
		pointer = 35 / 100 * (100 / (18.5 - 16) * (value - 16));
	}

	if (value > 18.5 && value <= 25) {
		pointer = 35 + (30 / (25 - 18.6) * (value - 18.6));
	}

	if (value > 25) {
		pointer = 65 + (35 / (40 - 25.1) * (value - 25.1));
	}

	pointer = pointer <= 0 ? 0 : pointer;
	pointer = pointer >= 100 ? 100 : pointer;
	
	return(<>
	<div className="bmi-params">
		<div className="bmi-header">
			<div className="pic-box ic-index"></div>
			<div className="bmi-bar">
				<div className="bar"></div>
				<div className="digits"><span>16</span><span>18.5</span><span>25</span><span>40</span></div>
				<div className="pointer" style={{left: 'calc('+pointer+'% - 5px)'}}></div>
			</div>
			<div className="bmi-value"><b>{value}</b><span>ИМТ</span></div>
		</div>
		
		{/* <!--<div className="caption">Ваши текущие показатели (ИМТ = Вес / Рост * Рост)</div>--> */}
		<div className="bmi-current-params">
			<div className="bmi-param">
				<i className="ic ic-gender"></i>
				<div className="txt">
					<div>Пол</div>
					<span><b>{sex}</b></span>
				</div>
			</div>
			<div className="bmi-param">
				<i className="ic ic-calendar"></i>
				<div className="txt">
					<div>Возраст</div>
					<span><b>{age}</b> {declOfAges(age)}</span>
				</div>
			</div>
			<div className="bmi-param">
				<i className="ic ic-height"></i>
				<div className="txt">
					<div>Рост</div>
					<span><b>{height}</b> см</span>
				</div>
			</div>
			<div className="bmi-param">
				<i className="ic ic-weight"></i>
				<div className="txt">
					<div>Вес</div>
					<span><b>{weight}</b> кг</span>
				</div>
			</div>
		</div>
		{
			value > 25 ?
				<div className="bmi-diagnostics up">
					<div className="name">Избыточный вес</div>
					<div className="value">+ {weight - (normalWeight + 25)} кг</div>
				</div>
			: null
		}
		{
			value < 18.5 ?
				<div className="bmi-diagnostics down">
					<div className="name">Недостаток веса</div>
					<div className="value">- {normalWeight - weight} кг</div>
				</div>
			: null
		}
		
		<div className="bmi-indexes">
			<div className={value < 15.9 ? 'current' : ''}><span>Сильный недостаток веса</span><span>{"< 15.9"}</span></div>
			<div className={value >= 16 && value <= 17 ? 'current' : ''}><span>Серъезный недостаток веса</span><span>{"16.0 - 16.9"}</span></div>
			<div className={value > 17 && value <= 18.5 ? 'current' : ''}><span>Недостаток веса</span><span>{"17.0 - 18.4"}</span></div>
			<div className={value > 18.5 && value <= 25 ? 'current' : ''}><span>Нормальный вес</span><span>{"18.0 - 24.9"}</span></div>
			<div className={value > 25 && value <= 30 ? 'current' : ''}><span>Избыточный вес</span><span>{"25.0 - 29.9"}</span></div>
			<div className={value > 30 && value <= 35 ? 'current' : ''}><span>Ожирение 1 степени</span><span>{"30.0 - 34.9"}</span></div>
			<div className={value > 35 && value <= 40 ? 'current' : ''}><span>Ожирение 2 степени</span><span>{"35.0 - 39.9"}</span></div>
			<div className={value > 40 ? 'current' : ''}><span>Ожирение 3 степени</span><span>{"> 40"}</span></div>
		</div>
		<div className="bmi-weight-range">
			<div>Нормальный вес</div>
			<div>{normalWeight} - {normalWeight + 25} кг</div>
		</div>
	</div>
	</>);
};

export default Bmi;